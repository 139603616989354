import React from 'react'
import SideBar from '@src/components/SideBar/SideBar'
import Form from '@src/features/Form/Form'
import {
  Avatar,
  Box,
  Button,
  InputGroup,
  Item,
  Side,
  VStack,
  Widget,
} from '@revolut/ui-kit'
import { useMountHiringStage } from '@src/pages/Forms/HiringStage/FormSections/useMountHiringStage'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import { HiringProcessInterface } from '@src/interfaces/hiringProccess'
import LapeRadioSelectInput from '@src/components/Inputs/LapeFields/LapeRadioSelectInput'
import { selectorKeys } from '@src/constants/api'
import LapeNewTextArea from '@src/components/Inputs/LapeFields/LapeNewTextArea'
import LapeNewSwitch from '@src/components/Inputs/LapeFields/LapeNewSwitch'
import { LapeSkillsField } from '@src/pages/Forms/HiringStage/FormSections/LapeSkillsField'
import LapeScorecardField from '@src/pages/Forms/HiringStage/FormSections/LapeScorecardField'
import EligibleInterviewers from '@src/pages/Forms/HiringStage/FormSections/EligibleInterviewers'
import { SchedulingPreferences } from '@src/pages/Forms/HiringStage/FormSections/SchedulingPreferences'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { createJobPostingHiringProcessStagesRequest } from '@src/api/jobPosting'
import { JobPostingInterface } from '@src/interfaces/jobPosting'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import OnlineTestRadioSelect from '@src/pages/Forms/HiringStage/OnlineTestRadioSelect'
import { hasNoSchedulingPreferences } from '@src/pages/Forms/HiringStage/FormSections/utils'

interface HiringStageFormProps {
  jobPosting?: JobPostingInterface
  onClose: VoidFunction
  onAfterSubmit: VoidFunction
}

export const HiringStageForm = ({
  jobPosting,
  onClose,
  onAfterSubmit,
}: HiringStageFormProps) => {
  const { values, errors } = useLapeContext<HiringProcessInterface>()
  const { isOnlineTest, isCVScreening } = useMountHiringStage()
  if (!values.job_posting && jobPosting) {
    values.job_posting = jobPosting
  }
  return (
    <VStack gap="s-16">
      <Widget>
        <Item>
          <Item.Avatar>
            <Avatar useIcon="IndustrialGear" />
          </Item.Avatar>
          <Item.Content>
            <Item.Title>Stage details</Item.Title>
          </Item.Content>
        </Item>
        <VStack gap="s-16" px="s-16" pb="s-16">
          <LapeNewInput name="title" required label="Stage name" />
          <LapeRadioSelectInput<HiringProcessInterface>
            name="stage_type"
            label="Stage type"
            selector={selectorKeys.hiring_stage_types}
            disabled={!!values.id}
          />
          <LapeNewTextArea name="notes" label="Stage description" rows={3} />
          {isOnlineTest ? (
            <>
              <LapeRadioSelectInput<HiringProcessInterface>
                name="test_platform"
                label="Test platform"
                selector={selectorKeys.hiring_stage_test_platforms}
                searchable={false}
                onAfterChange={() => {
                  values.online_test = undefined
                }}
              />
              <OnlineTestRadioSelect
                testPlatform={values.test_platform?.id}
                onChange={newOnlineTest => {
                  if (newOnlineTest) {
                    values.online_test = {
                      ...values?.online_test,
                      id: newOnlineTest.id,
                      name: newOnlineTest.name,
                    }
                  }
                }}
                value={values.online_test}
                error={errors?.online_test}
              />
            </>
          ) : !isCVScreening ? (
            <>
              <InputGroup variant="horizontal">
                <LapeNewInput
                  name="duration"
                  label="Enter duration"
                  type="number"
                  width="50%"
                  required
                />
                <LapeRadioSelectInput<HiringProcessInterface>
                  name="duration_unit"
                  label="Unit"
                  selector={selectorKeys.hiring_stage_duration_units}
                />
              </InputGroup>
              <Widget>
                <LapeNewSwitch
                  name="is_limited_by_seniorities"
                  itemTypeProps={{ title: 'Limited by seniorities' }}
                />
              </Widget>
              {values?.is_limited_by_seniorities && (
                <InputGroup variant="horizontal">
                  <LapeRadioSelectInput<HiringProcessInterface>
                    name="seniority_min"
                    label="Min Seniority"
                    selector={selectorKeys.seniority}
                  />
                  <LapeRadioSelectInput<HiringProcessInterface>
                    name="seniority_max"
                    label="Max Seniority"
                    selector={selectorKeys.seniority}
                  />
                </InputGroup>
              )}
            </>
          ) : null}
        </VStack>
      </Widget>
      <Widget>
        <Item>
          <Item.Avatar>
            <Avatar useIcon="Questionnaire" />
          </Item.Avatar>
          <Item.Content>
            <Item.Title>Evaluation criteria</Item.Title>
          </Item.Content>
        </Item>
        <VStack gap="s-16" px="s-16" pb="s-16">
          {!isCVScreening && !isOnlineTest && (
            <>
              <LapeSkillsField />
              <LapeScorecardField
                isClearable={false}
                isDisabled={false}
                isOnboarding={false}
                isRequired
              />
            </>
          )}
          <LapeNewInput name="playbook_link" label="Playbook link" />
        </VStack>
      </Widget>
      {!isCVScreening && !isOnlineTest && (
        <>
          <Widget>
            <Item>
              <Item.Avatar>
                <Avatar useIcon="Questionnaire" />
              </Item.Avatar>
              <Item.Content>
                <Item.Title>Interviewers</Item.Title>
                <Item.Description>
                  Specify eligible interviewers for this stage
                </Item.Description>
              </Item.Content>
            </Item>
            <Box p="s-16">
              <EligibleInterviewers isRequired />
            </Box>
          </Widget>
          {!hasNoSchedulingPreferences(values) && (
            <Widget>
              <Item>
                <Item.Avatar>
                  <Avatar useIcon="CalendarRecurring" />
                </Item.Avatar>
                <Item.Content>
                  <Item.Title>Scheduling preferences</Item.Title>
                </Item.Content>
              </Item>
              <Box px="s-16" pb="s-16">
                <SchedulingPreferences showHeader={false} />
              </Box>
            </Widget>
          )}
        </>
      )}
      <Side.Actions horizontal>
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <NewSaveButtonWithPopup
          useValidator
          hideWhenNoChanges={false}
          onAfterSubmit={onAfterSubmit}
          noPopup
        >
          Submit
        </NewSaveButtonWithPopup>
      </Side.Actions>
    </VStack>
  )
}

interface HiringStageFormSidebarProps extends HiringStageFormProps {
  stage: HiringProcessInterface | null
}

export const HiringStageFormSidebar = ({
  stage,
  ...props
}: HiringStageFormSidebarProps) => {
  const { values } = useLapeContext<JobPostingInterface>()
  return (
    <SideBar
      isOpen
      title={stage ? 'Edit hiring stage' : 'Create hiring stage'}
      useLayout
      onClose={props.onClose}
    >
      <Form
        api={createJobPostingHiringProcessStagesRequest(values.id)}
        disableLocalStorageCaching
        forceParams={{ id: stage ? String(stage.id) : undefined }}
      >
        <HiringStageForm {...props} jobPosting={values} />
      </Form>
    </SideBar>
  )
}
