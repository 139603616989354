import React, { useMemo } from 'react'
import { AxiosError } from 'axios'
import { Box, Flex, HStack, Icon, opacity, Text, Token } from '@revolut/ui-kit'

import { navigateTo } from '@src/actions/RouterActions'
import { getSurveyResultsHeatmapTableRequests } from '@src/api/engagement'
import { useTable } from '@src/components/TableV2/hooks'
import Tooltip from '@src/components/Tooltip/Tooltip'
import { selectorKeys } from '@src/constants/api'
import { ROUTES } from '@src/constants/routes'
import { CellTypes, ColumnCellInterface, FilterByInterface } from '@src/interfaces/data'
import {
  EngagementResultsHeatmapRowInterface,
  EngagementResultsHeatmapScoreInterface,
} from '@src/interfaces/engagement'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { FeatureFlags } from '@src/store/auth/types'
import { pathToUrl } from '@src/utils/router'
import { useSelector } from 'react-redux'
import { ItemsToAnalyse, MAX_ITEMS_ALLOWED_FOR_ANALYSIS, ViewMode } from '../common'

const HEATMAP_ITEM_HEIGHT = 76
const HEATMAP_ITEM_MIN_WIDTH = 140

const mapScoreToCellColor = (data?: EngagementResultsHeatmapScoreInterface) => {
  const neutralColor = Token.color.greyTone2

  if (!data) {
    return neutralColor
  }
  const score =
    data.min_value < 0
      ? data.average_score + data.min_value
      : data.min_value > 0
      ? data.average_score - data.min_value
      : data.average_score

  const midScore = (data.max_value + Math.abs(data.min_value)) / 2
  const minScore = 0
  const maxScore = data.min_value === 0 ? data.max_value : data.max_value - data.min_value

  const isDanger = score < midScore && score >= minScore
  const dangerOpacity = 1 - score / (midScore || 0.01)

  const isSuccess = score >= midScore && score <= maxScore
  const successOpacity = (score - midScore) / (maxScore - midScore || 0.01)

  if (score === midScore) {
    return neutralColor
  }
  if (isDanger) {
    return opacity(Token.colorChannel.red, dangerOpacity)
  }
  if (isSuccess) {
    return opacity(Token.colorChannel.green, successOpacity)
  }
  return neutralColor
}

const HeatmapTableCell = ({
  data,
}: {
  data?: EngagementResultsHeatmapScoreInterface
}) => {
  if (data?.anonymized) {
    return (
      <Flex width="100%" height="100%" alignItems="center" justifyContent="center">
        <Tooltip
          placement="top"
          text="This result is hidden due to the anonymity treshold"
        >
          <Icon name="EyeHide" color={Token.color.greyTone50} size={16} />
        </Tooltip>
      </Flex>
    )
  }
  if (data?.average_score == null || data.max_value == null) {
    return (
      <Flex width="100%" height="100%" alignItems="center">
        <Text color={Token.color.greyTone50} ml="s-16">
          N/A
        </Text>
      </Flex>
    )
  }
  return (
    <Box
      width="100%"
      height={HEATMAP_ITEM_HEIGHT}
      radius={Token.radius.r12}
      backgroundColor={mapScoreToCellColor(data)}
    >
      <Flex width="100%" height="100%" alignItems="center" justifyContent="center">
        <HStack space="s-2" align="end">
          <Text variant="heading3" color={Token.color.foreground}>
            {data.average_score}
          </Text>
          <Text fontWeight={500}>/</Text>
          <Text fontWeight={500}>{data.max_value}</Text>
        </HStack>
      </Flex>
    </Box>
  )
}

const mapScoreToTableCell = (
  targetScore: EngagementResultsHeatmapScoreInterface,
): ColumnCellInterface<EngagementResultsHeatmapRowInterface> => {
  return {
    type: CellTypes.insert,
    idPoint: targetScore.name,
    dataPoint: targetScore.name,
    sortKey: targetScore.name,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: targetScore.name,
    insert: ({ data }) => {
      const targetScoreData = data.scores.find(score => score.name === targetScore.name)
      return <HeatmapTableCell data={targetScoreData} />
    },
    width: HEATMAP_ITEM_MIN_WIDTH,
    padding: '4px',
    notHoverable: true,
  }
}

export type UseHeatmapTableReturnType = {
  itemsToAnalyse: ItemsToAnalyse
  surveyId: number
  onError: (e: AxiosError) => void
  initFilters: FilterByInterface[]
  isScopedView: boolean
  viewMode: ViewMode
}
export const useHeatmapTable = ({
  itemsToAnalyse,
  surveyId,
  onError,
  initFilters,
  isScopedView,
  viewMode,
}: UseHeatmapTableReturnType) => {
  const featureFlags = useSelector(selectFeatureFlags)
  const isEngagementV2 = featureFlags.includes(FeatureFlags.EngagementV2)
  const table = useTable<EngagementResultsHeatmapRowInterface>(
    getSurveyResultsHeatmapTableRequests({
      itemsToAnalyse,
      surveyId,
      onError,
    }),
    initFilters,
    undefined,
    { disableQuery: true, disable: viewMode !== 'heatmap' },
  )

  const row = useMemo(() => {
    const defaultTitleCell = {
      type: CellTypes.text,
      idPoint: 'id',
      dataPoint: 'name',
      sortKey: null,
      filterKey: null,
      selectorsKey: selectorKeys.none,
      title: 'Name',
      width: 200,
    }

    if (!table.data[0]?.scores?.length) {
      return { cells: [defaultTitleCell] }
    }
    const rowData = table.data[0]
    const scoresNum = rowData.scores.length
    const maxScoreColsNum = MAX_ITEMS_ALLOWED_FOR_ANALYSIS
    const scoreColumnsNum = scoresNum <= maxScoreColsNum ? scoresNum : maxScoreColsNum
    const scoresNumToWidth = [500, 400, 300]

    const cells: Array<ColumnCellInterface<EngagementResultsHeatmapRowInterface>> = [
      {
        ...defaultTitleCell,
        width: scoresNumToWidth[scoresNum] || 200,
      },
    ]

    for (let i = 0; i < scoreColumnsNum; i++) {
      cells.push(mapScoreToTableCell(rowData.scores[i]))
    }
    return {
      cells,
      linkToForm:
        isScopedView || !isEngagementV2
          ? undefined
          : ({ id }: { id: number }) => {
              if (!id) {
                return
              }
              if (itemsToAnalyse === 'questions') {
                navigateTo(
                  pathToUrl(ROUTES.PERFORMANCE.ENGAGEMENT.SURVEY_RESULTS.QUESTIONS, {
                    id: surveyId,
                    questionId: id,
                  }),
                )
              } else if (itemsToAnalyse === 'categories') {
                navigateTo(
                  pathToUrl(ROUTES.PERFORMANCE.ENGAGEMENT.SURVEY_RESULTS.CATEGORIES, {
                    id: surveyId,
                    categoryId: id,
                  }),
                )
              }
            },
    }
  }, [table.data[0], isScopedView, isEngagementV2])

  return { row, table }
}
