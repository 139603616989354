import React from 'react'
import { Box, chain, HStack, Item, Subheader } from '@revolut/ui-kit'
import { StageIcon } from '@src/pages/Forms/Candidate/StagesWidget/components/StageIcon'
import { StageTitle } from '@src/pages/Forms/Candidate/StagesWidget/components/StageTitle'
import { StageItems } from '@src/pages/Forms/Candidate/StagesWidget/components/StageItems'
import {
  CandidateInterface,
  InterviewRoundInterface,
  InterviewStageWithoutRoundInterface,
  StagesWidgetOnClickHandler,
} from '@src/interfaces/interviewTool'
import { StageGroupActions } from '@src/pages/Forms/Candidate/InterviewProgress/components/StagesTableActions/StageGroupActions'
import { FormPreviewDivider } from '@components/FormPreview/FormPreview'
import StageActions from '@src/pages/Forms/Candidate/InterviewProgress/components/StagesTableActions/StageActions'
import { PermissionTypes } from '@src/store/auth/types'
import {
  isStageSkippedNoFeedbacks,
  isStageStarted,
} from '@src/pages/Forms/Candidate/StagesWidget/utils'
import { MenuActionType } from '@components/MenuAction/MenuAction'
import {
  CurrentStage,
  isCurrentStage,
} from '@src/pages/Forms/Candidate/StagesWidget/components/CurrentStageLabel'
import { ActiveStageBorder } from '@src/pages/Forms/Candidate/StagesWidget/components/ActiveStageBorder'
import { StageWidgetWrapper } from '@src/pages/Forms/Candidate/StagesWidget/components/StageWidgetWrapper'

interface Props {
  stages: InterviewStageWithoutRoundInterface[]
  round: InterviewRoundInterface
  onRefresh: VoidFunction
  onClick?: StagesWidgetOnClickHandler
  disableActions?: boolean
  candidate: CandidateInterface
  canViewEditOffer: boolean
}

export const StageGroup = ({
  round,
  stages,
  onRefresh,
  onClick,
  disableActions,
  candidate,
  canViewEditOffer,
}: Props) => {
  const canCancel = !!round?.field_options?.permissions?.includes(
    PermissionTypes.CancelPendingScorecard,
  )
  const canAddFeedback = !!round?.field_options?.permissions?.includes(
    PermissionTypes.SeeAddFeedbackButton,
  )
  const interviewType = stages[0].interview_type
  const isArchived = !!round?.archived_reason
  const showActions = !disableActions && !isArchived

  const renderSingleStageActions = (
    menuType: MenuActionType,
    stage: InterviewStageWithoutRoundInterface,
  ) => (
    <StageActions
      canAddFeedback={canAddFeedback}
      canCancel={canCancel}
      candidateId={candidate.id}
      canRejectFeedback={canAddFeedback}
      canViewEditOffer={canViewEditOffer}
      canDuplicateStage={false}
      canRemoveStage={canAddFeedback}
      currentStageId={round?.latest_interview_stage?.id}
      menuType={menuType}
      roundId={round.id}
      stage={stage}
      stages={stages}
      onOpenSidebar={(stageData, mode, actionType) => {
        onClick?.(stageData, mode, undefined, actionType)
      }}
      onRefresh={onRefresh}
      compact
      maxCount={1}
    />
  )

  const isCurrentGroup = stages.some(stage => isCurrentStage(round, stage))

  return (
    <StageWidgetWrapper data-testid={`Stage group ${stages[0].title}`}>
      {isCurrentGroup && <ActiveStageBorder />}

      <Item useIcon={<StageIcon interviewType={interviewType} />}>
        <Item.Content>
          <Item.Title>
            <StageTitle round={round} stage={stages[0]} noCurrentLabel />
          </Item.Title>
        </Item.Content>
        {showActions && round?.latest_interview_stage && (
          <Item.Side
            onClick={e => {
              e.stopPropagation()
            }}
          >
            <StageGroupActions
              roundId={round.id}
              groupStages={stages}
              onRefresh={onRefresh}
              currentStageId={round?.latest_interview_stage.id}
            />
          </Item.Side>
        )}
      </Item>

      {stages.map((stage, idx) => {
        const showFeedbacks = isStageStarted(stage) && !isStageSkippedNoFeedbacks(stage)
        return (
          <Box key={stage.id} data-testid={`Group ${stages[0].title} Stage ${idx}`}>
            <Box pl="s-56" pr="s-16">
              <Subheader
                css={{ marginBottom: 0, paddingBottom: 0, cursor: 'pointer' }}
                onClick={() => {
                  if (onClick) {
                    onClick(stage, undefined, stage.interview_type)
                  }
                }}
              >
                <Subheader.Title>
                  <HStack gap="s-8">
                    {chain(stage.title, idx + 1)}
                    {round?.latest_interview_stage?.id === stage.id && <CurrentStage />}
                  </HStack>
                </Subheader.Title>
                {showFeedbacks && (
                  <Subheader.Side>
                    {renderSingleStageActions('dropdown', stage)}
                  </Subheader.Side>
                )}
              </Subheader>
            </Box>
            {showFeedbacks ? (
              <StageItems
                candidate={candidate}
                disable={!showActions}
                stage={stage}
                onClick={onClick}
              />
            ) : (
              <Box pl="s-56" pt="s-8" pb="s-16">
                {renderSingleStageActions('primary', stage)}
              </Box>
            )}

            {idx !== stages.length - 1 && (
              <Box pl="s-56" pr="s-16">
                <FormPreviewDivider />
              </Box>
            )}
          </Box>
        )
      })}
    </StageWidgetWrapper>
  )
}
