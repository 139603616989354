import { AxiosError, AxiosResponse } from 'axios'
import {
  PerformanceTimelineChartInterface,
  PerformanceCycleInterface,
  PerformanceReviewerSelector,
  PerformanceSelector,
  PerformanceTimelineEventsInterface,
  PerformanceTimelineInterface,
  PerformanceTimelineStats,
  PerfReviewRequestFeedbackInterface,
  RequestFeedbackInterface,
  ReviewCategory,
  SkillsReviewsInterface,
  SingleTimelineEventInterface,
  CandidatePerformanceInterface,
  PerformanceReviewAnalyticsGroupTypes,
  PerformanceReviewAnalyticsInterface,
} from '../interfaces/performance'
import { api, apiV2, apiWithoutHandling } from './index'
import { API } from '../constants/api'
import { ScorecardInterface } from '../interfaces/scorecard'
import { EmployeeGoal } from '../interfaces/employees'
import { GetRequestData, GetRequestInterface, Statuses } from '../interfaces'
import { filterSortPageIntoQuery, groupSortByIntoURLQuery } from '../utils/table'
import {
  useDelete,
  useFetch,
  useFetchV2,
  usePost,
  UseQueryOptions,
} from '@src/utils/reactQuery'
import { FilterByInterface, SORT_DIRECTION, SortByInterface } from '@src/interfaces/data'
import { OptionInterface } from '@src/interfaces/selectors'
import { InterviewStageWithoutRoundInterface } from '@src/interfaces/interviewTool'
import { useContext, useMemo } from 'react'
import { transformInterviewToPerformanceScorecard } from '@src/utils/interview'
import { Cycle, CycleStats } from '@src/pages/Forms/DepartmentForm/Performance/interfaces'
import type { PerformanceSummaryData } from '@src/pages/Forms/DepartmentForm/Performance/interfaces'
import { useMutation, useQueryClient } from 'react-query'
import { ExtensionApiHandlerContext } from '@src/utils/extension'

export const useGetGoals = (id: number | null, filters: FilterByInterface[]) => {
  const context = useFetch<{ results: EmployeeGoal[] }>(
    id ? `${API.EMPLOYEES}/${id}${API.PERFORMANCE}/goals` : null,
    'v2',
    {
      params: filterSortPageIntoQuery(undefined, filters, undefined),
    },
  )

  return { ...context, data: context.data?.results }
}

export const useDeleteGoal = (
  employeeId: number,
  filters: FilterByInterface[],
  updater: (
    oldData: GetRequestData<EmployeeGoal>,
    id?: string | number,
  ) => GetRequestData<EmployeeGoal>,
) =>
  useDelete(
    `${API.EMPLOYEES}/${employeeId}${API.PERFORMANCE}/goals`,
    'v2',
    {
      params: filterSortPageIntoQuery(undefined, filters, undefined),
    },
    updater,
  )

export const useAddGoal = (
  employeeId: number,
  filters: FilterByInterface[],
  updater: (
    oldData: GetRequestData<EmployeeGoal>,
    newData: EmployeeGoal,
  ) => GetRequestData<EmployeeGoal>,
) =>
  usePost(
    `${API.EMPLOYEES}/${employeeId}${API.PERFORMANCE}/goals`,
    'v2',
    {
      params: filterSortPageIntoQuery(undefined, filters, undefined),
    },
    updater,
  )

export const getPerformanceReviews = (id: number, filters?: Object) => {
  return api.get<ScorecardInterface>(
    `${API.EMPLOYEES}/${id}${API.PERFORMANCE}${API.REVIEWS}/summary`,
    {
      params: filters || [],
    },
    'v2',
  )
}
export const postScorecard = (data: ScorecardInterface) =>
  api.post(
    `${API.EMPLOYEES}/${data.reviewed_person.id}${API.PERFORMANCE}${API.REVIEWS}`,
    data,
    {},
    'v2',
  )
export const getScorecardForEditing = (id: number) => {
  return api.get<ScorecardInterface>(`${API.SCORECARD}/${id}`, {}, 'v2')
}
export const putScorecardForEditing = (id: string, data: ScorecardInterface) => {
  return api.put<ScorecardInterface>(`${API.SCORECARD}/${id}`, data, {}, 'v2')
}
export const getReviewersSelector = (id: number, filters?: Object) => {
  return api.get<PerformanceReviewerSelector[]>(
    `${API.EMPLOYEES}/${id}${API.PERFORMANCE}${API.REVIEWS}`,
    {
      params: filters || [],
    },
    'v2',
  )
}

interface PerformanceSelectorParams {
  exclude_adhoc?: boolean
}

export const useGetPerformanceSelector = (
  id?: number | string,
  params?: PerformanceSelectorParams,
) =>
  useFetchV2<PerformanceSelector[]>({
    url: `${API.EMPLOYEES}/${id}${API.PERFORMANCE}/reviewCycles`,
    version: 'v2',
    queryOptions: {
      enabled: id !== undefined,
    },
    params: params ? { params } : undefined,
  })

export const postFeedbackRequest = (data: RequestFeedbackInterface) => {
  return api.post<RequestFeedbackInterface>(
    `${API.EMPLOYEES}/${data.reviewed_person.id}${API.PERFORMANCE}${API.REVIEWS}/requests`,
    data,
    {},
    'v2',
  )
}

export const postPerfReviewFeedbackRequest = (
  employeeId: number,
  id: string,
  data: {
    reviewer: { id: number }
    team_mate?: { id: number }
    reviewer_relation?: string
    category: ReviewCategory
    checkpoint_number?: number
  },
) => {
  return api.post<PerfReviewRequestFeedbackInterface>(
    `${API.EMPLOYEES}/${employeeId}${API.PERFORMANCE}/cycles/${id}/performanceReviews/requests`,
    data,
    {},
    'v2',
  )
}

export const deleteScorecard = (
  employeeId: number | string,
  cycleId: number | string,
  id: number,
) =>
  api.delete(
    `/employees/${employeeId}/performance/cycles/${cycleId}/performanceReviews/requests/${id}`,
    {},
    'v2',
  )

export const useGetFeedbackRequests = (id: number | null, filters?: Object) =>
  useFetch<RequestFeedbackInterface[]>(
    id ? `${API.EMPLOYEES}/${id}${API.PERFORMANCE}${API.REVIEWS}/requests` : null,
    'v2',
    { params: filters || [] },
  )

export const useGetPerfReviewFeedbackRequests = (
  employeeId: number | null,
  cycleId: string | null,
  filters?: Object,
) =>
  useFetch<{
    results: PerfReviewRequestFeedbackInterface[]
    requester_upwards_review_status: Statuses
  }>(
    employeeId && cycleId !== undefined
      ? `${API.EMPLOYEES}/${employeeId}${API.PERFORMANCE}/cycles/${cycleId}/performanceReviews/requests`
      : null,
    'v2',
    { params: filters || [] },
  )

export const getScorecardStructure = (id: number, filter?: Object) =>
  api.get<ScorecardInterface>(
    `${API.EMPLOYEES}/${id}${API.PERFORMANCE}/scorecardsStructure`,
    {
      params: filter || [],
    },
    'v2',
  )

export const initiatePIP = (
  employeeId: number,
  data: Partial<PerformanceCycleInterface>,
) => {
  return api.post(
    `${API.EMPLOYEES}/${employeeId}${API.PERFORMANCE}/PIPCycles`,
    data,
    {},
    'v2',
  )
}

export const getExport = (employeeId: number, cycle__id: number | string, id?: string) =>
  api.get<BinaryType>(
    `${API.EMPLOYEES}/${employeeId}${API.PERFORMANCE}/reviews/exportSummary`,
    {
      responseType: 'arraybuffer',
      params: {
        cycle__id,
        ...(id ? { id } : {}),
      },
    },
    'v2',
  )

export const getPerformanceTimeline = (
  employeeId: number | string,
  cycleId?: number | string,
): Promise<AxiosResponse<GetRequestInterface<PerformanceTimelineInterface>>> =>
  apiWithoutHandling.get(
    `${API.EMPLOYEES}/${employeeId}${API.PERFORMANCE}/performanceTimeline`,
    {
      params: {
        cycle__id: cycleId,
      },
    },
    'v2',
  )

export const getPerformanceTimelineStats = (
  employeeId: number,
  cycleId?: number | string,
): Promise<AxiosResponse<PerformanceTimelineStats>> =>
  apiWithoutHandling.get(
    `${API.EMPLOYEES}/${employeeId}${API.PERFORMANCE}/performanceTimeline/stats`,
    {
      params: {
        cycle__id: cycleId,
      },
    },
    'v2',
  )

export const getPerformanceTimelineChart = (employeeId: string | number) =>
  api.get<PerformanceTimelineChartInterface>(
    `${API.EMPLOYEES}/${employeeId}${API.PERFORMANCE}/performanceTimeline/chart`,
    {},
    'v2',
  )

export const getPerformanceTimelineChartSkill = (
  employeeId: string | number,
  skill_id: string | number,
) =>
  api.get<PerformanceTimelineChartInterface>(
    `${API.EMPLOYEES}/${employeeId}${API.PERFORMANCE}/performanceTimeline/skillChart`,
    {
      params: {
        skill_id,
      },
    },
    'v2',
  )

export const getPerformanceChartSkills = (employeeId: string | number) =>
  api.get<OptionInterface[]>(
    `${API.EMPLOYEES}/${employeeId}${API.PERFORMANCE}/performanceTimeline/chartSkills`,
    {},
    'v2',
  )

export const getPerformanceSkillsReviews = (
  employeeId: number,
): Promise<AxiosResponse<GetRequestInterface<SkillsReviewsInterface>>> =>
  apiWithoutHandling.get(
    `${API.EMPLOYEES}/${employeeId}${API.PERFORMANCE}/skillsReviews`,
    {},
    'v2',
  )

export const getPerformanceTimelinEevents = (employeeId: number) => {
  return api.get<PerformanceTimelineEventsInterface>(
    `${API.EMPLOYEES}/${employeeId}${API.PERFORMANCE}/performanceTimeline/events`,
    {},
    'v2',
  )
}

export const useGetEmployeeGradeVisibility = (data: {
  employeeId?: number
  cycleId?: string | number
  enabled: boolean
}) =>
  useFetchV2<{ is_visible: boolean }>({
    url: `/${data.employeeId}/gradeVisibility/${data.cycleId}`,
    version: 'v2',
    queryOptions: { enabled: Boolean(data.cycleId && data.employeeId && data.enabled) }, // disabled by default
  })

export const useUpdateEmployeeGradeVisibility = () => {
  const queryClient = useQueryClient()

  const apiHandler = useContext(ExtensionApiHandlerContext)
  let apiFunc = apiHandler || apiV2

  return useMutation<
    AxiosResponse<{ is_visible: boolean }>,
    AxiosError,
    [{ employeeId: string | number; cycleId: string | number }, { is_visible: boolean }]
  >(
    ([{ employeeId, cycleId }, data]) =>
      apiFunc.put(`/${employeeId}/gradeVisibility/${cycleId}`, data, undefined, 'v2'),
    {
      onSuccess: (response, variables) => {
        if (response.data) {
          queryClient.setQueryData<{ is_visible: boolean }>(
            [
              `${variables[0].employeeId}/gradeVisibility/${variables[0].cycleId}`,
              'v2',
              null,
            ],
            _oldData => response.data,
          )
        }
      },
    },
  )
}

export const useGetPerformanceCycleTimelineEvents = (
  employeeId: number,
  cycleId?: number | string,
  isPerformance?: boolean,
) =>
  useFetch<SingleTimelineEventInterface[]>(
    employeeId && cycleId !== undefined && isPerformance
      ? `${API.EMPLOYEES}/${employeeId}${API.PERFORMANCE}/performanceTimeline/cycleEvents`
      : null,
    'v2',
    { params: { cycle_id: cycleId } },
  )

export const useGetPerformanceRecruitmentCycleData = ({
  employeeId,
  interviewerIds,
  options,
}: {
  employeeId: number
  interviewerIds?: number[]
  options?: { enabled?: boolean }
}) =>
  useFetch<CandidatePerformanceInterface>(
    employeeId ? `/employees/${employeeId}/candidatePerformance` : null,
    'v1',
    interviewerIds?.length
      ? {
          params: {
            interviewer_ids: interviewerIds?.join(','),
          },
        }
      : undefined,
    true,
    {
      enabled: options?.enabled,
    },
  )

export const useFetchInterviewStages = (employeeId: number | string) => {
  const sortBy = [
    {
      sortBy: 'interview_number',
      direction: SORT_DIRECTION.DESC,
      nonResettable: true,
    },
    {
      sortBy: 'interview_number_for_type',
      direction: SORT_DIRECTION.DESC,
      nonResettable: true,
    },
  ]

  const filters = [
    {
      filters: [{ name: 'True', id: 'True' }],
      columnName: 'is_enabled',
      nonResettable: true,
    },
  ]
  const context = useFetch<GetRequestInterface<InterviewStageWithoutRoundInterface>>(
    employeeId ? `/employees/${employeeId}/candidatePerformance/interviewStages` : null,
    undefined,
    {
      params: filterSortPageIntoQuery(sortBy, filters),
    },
    undefined,
    {
      refetchOnWindowFocus: false,
    },
  )
  const results = useMemo(
    () =>
      context.data?.results.map(stage => ({
        ...stage,
        interview_feedbacks: stage.interview_feedbacks.map(feedback =>
          transformInterviewToPerformanceScorecard(feedback),
        ),
      })),
    [context.data?.results],
  )

  return {
    ...context,
    data: results || [],
  }
}

export const useGetDepartmentReviewCycles = (
  contentTypeId: number | undefined,
  departmentId?: number | string,
) =>
  useFetch<Cycle[]>({
    url: departmentId && contentTypeId ? `/performanceHistory/reviewCycles` : null,
    params: {
      params: {
        content_type_id: contentTypeId,
        object_id: departmentId,
      },
    },
  })

export const useGetAggregatedStats = ({
  contentTypeId,
  departmentId,
  navigationType,
  reviewCycleIds,
}: {
  contentTypeId: number | undefined
  departmentId?: number | string
  navigationType?: 'year' | 'review_cycle'
  reviewCycleIds?: number[]
}) =>
  useFetch<PerformanceSummaryData>({
    url: `/performanceHistory/aggregatedStats`,
    version: 'v1',
    params: {
      params: {
        content_type_id: contentTypeId,
        object_id: departmentId,
        navigation_type: navigationType,
        review_cycle_id: reviewCycleIds?.toString(),
      },
    },
    queryOptions: {
      enabled:
        !!departmentId && !!contentTypeId && !!navigationType && !!reviewCycleIds?.length,
    },
  })
export const useGetDepartmentPerformanceStats = (
  contentTypeId: number | undefined,
  reviewCycleIds: number[] | undefined,
  departmentId?: number | string,
) =>
  useFetch<CycleStats[]>(
    departmentId && contentTypeId && reviewCycleIds?.length
      ? `/performanceHistory/stats`
      : null,
    'v1',
    {
      params: {
        content_type_id: contentTypeId,
        object_id: departmentId,
        review_cycle_id: reviewCycleIds?.toString(),
      },
    },
  )

export enum PerformanceReviewAnalyticsQueryKeys {
  Ordering = 'ordering',
}

export enum PerformanceReviewAnalyticsFilterKeys {
  CycleId = 'cycle__id',
  DepartmentId = 'department__id',
  FunctionId = 'function__id',
}

export enum PerformanceReviewAnalyticsSortKeys {
  Name = 'name',
  Performance = 'performance',
  Seniority = 'seniority',
  EmployeeCount = 'employee_count',
  CycleStartDate = 'cycle_start_date',
}

export interface PerformanceReviewAnalyticsParams {
  [PerformanceReviewAnalyticsFilterKeys.CycleId]?: number | string
  [PerformanceReviewAnalyticsFilterKeys.DepartmentId]?: number[]
  [PerformanceReviewAnalyticsFilterKeys.FunctionId]?: number[]
  [PerformanceReviewAnalyticsQueryKeys.Ordering]?: string
}

export const useGetPerformanceReviewAnalytics = (
  groupType: PerformanceReviewAnalyticsGroupTypes,
  params?: PerformanceReviewAnalyticsParams,
  sortBy?: SortByInterface[],
  options?: UseQueryOptions<PerformanceReviewAnalyticsInterface>,
) => {
  let ordering = ''

  if (sortBy) {
    ordering = groupSortByIntoURLQuery(sortBy)
  }

  const queryParams = params
    ? {
        ...params,
        ...(params.department__id && params.department__id.length > 0
          ? { department__id: params.department__id.join(',') }
          : {}),
        ...(params.function__id && params.function__id.length > 0
          ? { function__id: params.function__id.join(',') }
          : {}),
        ordering,
      }
    : { ordering }

  return useFetchV2<PerformanceReviewAnalyticsInterface[]>({
    url: `${API.PERFORMANCE}/reviewAnalytics/groupBy/${groupType}`,
    params: queryParams ? { params: queryParams } : undefined,
    queryOptions: {
      enabled: options?.enabled ?? true,
    },
  })
}
