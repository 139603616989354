import React, { useMemo } from 'react'
import { Route, Switch, useParams } from 'react-router-dom'
import { Box, Flex, Icon, Token } from '@revolut/ui-kit'
import { connect } from 'lape'

import { FunctionInterface } from '@src/interfaces/functions'
import {
  functionsRequestsNew,
  silentDeleteFunction,
  useGetFunctionsStats,
  useUpdateFunction,
} from '@src/api/functions'
import { ROUTES } from '@src/constants/routes'
import {
  Colored,
  ColoredPercent,
} from '@components/ColumnInserts/ColoredPercent/ColoredPercent'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { PageWrapper } from '@components/Page/Page'
import QuickSummaryCount from '@components/QuickSummary/QuickSummaryCount'
import { getTalentStatsQuickSummary } from '@src/pages/Forms/CommonTalentTab/TalentStats'
import { EntityPermissions, FeatureFlags, PermissionTypes } from '@src/store/auth/types'
import Loader from '@components/CommonSC/Loader'
import { pathToUrl } from '@src/utils/router'
import { overallScoreToColor } from '@src/apps/People/Engagement/helpers'

import Roadmap from './Roadmap/Roadmap'
import Roles from './Roles/Roles'
import Settings from './Settings/Settings'
import Hierarchy from './Hierarchy/Hierarchy'
import Playbooks from './Playbooks/Playbooks'
import Requisitions from './Requisitions/Requisitions'
import Specialisations from './Specialisations/Specialisations'
import Talent from './Talent/Talent'
import Budget from './Budget/Budget'
import { AnalyticsDashboards } from './AnalyticsDashboards/AnalyticsDashboards'

import { Summary } from './Summary/Summary'
import { Engagement } from './Engagement/Engagement'
import { PageError } from '@src/features/Errors/components/Page/PageError'
import { useSelector } from 'react-redux'
import { selectFeatureFlags, selectPermissions } from '@src/store/auth/selectors'
import {
  useGetRequisitionSettings,
  useGetRoadmapSettings,
  useGlobalSettings,
} from '@src/api/settings'
import {
  OrgEntityInterface,
  OrgEntityProvider,
} from '@src/features/OrgEntityProvider/OrgEntityProvider'
import { EntityTypes, API } from '@src/constants/api'
import { Statuses } from '@src/interfaces'
import { EntityAvatar } from '@src/features/EntityAvatar/EntityAvatar'
import { FUNCTION_DEFAULT_ICON } from '@src/constants/common'
import SettingsButtons, { CopyButton } from '@src/features/SettingsButtons'
import { CommunicationActions } from '@src/features/CommunicationGroups/CommunicationActions'
import PermissionTransferButton from '@src/pages/PermissionTransfer/Button/PermissionTransferFormButton'
import LapeDeleteOrgUnitButton from '@src/features/SettingsButtons/DeleteOrgUnitButton/LapeDeleteOrgUnitButton'
import { goBack } from '@src/actions/RouterActions'
import { ArchiveFunctionButton } from '@src/pages/Forms/FunctionsForm/Summary/ArchiveFunctionButton'
import Form from '@src/features/Form/Form'
import { useLapeContext } from '@src/features/Form/LapeForm'

const Function = () => {
  const { values } = useLapeContext<FunctionInterface>()
  const params = useParams<{ id: string; tab: string }>()
  const backUrl = ROUTES.ORGANISATION.ROLES.FUNCTIONS
  const featureFlags = useSelector(selectFeatureFlags)
  const isCommercial = featureFlags?.includes(FeatureFlags.CommercialProduct)

  const permissions = useSelector(selectPermissions)

  const { data: roadmapSettings } = useGetRoadmapSettings()
  const { data: requisitionSettings } = useGetRequisitionSettings()
  const {
    settings: { engagement_enabled },
  } = useGlobalSettings()

  const { data: stats, isLoading, error } = useGetFunctionsStats(params.id)

  const canEdit = !!values?.field_options?.actions?.includes?.(EntityPermissions.Change)

  const entity = useMemo<OrgEntityInterface | undefined>(() => {
    return values
      ? {
          type: EntityTypes.function,
          data: values,
        }
      : undefined
  }, [values])

  if (isLoading) {
    return (
      <PageWrapper>
        <Loader />
      </PageWrapper>
    )
  }

  if (error) {
    return <PageError error={error} />
  }

  if (!values) {
    return (
      <PageWrapper>
        <PageHeader title="New Function" backUrl={backUrl} />
        <Settings />
      </PageWrapper>
    )
  }

  const tabs = [
    {
      title: 'Summary',
      path: ROUTES.FORMS.FUNCTION.SUMMARY,
      to: pathToUrl(ROUTES.FORMS.FUNCTION.SUMMARY, params),
      component: Summary,
      canView: !!values.id,
    },
    {
      title: 'Roadmap',
      path: ROUTES.FORMS.FUNCTION.ROADMAP,
      to: pathToUrl(ROUTES.FORMS.FUNCTION.ROADMAP, params),
      quickSummary: <ColoredPercent percent={values.roadmap_progress_percent * 100} />,
      component: Roadmap,
      canView: !!values.id && !!roadmapSettings?.enabled,
    },
    {
      key: 'analytics',
      title: 'Analytics',
      icon: <Icon name="BarChart" size={15} />,
      path: ROUTES.FORMS.FUNCTION.ANALYTICS_DASHBOARDS,
      to: pathToUrl(ROUTES.FORMS.FUNCTION.ANALYTICS_DASHBOARDS, params),
      component: AnalyticsDashboards,
      canView: !!values?.id,
      quickSummary: <QuickSummaryCount count={values.dashboard_count} />,
    },
    {
      title: 'Roles',
      path: ROUTES.FORMS.FUNCTION.ROLES,
      to: pathToUrl(ROUTES.FORMS.FUNCTION.ROLES, params),
      quickSummary: <QuickSummaryCount count={values.role_count} />,
      component: Roles,
      canView: !!values.id,
    },
    {
      title: 'Specialisations',
      path: ROUTES.FORMS.FUNCTION.SPECIALISATIONS,
      to: pathToUrl(ROUTES.FORMS.FUNCTION.SPECIALISATIONS, params),
      quickSummary: <QuickSummaryCount count={values.specialisation_count} />,
      component: Specialisations,
      canView: !!values.id,
    },
    {
      title: 'Talent',
      path: ROUTES.FORMS.FUNCTION.TALENT.ANY,
      to: pathToUrl(ROUTES.FORMS.FUNCTION.TALENT.GENERAL, params),
      quickSummary: getTalentStatsQuickSummary({
        headcount: values?.headcount,
        nips: stats?.nips,
      }),
      component: Talent,
      canView: !!values && !!values.id,
    },
    {
      title: 'Hierarchy',
      quickSummary: stats?.missing_employee_count ? (
        <Colored color={Token.color.warning}>{stats.missing_employee_count}</Colored>
      ) : null,
      path: ROUTES.FORMS.FUNCTION.HIERARCHY,
      to: pathToUrl(ROUTES.FORMS.FUNCTION.HIERARCHY, params),
      component: Hierarchy,
      canView: !!values.id,
    },
    {
      title: 'Requisitions',
      path: ROUTES.FORMS.FUNCTION.REQUISITIONS,
      to: pathToUrl(ROUTES.FORMS.FUNCTION.REQUISITIONS, params),
      component: Requisitions,
      quickSummary: <QuickSummaryCount count={values.requisition_headcount} />,
      canView:
        !!values.id &&
        requisitionSettings?.enabled &&
        permissions.includes(PermissionTypes.ViewRequisitions),
    },
    {
      title: 'Engagement',
      path: ROUTES.FORMS.FUNCTION.ENGAGEMENT.ANY,
      to: pathToUrl(ROUTES.FORMS.FUNCTION.ENGAGEMENT.CATEGORIES, params),
      component: Engagement,
      canView:
        engagement_enabled &&
        !!values.id &&
        permissions.includes(PermissionTypes.ViewEngagementTabs),
      quickSummary: (
        <QuickSummaryCount
          show0InGrey
          count={values.average_score}
          color={overallScoreToColor(values.average_score)}
        />
      ),
    },
    {
      title: 'Playbooks',
      path: ROUTES.FORMS.FUNCTION.PLAYBOOKS,
      to: pathToUrl(ROUTES.FORMS.FUNCTION.PLAYBOOKS, params),
      quickSummary: <QuickSummaryCount count={values.playbook_count} />,
      component: Playbooks,
      canView: !!values.id && !isCommercial,
    },
    {
      title: 'Compensation',
      path: ROUTES.FORMS.FUNCTION.BUDGET,
      to: pathToUrl(ROUTES.FORMS.FUNCTION.BUDGET, params),
      component: Budget,
      canView: values?.field_options?.permissions?.includes(
        EntityPermissions.ViewCompensationReviews,
      ),
    },
  ]

  const filteredTabs = tabs.filter(tab => {
    if (tab.canView === undefined) {
      return true
    }

    return tab.canView
  })

  return (
    <OrgEntityProvider entity={entity}>
      <PageWrapper>
        <PageHeader
          title={
            <PageHeader.Title
              title={values.name || 'New Function'}
              avatar={
                <EntityAvatar
                  data={values}
                  defaultIcon={FUNCTION_DEFAULT_ICON}
                  api={useUpdateFunction}
                  apiUrl={API.FUNCTIONS}
                  canEdit={canEdit}
                  onSuccess={data => {
                    if (values && data) {
                      values.icon = data?.icon || null
                      values.avatar = data?.avatar || null
                    }
                  }}
                />
              }
              labels={
                <PageHeader.LabelsBar>
                  {values.status.id === Statuses.archived && (
                    <PageHeader.Label useTag color={Token.color.greyTone20}>
                      {values.status.name}
                    </PageHeader.Label>
                  )}
                  <PageHeader.Label useTag icon="RepairTool">
                    Function
                  </PageHeader.Label>
                  {values.owner && (
                    <PageHeader.Label
                      icon="Profile"
                      to={pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, {
                        id: values.owner.id,
                      })}
                    >
                      {values.owner.name}
                    </PageHeader.Label>
                  )}
                </PageHeader.LabelsBar>
              }
              actions={
                !!values?.id && (
                  <SettingsButtons>
                    <CommunicationActions group={values.communication_group} />
                    <PermissionTransferButton
                      path={ROUTES.FORMS.PERMISSION_MANAGEMENT.ENTITIES.FUNCTION}
                    />
                    <CopyButton
                      afterSubmitUrl={pathToUrl(ROUTES.FORMS.FUNCTION.SETTINGS, {})}
                    />
                    <LapeDeleteOrgUnitButton
                      onAfterDelete={() => goBack(ROUTES.ORGANISATION.ROLES.FUNCTIONS)}
                      deleteApi={silentDeleteFunction}
                      prefix="function"
                      displayName="function"
                    />
                    <ArchiveFunctionButton data={values} />
                  </SettingsButtons>
                )
              }
            />
          }
          backUrl={backUrl}
        >
          <Box pb="s-8" maxWidth="100vw">
            <TabBarNavigation tabs={filteredTabs} />
          </Box>
        </PageHeader>
        <Flex pt="s-8" flex="1 0" flexDirection="column" width="100%" minHeight={0}>
          <Switch>
            {tabs.map(tab => (
              <Route exact path={tab.path} key={tab.path}>
                <tab.component stats={stats} />
              </Route>
            ))}
          </Switch>
        </Flex>
      </PageWrapper>
    </OrgEntityProvider>
  )
}

export default connect(() => (
  <Form api={functionsRequestsNew}>
    <Function />
  </Form>
))
