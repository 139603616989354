import React, { useEffect, useMemo } from 'react'
import { Route, Switch, useParams } from 'react-router-dom'
import { connect, useLape } from 'lape'

import { ROUTES } from '@src/constants/routes'
import {
  ColoredPercent,
  getFunctionNIPSColor,
} from '@components/ColumnInserts/ColoredPercent/ColoredPercent'
import Loader from '@components/CommonSC/Loader'
import { pathToUrl } from '@src/utils/router'
import AuditCircles from '@components/ColumnInserts/AuditCircles/AuditCircles'
import RiskCircles from '@components/ColumnInserts/RiskCircles/RiskCircles'

import { LegacyAnalyticsDashboards } from './AnalyticsDashboards/AnalyticsDashboards'
import CompanyFindings from './Findings/Findings'
import CompanyRoadmap from './Roadmap/Roadmap'
import CompanyRisk from './Risk/Risk'
import CompanyValues from './Values'
import { getCompany } from '@src/api/company'
import { CompanyInterface } from '@src/interfaces/company'
import { PageWrapper } from '@components/Page/Page'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { Box, Flex, Link } from '@revolut/ui-kit'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import Tooltip from '@components/Tooltip/Tooltip'
import Talent from '@src/pages/Forms/Company/Talent/Talent'
import { FeatureFlags, PermissionTypes } from '@src/store/auth/types'
import isNumber from 'lodash/isNumber'
import { formatNumber } from '@src/utils/format'
import { getTalentStats } from '@src/api/talent'
import { TalentStatsInterface } from '@src/interfaces/functions'
import Compensation from './Compensation'
import { useSelector } from 'react-redux'
import { selectFeatureFlags, selectPermissions } from '@src/store/auth/selectors'
import Spend from './Budget/Spend'
import { Engagement } from './Engagement/Engagement'
import {
  useGetOrganisationSettings,
  useGlobalSettings,
  useGetRoadmapSettings,
  useGetSkillsSettings,
} from '@src/api/settings'
import QuickSummaryCount from '@components/QuickSummary/QuickSummaryCount'
import { EntityTypes } from '@src/constants/api'
import {
  OrgEntityInterface,
  OrgEntityProvider,
} from '@src/features/OrgEntityProvider/OrgEntityProvider'
import { GoalsTab } from '@src/features/Goals/GoalsTab'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { InternalRedirect } from '@src/components/InternalLink/InternalRedirect'

const Company = () => {
  const params = useParams<{ id: string }>()
  const state = useLape<{
    data?: CompanyInterface
    loading: boolean
    stats?: TalentStatsInterface
  }>({
    data: undefined,
    loading: true,
    stats: undefined,
  })
  const backUrl = ROUTES.ORGANISATION.TEAMS.TEAMS

  const featureFlags = useSelector(selectFeatureFlags)
  const permissions = useSelector(selectPermissions)

  const { data: settings } = useGetOrganisationSettings()
  const {
    settings: { engagement_enabled },
  } = useGlobalSettings()
  const {
    settings: { compensation_enabled },
  } = useGlobalSettings()
  const { data: roadmapSettings } = useGetRoadmapSettings()
  const { data: skillsSettings } = useGetSkillsSettings()

  useEffect(() => {
    getCompany().then(res => {
      state.data = res.data
      state.loading = false
    })
    getTalentStats('', params.id).then(res => {
      if (res.data) {
        state.stats = res.data
      }
    })
  }, [])

  const entity = useMemo<OrgEntityInterface | undefined>(
    () =>
      state.data
        ? {
            type: EntityTypes.company,
            data: state.data,
          }
        : undefined,
    [state.data],
  )

  if (state.loading || !state.data) {
    return (
      <PageWrapper>
        <Loader />
      </PageWrapper>
    )
  }

  const nips = isNumber(state.stats?.nips) ? formatNumber(state.stats!.nips * 100, 1) : 0

  const tabs = [
    {
      title: 'Goals',
      path: ROUTES.FORMS.COMPANY.GOALS.ANY,
      to: pathToUrl(ROUTES.FORMS.COMPANY.GOALS.GENERAL, params),
      quickSummary: Number.isFinite(state.data.goals_progress_percent) && (
        <ColoredPercent percent={state.data.goals_progress_percent! * 100} />
      ),
      component: GoalsTab,
    },
    {
      title: 'Roadmap',
      path: ROUTES.FORMS.COMPANY.ROADMAP,
      to: pathToUrl(ROUTES.FORMS.COMPANY.ROADMAP, params),
      canView: !!roadmapSettings?.enabled,
      quickSummary: <ColoredPercent percent={state.data.roadmap_progress_percent} />,
      component: CompanyRoadmap,
    },
    {
      title: 'Talent',
      path: ROUTES.FORMS.COMPANY.TALENT.ANY,
      to: pathToUrl(ROUTES.FORMS.COMPANY.TALENT.PERFORMANCE, params),
      quickSummary: (
        <Tooltip
          placement="bottom"
          text="Net Individual Performance Score (NIPS) = %Exceptional + %Exceeding + %Performing - %Developing - %Unsatisfactory"
        >
          <ColoredPercent percent={nips} color={getFunctionNIPSColor(nips)} />
        </Tooltip>
      ),
      component: Talent,
      canView:
        !!state.data &&
        state.data?.field_options?.permissions?.includes(
          PermissionTypes.ViewCompanyPerformanceTalentTab,
        ),
    },
    {
      title: 'Analytics',
      path: ROUTES.FORMS.COMPANY.ANALYTICS_DASHBOARDS,
      to: pathToUrl(ROUTES.FORMS.COMPANY.ANALYTICS_DASHBOARDS, params),
      component: LegacyAnalyticsDashboards,
      canView: !featureFlags.includes(FeatureFlags.CommercialProduct),
      quickSummary: <QuickSummaryCount count={state.data.dashboard_count} />,
    },
    {
      title: 'Findings',
      path: ROUTES.FORMS.COMPANY.FINDINGS,
      to: pathToUrl(ROUTES.FORMS.COMPANY.FINDINGS, params),
      canView: !!settings?.findings?.enabled,
      quickSummary: <AuditCircles data={state.data} />,
      component: CompanyFindings,
    },
    {
      title: 'Risk',
      path: ROUTES.FORMS.COMPANY.RISK.ANY,
      canView: !!settings?.risk?.enabled,
      to: pathToUrl(ROUTES.FORMS.COMPANY.RISK.SUMMARY, params),
      quickSummary: <RiskCircles data={state.data} />,
      component: CompanyRisk,
    },
    {
      title: 'Values',
      path: ROUTES.FORMS.COMPANY.VALUES.ANY,
      to: pathToUrl(ROUTES.FORMS.COMPANY.VALUES.CULTURAL_SKILLS, params),
      canView: !skillsSettings?.behaviours_assessment_enabled,
      quickSummary: state.data?.culture_skills_count,
      component: CompanyValues,
    },
    {
      title: 'Engagement',
      path: ROUTES.FORMS.COMPANY.ENGAGEMENT.ANY,
      to: pathToUrl(ROUTES.FORMS.COMPANY.ENGAGEMENT.CATEGORIES, params),
      component: Engagement,
      canView:
        engagement_enabled && permissions.includes(PermissionTypes.ViewEngagementTabs),
    },
    {
      title: 'Budget',
      path: ROUTES.FORMS.COMPANY.BUDGET.ANY,
      to: pathToUrl(ROUTES.FORMS.COMPANY.BUDGET.SPEND, params),
      canView: !!settings?.enable_budget_management,
      quickSummary: null,
      component: Spend,
    },
    {
      title: 'Compensation',
      path: ROUTES.FORMS.COMPANY.COMPENSATION,
      to: pathToUrl(ROUTES.FORMS.COMPANY.COMPENSATION, params),
      canView:
        compensation_enabled &&
        !!settings?.enable_budget_management &&
        permissions.includes(PermissionTypes.ViewCompanyCompensationReview),
      quickSummary: null,
      component: Compensation,
    },
  ]

  const filteredTabs = tabs.filter(organisationSubtab => {
    if (organisationSubtab.canView === undefined) {
      return true
    }

    return organisationSubtab.canView
  })

  return (
    <OrgEntityProvider entity={entity}>
      <PageWrapper>
        <PageHeader
          title={state.data.name}
          subtitle={
            state.data.owner?.id &&
            state.data.owner?.name && (
              <Link
                color="inherit"
                to={pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, { id: state.data.owner.id })}
                use={InternalLink}
                target="_blank"
              >
                Owner: {state.data.owner.name}
              </Link>
            )
          }
          backUrl={backUrl}
        >
          <Box pb="s-8" maxWidth="100vw">
            <TabBarNavigation tabs={filteredTabs} />
          </Box>
        </PageHeader>
        <Flex pt="s-8" flex="1 0" flexDirection="column" width="100%" minHeight={0}>
          <Switch>
            {filteredTabs.map(tab => (
              <Route exact path={tab.path} key={tab.path}>
                <tab.component data={state.data!} />
              </Route>
            ))}
            <InternalRedirect to={filteredTabs[0].path} />
          </Switch>
        </Flex>
      </PageWrapper>
    </OrgEntityProvider>
  )
}

export default connect(Company)
