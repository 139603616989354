import { SeniorityInterface } from '@src/interfaces/seniority'
import { EmployeeInterface, NameIdInterface } from '@src/interfaces/employees'
import { OptionInterface } from '@src/interfaces/selectors'
import React, { useEffect, useMemo, useState } from 'react'
import {
  EligibilityCheckItem,
  EligibilityCheckResult,
  ProgressionTrack,
  PromotionNominationInterface,
} from '@src/interfaces/promotions'
import { checkPromotionEligibility } from '@src/api/promotions'
import {
  Box,
  Color,
  Flex,
  Icon,
  InputGroup,
  Link,
  Text,
  Token,
  VStack,
} from '@revolut/ui-kit'
import { CheckSuccess, ExclamationTriangle } from '@revolut/icons'
import { PromotionTrack } from './PromotionTrack'
import LapeNewTextArea from '@src/components/Inputs/LapeFields/LapeNewTextArea'
import HideIfCommercial from '@src/components/HideIfCommercial/HideIfCommercial'
import { CONTACT_PERFORMANCE_TEAM_LINK } from '@src/constants/externalLinks'
import LapeMultiInput from '@src/components/Inputs/LapeFields/LapeMultiInput'
import { useLapeContext } from '@src/features/Form/LapeForm'

export interface PromotionCriteriaProps {
  employeeId: number
  targetSeniority: SeniorityInterface
  targetSpecialisation: NameIdInterface | OptionInterface
  nominationId: string
  showSubtitle?: boolean
  employee?: EmployeeInterface
  editMode?: boolean
  nomination?: PromotionNominationInterface
  progressionTrack: ProgressionTrack
}

export const EligibilityCriteria = ({
  employeeId,
  targetSeniority,
  targetSpecialisation,
  nominationId,
  showSubtitle = false,
  employee,
  editMode,
  nomination,
  progressionTrack,
}: PromotionCriteriaProps) => {
  const [eligibility, setEligibility] = useState<EligibilityCheckResult | null>(null)
  const { values } = useLapeContext<PromotionNominationInterface>()

  useEffect(() => {
    let preventResult: boolean = false

    checkPromotionEligibility(
      employeeId,
      {
        seniority: targetSeniority,
        specialisation: targetSpecialisation,
        progression_track: progressionTrack,
      },
      nominationId,
    ).then(res => {
      if (preventResult) {
        return
      }
      setEligibility(res.data)
    })

    return () => {
      preventResult = true
    }
  }, [employeeId, targetSeniority, targetSpecialisation, nominationId])

  const EligibilityFact = useMemo(() => {
    if (eligibility?.meets_eligibility_criteria) {
      return (
        <Flex justifyContent="space-between" alignItems="center">
          <Text variant="primary">Eligibility criteria met</Text>
          <CheckSuccess size={20} color="success" />
        </Flex>
      )
    }

    return (
      <Flex justifyContent="space-between" alignItems="center">
        <Text variant="primary">Eligibility criteria not met</Text>
        <ExclamationTriangle size={20} color="error" />
      </Flex>
    )
  }, [eligibility?.meets_eligibility_criteria])

  const renderCriteria = (criteria: EligibilityCheckItem) => {
    return (
      <Flex
        flexDirection="row"
        alignItems="center"
        key={criteria.eligibility_criteria.name}
      >
        {criteria.passed ? (
          <Icon name="CheckSuccess" size={20} color={Token.color.success} />
        ) : (
          <Icon name="CrossCircle" size={20} color={Token.color.danger} />
        )}
        <Text ml="s-8">{criteria.eligibility_criteria.name}</Text>
      </Flex>
    )
  }

  return (
    <VStack space="s-16" width="100%">
      {EligibilityFact}
      {!eligibility?.meets_eligibility_criteria && showSubtitle && (
        <Box mt="-s-8">
          <Text variant="caption" color="grey-tone-50">
            Employees who do not meet the criteria will be flagged, with a reduced chance
            of being approved at a later stage. Strong rationale for why they deserve
            promotion now, will be asked for.
          </Text>
        </Box>
      )}
      <VStack space="s-8">
        {eligibility?.eligibility_criteria_results.map(criteria =>
          renderCriteria(criteria),
        )}
        <PromotionTrack
          sublevel={employee?.specialisation_seniority_sublevel?.sublevel}
          promotionTrack={progressionTrack}
        />

        {!eligibility?.meets_eligibility_criteria && editMode && (
          <VStack space="s-8" mt="s-24">
            <Text fontSize="16px" fontWeight={500}>
              This employee does not met the eligibility criteria for promotion at this
              time
            </Text>
            <LapeNewTextArea
              required
              label="Why should this employee be reviewed now?"
              name="business_requirements_justification"
            />
          </VStack>
        )}
      </VStack>

      {editMode && (
        <>
          <Text fontSize="16px" fontWeight={500}>
            Evidence cases that demonstrate readiness for promotion
          </Text>
          <Text fontSize="14px" fontWeight={400} color={Color.GREY_TONE_50}>
            If you wish to continue, please provide cases as evidence to justify why this
            employee should be considered for promotion <b>now.</b> Use the format
            suggested and at least 3 cases are recommended.
            <HideIfCommercial>
              Please raise any questions to the{' '}
              <Link href={CONTACT_PERFORMANCE_TEAM_LINK} target="_blank">
                Performance Service Desk.
              </Link>
            </HideIfCommercial>
          </Text>
          <InputGroup>
            <LapeMultiInput<string>
              maxLength={5000}
              name="justification"
              required
              preFillWith={`Situation:\nTask/actions:\nResults:\nValue demonstrated:`}
              useTextArea
              label="Evidence case"
              valueFieldName={undefined}
              value={values.justification || nomination?.justification}
              disabled={!editMode}
            />
          </InputGroup>
        </>
      )}
    </VStack>
  )
}
