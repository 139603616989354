import { useReviewCycles } from '@src/api/reviewCycles'

import { SORT_DIRECTION } from '@src/interfaces/data'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { BaseOptionId } from '@src/interfaces/selectors'
import { useQuery } from '@src/utils/queryParamsHooks'
import { useEffect, useState } from 'react'

const initialSorting = [{ sortBy: 'start_date_time', direction: SORT_DIRECTION.ASC }]

export const useReviewCycleData = ({
  activeCycleId,
  employeeId,
  managerId,
}: {
  activeCycleId?: BaseOptionId
  employeeId: number
  managerId: number
}) => {
  const { query, changeQueryParam } = useQuery<{ cycle_id?: string }>()
  const [selectedCycle, setSelectedCycle] = useState<ReviewCyclesInterface>()
  const { data: reviewCycles, isLoading: isLoadingReviewCycles } = useReviewCycles({
    sortBy: initialSorting,
    filters: [
      {
        columnName: 'employees',
        filters: [
          { id: employeeId, name: `${employeeId}` },
          { id: managerId, name: `${managerId}` },
        ],
      },
    ],
  })

  useEffect(() => {
    if (!isLoadingReviewCycles && reviewCycles?.results) {
      const cycle =
        // select cycle based on passed param id if present
        reviewCycles.results.find(item => item.id === Number(activeCycleId)) ||
        // else select current cycle
        reviewCycles.results.find(item => item.offset === 0) ||
        // else  cycle closest to current
        reviewCycles.results.reduce((closestCycle, currentCycle) => {
          if (currentCycle.offset === undefined) {
            return closestCycle
          }

          if (
            closestCycle.offset === undefined ||
            Math.abs(currentCycle.offset) < Math.abs(closestCycle.offset)
          ) {
            return currentCycle
          }

          return closestCycle
        }, reviewCycles.results[0])
      if (!query.cycle_id) {
        changeQueryParam('cycle_id', String(cycle.id))
      }
      setSelectedCycle(cycle)
    }
  }, [reviewCycles?.results, isLoadingReviewCycles, activeCycleId])

  const reviewCycleOptions = {
    options:
      reviewCycles?.results?.map(cycle => {
        return {
          id: cycle.id,
          name: cycle.name,
          offset: cycle.offset,
          is_test: cycle.is_test,
          is_adhoc: cycle.is_adhoc,
        }
      }) || [],
  }

  const dateRanges = {
    startDate: selectedCycle?.start_date_time,
    endDate: selectedCycle?.end_date_time,
  }
  return {
    selectedCycle,
    reviewCycleOptions,
    isLoading: isLoadingReviewCycles,
    dateRanges,
  }
}
