import { api, apiV2, apiWithoutHandling } from '@src/api/index'
import { API } from '@src/constants/api'
import {
  EstimatedRequisitionAnalytics,
  RecruitmentAnalytics,
  SpecialisationLocations,
} from '@src/interfaces/specialisations'
import { AxiosResponse } from 'axios'
import {
  GetRequestData,
  RequestInterface,
  RequestInterfaceNew,
  tableRequests,
} from '@src/interfaces'
import { SeniorityInterface, SenioritySublevelInterface } from '@src/interfaces/seniority'
import { useFetch, useUpdate } from '@src/utils/reactQuery'
import { LocationInterface } from '@src/interfaces/requisitions'
import { HiringProcessInterface } from '@src/interfaces/hiringProccess'
import { CompetencyMatrixInterface, SpecialisationInterface } from '@src/interfaces/roles'
import {
  getNormalizedDeliverablesCompetencyMatrix,
  getNormalizedFuncCompetencyMatrix,
} from '@src/features/CompetencyMatrixTable/utils'
import { getTalentStats } from '@src/api/talent'
import { filterSortPageIntoQuery } from '@src/utils/table'

export const getRecruitmentAnalytics = (id: number) => {
  return api.get<RecruitmentAnalytics>(
    `${API.SPECIALISATIONS}/${id}/recruitmentAnalytics`,
  )
}

export const calcEstimatedRequisitionAnalytics = (id: number, headcount: number) => {
  return api.post<EstimatedRequisitionAnalytics>(
    `${API.SPECIALISATIONS}/${id}/estimatedLastRequisitionAnalyticsFields`,
    { headcount },
  )
}

export const getSpecialisationSeniorities = async (
  id: number | string,
): Promise<AxiosResponse<SeniorityInterface[]>> =>
  api.get(`${API.SPECIALISATIONS}/${id}/seniorities`)

export const useGetSpecialisationSeniorities = (id: number | string | null) => {
  const url = `${API.SPECIALISATIONS}/${id}/seniorities`
  return useFetch<SeniorityInterface[]>(id ? url : null)
}

export const useGetSpecialisationPreferredHiringLocations = (
  specialisationId?: number | string,
) =>
  useFetch<LocationInterface[]>(
    specialisationId
      ? `${API.SPECIALISATIONS}/${specialisationId}/preferredHiringLocations`
      : null,
  )

export const getSpecialisationPreferredHiringLocations = (
  specialisationId: number | string,
) =>
  api.get<LocationInterface[]>(
    `${API.SPECIALISATIONS}/${specialisationId}/preferredHiringLocations`,
  )

export const useGetSpecialisationLocations = (id?: number | string) => {
  const url = `${API.SPECIALISATIONS}/${id}/locations`
  return useFetch<SpecialisationLocations>(id ? url : null)
}

export const getSpecialisationSublevels = (specialisationId: number) =>
  api.get<GetRequestData<SenioritySublevelInterface>>(
    `${API.SPECIALISATIONS}/${specialisationId}/senioritySubLevels`,
  )

export const renameSenioritySublevel = (
  specialisationId: number,
  sublevelId: number,
  newTitle: string,
) =>
  api.patch<SenioritySublevelInterface>(
    `${API.SPECIALISATIONS}/${specialisationId}/senioritySubLevels/${sublevelId}`,
    { job_title: newTitle },
  )

export const updateHiringProcessRounds = (
  specialisationId: number,
  hiring_process_rounds: HiringProcessInterface[],
) =>
  api.patch(
    `${API.SPECIALISATIONS}/${specialisationId}`,
    {
      hiring_process_rounds,
    },
    {
      params: {
        step: 'hiring_process',
      },
    },
  )

export const useGetSpecialisation = (specialisationId?: number | string) =>
  useFetch<SpecialisationInterface>({
    url: `${API.SPECIALISATIONS}/${specialisationId}`,
    queryOptions: { enabled: specialisationId !== undefined && specialisationId !== '' },
  })

export const bulkEditSpecialisation = (ids: number[], fields: Record<string, number>) =>
  apiWithoutHandling.patch(`${API.SPECIALISATIONS}/bulkEdit`, {
    ids,
    fields,
  })

export const bulkDeleteSpecialisations = (ids: number[]) =>
  apiWithoutHandling.post(`${API.SPECIALISATIONS}/bulkDelete`, { ids })

export const specialisationsRequests: RequestInterface<SpecialisationInterface> = {
  getItems: async ({ sortBy, filters, page }) =>
    api.get(API.SPECIALISATIONS, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    }),
  getItem: async id => api.get(`${API.SPECIALISATIONS}/${id}`),
  patchItem: async (data, id) => api.patch(`${API.SPECIALISATIONS}/${id}`, data),
  deleteItem: async id => api.delete(`${API.SPECIALISATIONS}/${id}`),
  postItem: async data => api.post(API.SPECIALISATIONS, data),
}
export const specialisationsRequestsNew: RequestInterfaceNew<SpecialisationInterface> = {
  get: async ({ id }) => api.get<SpecialisationInterface>(`${API.SPECIALISATIONS}/${id}`),
  update: async (diff, { id }, data) => {
    const values = {
      ...diff,
    }

    if (diff.seniority_min || diff.seniority_max) {
      values.functional_competency_matrix = getNormalizedFuncCompetencyMatrix(data)
      values.deliverables_competencies = getNormalizedDeliverablesCompetencyMatrix(data)
    }

    return apiWithoutHandling.patch(`${API.SPECIALISATIONS}/${id}`, values)
  },
  submit: async data => {
    const values = {
      ...data,
      functional_competency_matrix: getNormalizedFuncCompetencyMatrix(data),
      deliverables_competencies: getNormalizedDeliverablesCompetencyMatrix(data),
    }

    return data?.id
      ? apiWithoutHandling.patch(`${API.SPECIALISATIONS}/${data.id}`, values)
      : apiWithoutHandling.post(API.SPECIALISATIONS, values)
  },
  delete: async ({ id }) => api.delete(`${API.SPECIALISATIONS}/${id}`),
}
export const specialisationTalentTabStats = (id: string) =>
  getTalentStats('specialisation__id', id)
export const updateSpecialisation = (
  id: number,
  data: Partial<SpecialisationInterface>,
) => apiWithoutHandling.patch(`${API.SPECIALISATIONS}/${id}`, data)

export const useUpdateSpecialisation = () =>
  useUpdate<SpecialisationInterface, SpecialisationInterface>(
    API.SPECIALISATIONS,
    undefined,
    undefined,
    false,
    (_oldData, newData) => newData,
    true,
  )

export const specialisationHiringStagesRequests = (
  id?: number,
): tableRequests<HiringProcessInterface, undefined> => ({
  getItems: ({ sortBy, filters, page }) =>
    apiV2.get(`${API.SPECIALISATIONS}/${id}/specialisationHiringStages`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    }),
})

export const updateSpecialisationCompetencyMatrix = (
  id: number,
  data: CompetencyMatrixInterface[],
) =>
  specialisationsRequestsNew.update(
    { functional_competency_matrix: data },
    { id: String(id) },
  )
