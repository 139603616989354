import React, { useEffect, useState } from 'react'
import { Avatar, Box, Group, Item, VStack } from '@revolut/ui-kit'
import {
  ReviewCategory,
  ReviewDataInterface,
  ReviewerRelation,
  ReviewScorecardInterface,
} from '@src/interfaces/performance'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { useEmployeeProfileData } from '@src/pages/EmployeeProfile/Preview/hooks'
import { OptionInterface } from '@src/interfaces/selectors'
import { useQuery } from '@src/utils/queryParamsHooks'
import { useFetchPerformanceSummary } from '@src/pages/EmployeeProfile/Preview/Performance/Summary/hooks'
import { SummarySidebarRoleSeniority } from '@src/pages/Forms/EmployeePerformanceLayout/components/summary/SummarySidebarRoleSeniority'
import { getCleanValuesBeforeSave } from '@src/utils/performance'
import { postPerformanceReviewForSummary } from '@src/api/performanceReview'
import { Queries } from '@src/constants/api'
import ReviewersFilter from '@src/pages/EmployeeProfile/Preview/Performance/Summary/ReviewersFilter'
import SidebarSkeleton from '@src/pages/EmployeeProfile/Preview/Performance/Summary/SidebarSkeleton'
import NoReviewsWidget from '@src/pages/EmployeeProfile/Preview/Performance/Common/NoReviewsWidget'
import { SummaryReviewTables } from '@src/pages/Forms/EmployeePerformanceLayout/components/summary/SummaryReviewTables'
import { BarRaiserSummary } from '@src/pages/Forms/EmployeePerformanceLayout/components/summary/BarRaiserSummary'
import { SummaryFeedbackSection } from '@src/pages/Forms/EmployeePerformanceLayout/components/summary/SummaryFeedbackSection'
import { Statuses } from '@src/interfaces'

export const PerformanceSummary = ({
  reviewData,
  scrollToNotes,
}: {
  reviewData?: ReviewDataInterface
  scrollToNotes?: boolean
}) => {
  const { values } = useLapeContext<ReviewScorecardInterface>()
  const { data: employee } = useEmployeeProfileData({
    employeeId: values.reviewed_employee.id,
  })
  const [summaryData, setSummaryData] = useState<ReviewDataInterface>()
  const [filters, setFilters] = useState<OptionInterface[]>()
  const isPerfReview = values.category === ReviewCategory.Performance
  const reviewInProgress =
    values.status === Statuses.pending || values.status === Statuses.draft

  const fetchSummary = async () => {
    let cleanValues = values

    if (values.category === ReviewCategory.Performance) {
      cleanValues = getCleanValuesBeforeSave(values)
    }

    const result = await postPerformanceReviewForSummary(cleanValues, values.category)
    setSummaryData(result.data)
  }

  useEffect(() => {
    if (reviewData) {
      setSummaryData(reviewData)
    } else {
      fetchSummary()
    }
  }, [])

  const cycleId = values.cycle?.id ? String(values.cycle?.id) : undefined
  const employeeId = values.reviewed_employee.id
  const category = ReviewCategory.Performance

  const { query, changeQueryParam } = useQuery()

  const currentReviewSelected =
    reviewInProgress && query?.reviewer_id === String(values.reviewer?.id)

  const {
    data,
    isLoading,
    reviews,
    refresh: refreshSummary,
  } = useFetchPerformanceSummary(cycleId, employeeId, category)

  useEffect(() => {
    changeQueryParam(Queries.ReviewerId, String(values.reviewer?.id))
  }, [])

  const handleReviewerFilterChange = (options?: OptionInterface[]) => {
    setFilters(options)
    refreshSummary(options, undefined, false)
  }

  const getSubtitle = () => {
    switch (values.category) {
      case ReviewCategory.Upwards:
        return 'Upwards Review'
      case ReviewCategory.Probation:
        return `Probation Review · CP${values.probation_checkpoint?.number}`
      case ReviewCategory.PIP_V2:
      case ReviewCategory.PIP:
        return 'PIP Checkpoint'
      default:
        return values.cycle?.name
    }
  }

  if (!employee) {
    return null
  }

  return (
    <Group>
      <Item>
        <Item.Avatar>
          <Avatar useIcon="BarChart" />
        </Item.Avatar>
        <Item.Content>
          <Item.Title>Performance summary</Item.Title>
          <Item.Description>{getSubtitle()}</Item.Description>
        </Item.Content>
      </Item>
      <VStack space="s-16" p="s-16">
        {isPerfReview && values.cycle && !!reviews.length && (
          <ReviewersFilter
            data={reviews}
            onFilterChange={handleReviewerFilterChange}
            additionalFilters={
              reviewInProgress
                ? [
                    {
                      key: 'current_review',
                      name: 'My review',
                      filters: [values.reviewer.id],
                    },
                  ]
                : undefined
            }
            additionalOptions={
              reviewInProgress
                ? [
                    {
                      id: values.id,
                      reviewed_employee: employee,
                      reviewer: values.reviewer,
                      reviewer_relation: ReviewerRelation.Unknown,
                      updated_date: '',
                    },
                  ]
                : undefined
            }
            showFilters
            hideAll={reviewInProgress}
            hideDropdown={reviewInProgress}
          />
        )}

        {currentReviewSelected || !isPerfReview ? (
          <VStack space="s-16" mt="s-8">
            {employee && <SummarySidebarRoleSeniority employee={employee} />}
            <Box>
              {summaryData ? (
                <VStack space="s-32">
                  <SummaryReviewTables reviewData={summaryData} cycleId={cycleId} />
                  <BarRaiserSummary data={summaryData} />
                </VStack>
              ) : (
                <SidebarSkeleton />
              )}
            </Box>
          </VStack>
        ) : (
          <>
            {isLoading ? (
              <SidebarSkeleton />
            ) : (
              <>
                {!data?.reviews.length ? (
                  <NoReviewsWidget filtersApplied={!!filters?.length} />
                ) : (
                  <>
                    {data.summary && (
                      <>
                        <SummaryReviewTables
                          reviewData={data.summary}
                          cycleId={cycleId}
                        />
                        <BarRaiserSummary data={data.summary} completedReviews />
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
        {employee && (
          <SummaryFeedbackSection
            employee={employee}
            summary={data?.summary || undefined}
            scrollToNotes={scrollToNotes}
          />
        )}
      </VStack>
    </Group>
  )
}
