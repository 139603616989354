import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'

import { navigateTo } from '@src/actions/RouterActions'
import { getEngagementResultsTableV2Requests } from '@src/api/engagement'
import { EmptyTableRaw } from '@src/components/Table/EmptyTableRaw'
import AdjustableTable from '@src/components/TableV2/AdjustableTable'
import { useTable } from '@src/components/TableV2/hooks'
import {
  engagementResultsV2AverageScoreColumn,
  engagementResultsV2CategoryNameColumn,
  engagementResultsV2CommentsNumColumn,
  engagementResultsV2DistributionColumn,
} from '@src/constants/columns/engagementResultsV2'
import { ROUTES } from '@src/constants/routes'
import { TableNames } from '@src/constants/table'
import { RowInterface } from '@src/interfaces/data'
import { EngagementResultV2Interface } from '@src/interfaces/engagement'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { FeatureFlags } from '@src/store/auth/types'
import { pathToUrl } from '@src/utils/router'
import { ItemsToAnalyse, mainEntrypointBaseRoutes, ResultsInterface } from './common'
import {
  getDateRangeFilterParams,
  getSurveyRoundFilterParams,
  useApplyNonTableFilters,
} from './hooks/useApplyNonTableFilters'

const getRow = (
  surveyId: number,
  canViewDetails: boolean,
): RowInterface<EngagementResultV2Interface> => ({
  linkToForm: canViewDetails
    ? ({ id: categoryId }) =>
        navigateTo(
          pathToUrl(ROUTES.PERFORMANCE.ENGAGEMENT.SURVEY_RESULTS.CATEGORIES, {
            id: surveyId,
            categoryId,
          }),
        )
    : undefined,
  cells: [
    { ...engagementResultsV2CategoryNameColumn, width: 250 },
    { ...engagementResultsV2AverageScoreColumn, width: 120 },
    { ...engagementResultsV2DistributionColumn, width: 300 },
    { ...engagementResultsV2CommentsNumColumn, width: 100 },
  ],
})

export const ResultCategoriesTable = ({
  viewMode,
  surveyId,
  timelineFilter,
  scopeFilters = [],
}: ResultsInterface) => {
  const featureFlags = useSelector(selectFeatureFlags)
  const isEngagementV2 = featureFlags.includes(FeatureFlags.EngagementV2)

  const itemsToAnalyse: ItemsToAnalyse = 'categories'
  const disableTableRequests = viewMode !== 'table'

  const table = useTable<EngagementResultV2Interface>(
    getEngagementResultsTableV2Requests(surveyId, itemsToAnalyse),
    [
      ...getSurveyRoundFilterParams(timelineFilter),
      ...getDateRangeFilterParams(
        timelineFilter.dateFrom,
        timelineFilter.dateTo,
        itemsToAnalyse,
      ),
      ...scopeFilters,
    ],
    undefined,
    {
      disable: disableTableRequests,
      disableQuery: true,
    },
  )
  useApplyNonTableFilters({
    disable: disableTableRequests || table.loading,
    table,
    timelineFilter,
    scopeFilters,
    itemsToAnalyse: 'categories',
  })

  const isMainEntryPoint = useRouteMatch(mainEntrypointBaseRoutes.map(route => route.ANY))
  const row = useMemo(
    () => getRow(surveyId, !!isMainEntryPoint && isEngagementV2),
    [surveyId, isMainEntryPoint],
  )

  return (
    <AdjustableTable
      name={TableNames.EngagementSurveysResultCategories}
      row={row}
      hideCount
      emptyState={<EmptyTableRaw title="Results for the categories were not found" />}
      useWindowScroll
      {...table}
    />
  )
}
