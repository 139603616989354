import React, { useEffect, useState } from 'react'
import {
  Action,
  Avatar,
  Banner,
  BannerProps,
  Text,
  Token,
  Tooltip,
  useTooltip,
} from '@revolut/ui-kit'
import {
  AvailableInterviewerSlotRequestArguments,
  useFetchDynamicSlotsCount,
} from '@src/api/recruitment/interviewerScheduling'
import pluralize from 'pluralize'
import { SlotBannerSkeleton } from '@src/pages/Forms/Candidate/ScheduleSidebar/SlotBannerSkeleton'
import { getMessageFromApiError } from '@src/store/notifications/actions'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import isNumber from 'lodash/isNumber'
import { PreviewDynamicSlots } from '@src/pages/Forms/Candidate/ScheduleSidebar/PreviewDynamicSlots'

interface Props
  extends BannerProps,
    Pick<
      AvailableInterviewerSlotRequestArguments,
      | 'roundId'
      | 'stageId'
      | 'interviewId'
      | 'daysCount'
      | 'interviewers'
      | 'interviewerGroups'
    > {
  timeZoneId: string
}

export const SentSlotsBanner = ({
  roundId,
  stageId,
  interviewId,
  daysCount,
  interviewers,
  interviewerGroups,
  timeZoneId,
  ...props
}: Props) => {
  const interviewersTooltip = useTooltip()
  const groupsTooltip = useTooltip()
  const showStatusPopup = useShowStatusPopup()
  const [showAllSlots, setShowAllSlots] = useState(false)
  const { data, isLoading, error, isError } = useFetchDynamicSlotsCount({
    stageId,
    roundId,
    interviewId,
    daysCount,
  })

  useEffect(() => {
    if (error) {
      showStatusPopup({
        title: 'Cannot fetch count of slots',
        description: getMessageFromApiError(error),
        status: 'error',
      })
    }
  }, [error])

  if (!isNumber(daysCount)) {
    return null
  }

  const interviewersLabel = (
    <Text
      style={interviewers?.length ? { cursor: 'pointer' } : undefined}
      color={Token.color.foreground}
      {...interviewersTooltip.getAnchorProps()}
    >
      {interviewers?.length
        ? pluralize('interviewer', interviewers.length, true)
        : 'all eligible interviewers'}
    </Text>
  )
  const groupsLabel = (
    <Text
      style={interviewerGroups?.length ? { cursor: 'pointer' } : undefined}
      color={Token.color.foreground}
      {...groupsTooltip.getAnchorProps()}
    >
      {interviewerGroups?.length
        ? pluralize('group', interviewerGroups.length, true)
        : 'all eligible groups'}
    </Text>
  )
  const slotsCountLabel = pluralize('slot', data?.count, true)
  const daysCountLabel = pluralize('day', data?.days_for_suggested_slots, true)
  const thereIsLabel = pluralize('is', data?.count)

  const renderInterviewersTooltip = () => {
    if (!interviewers?.length) {
      return null
    }

    return (
      <Tooltip placement="top" {...interviewersTooltip.getTargetProps()}>
        {interviewers.map(item => item.name).join(', ')}
      </Tooltip>
    )
  }

  const renderGroupsTooltip = () => {
    if (!interviewerGroups?.length) {
      return null
    }

    return (
      <Tooltip placement="top" {...groupsTooltip.getTargetProps()}>
        {interviewerGroups.map(item => item.name).join(', ')}
      </Tooltip>
    )
  }

  return (
    <>
      <Banner {...props}>
        <Banner.Avatar>
          <Avatar useIcon="CalendarWeek" size={56} />
        </Banner.Avatar>
        <Banner.Content>
          <Banner.Title>Slots sent</Banner.Title>
          {isLoading ? (
            <SlotBannerSkeleton />
          ) : isError ? (
            <Banner.Description>Couldn't fetch amount of slots</Banner.Description>
          ) : (
            <Banner.Description>
              Interview slots were sent for {interviewersLabel} & {groupsLabel}. There{' '}
              {thereIsLabel} currently {slotsCountLabel} left for these interviewers in
              the next {daysCountLabel}.
              <>
                {renderInterviewersTooltip()}
                {renderGroupsTooltip()}
              </>
              {!!data?.count && (
                <Action
                  onClick={() => {
                    setShowAllSlots(prev => !prev)
                  }}
                >
                  {showAllSlots ? 'Hide' : 'Show'} all slots
                </Action>
              )}
            </Banner.Description>
          )}
        </Banner.Content>
      </Banner>

      {showAllSlots && (
        <PreviewDynamicSlots
          timeZoneId={timeZoneId}
          interviewId={interviewId}
          daysCount={daysCount}
          stageId={stageId}
          roundId={roundId}
        />
      )}
    </>
  )
}
