import { connect } from 'lape'
import React, { useEffect, useState } from 'react'
import set from 'lodash/set'
import find from 'lodash/find'
import isNil from 'lodash/isNil'
import { pathToUrl } from '@src/utils/router'
import { selectorKeys } from '@src/constants/api'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'
import { CompanyGoalInterface, CompanyGoalsChildren } from '@src/interfaces/companyGoals'
import { OptionInterface } from '@src/interfaces/selectors'
import styled from 'styled-components'
import { ROUTES } from '@src/constants/routes'
import { getLocationDescriptor, navigateReplace } from '@src/actions/RouterActions'
import { getKPIFormInitialValues } from '@src/pages/Forms/KpiForm/General'
import {
  Text,
  Flex,
  HStack,
  IconButton,
  InputGroup,
  TextButton,
  Token,
  Icon,
} from '@revolut/ui-kit'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { Grid } from '@components/CommonSC/Grid'
import NewSelectionCard from '@components/NewSelectionCard/NewSelectionCard'
import AutoStepper from '@components/Stepper/AutoStepper'
import NewStepperTitle from '@components/Stepper/NewStepperTitle'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import LapeNewTextArea from '@components/Inputs/LapeFields/LapeNewTextArea'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { companyKpiRequests } from '@src/api/kpis'
import { Statuses } from '@src/interfaces'
import Loader from '@components/CommonSC/Loader'
import { ReviewCyclesSelectorInterface } from '@src/interfaces/reviewCycles'
import { KpiInterface, KPIPerformanceTypes } from '@src/interfaces/kpis'
import { PageBody } from '@components/Page/PageBody'
import { PageActions } from '@src/components/Page/PageActions'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import RadioSelectInput, {
  RadioSelectOption,
} from '@components/Inputs/RadioSelectInput/RadioSelectInput'

const Message = styled.div<{ error: boolean }>`
  color: ${props => (props.error ? Token.color.red : Token.color.foreground)};
`

const General = () => {
  const user = useSelector(selectUser)
  const { values } = useLapeContext<CompanyGoalInterface>()

  const [isRouter, setIsRouter] = useState(true)
  const [total, setTotal] = useState(0)
  const [kpisLoading, setKpisLoading] = useState<boolean>(false)
  const [allKpiOptions, setAllKpiOptions] = useState<RadioSelectOption<KpiInterface>[]>(
    [],
  )
  const [availableKpiOptions, setAvailableKpiOptions] = useState<
    RadioSelectOption<KpiInterface>[]
  >([])

  const updateTotal = (): void => {
    let result = 0

    if (values.children) {
      result =
        values.children.reduce((prev, curr) => {
          return prev + Number(curr.weight || 0)
        }, 0) || 0
    }
    setTotal(result)
  }

  useEffect(() => {
    updateTotal()
  }, [values.children])

  useEffect(() => {
    values.children = []

    if (!values?.id) {
      values.owner = {
        id: user.id,
        name: user.full_name,
      }
      values.target_year = new Date().getFullYear()
    }
  }, [])

  useEffect(() => {
    if (values.review_cycle) {
      setKpisLoading(true)

      fetchKPIs(String(values.review_cycle.offset)).then(fetchedKPIs => {
        const newChildren: CompanyGoalsChildren[] = []
        const newAllOptions: RadioSelectOption<KpiInterface>[] = []
        const newAvailableOptions: RadioSelectOption<KpiInterface>[] = []

        fetchedKPIs.forEach(kpi => {
          const option = {
            value: kpi,
            label: kpi.name,
          }

          if (kpi.parent?.id === values.id) {
            newChildren.push(kpi as CompanyGoalsChildren)
          } else {
            newAvailableOptions.push(option)
          }

          newAllOptions.push(option)
        })

        values.children = newChildren
        setAllKpiOptions(newAllOptions)
        setAvailableKpiOptions(newAvailableOptions)

        setKpisLoading(false)
      })
    }
  }, [values.review_cycle])

  useEffect(() => {
    const availableOptions = allKpiOptions.filter(option => {
      return !find(values.children, kpi => kpi.id === option.value?.id)
    })
    setAvailableKpiOptions(availableOptions)
  }, [values.children])

  const fetchKPIs = (cycleOffset: string) => {
    values.children = []
    const unlinkedParentFilter = { name: '-1', id: '-1' }
    const thisParentFilter = { name: `${values.id}`, id: `${values.id}` }

    const filters = [
      {
        filters: [{ name: cycleOffset, id: cycleOffset }],
        columnName: 'review_cycle__offset',
      },
      {
        filters: [unlinkedParentFilter, ...(values.id ? [thisParentFilter] : [])],
        columnName: 'parent__id',
      },
      {
        filters: [{ name: Statuses.active, id: Statuses.active }],
        columnName: 'status',
      },
      {
        filters: [
          { name: KPIPerformanceTypes.business, id: KPIPerformanceTypes.business },
        ],
        columnName: 'kpi_performance_type',
      },
    ]
    return companyKpiRequests.getItems({ filters }).then(r => r.data.results)
  }

  const handleYearChange = (chosen: OptionInterface | null) => {
    set(values, 'target_year', chosen?.id)
  }

  const getNextTenYears = () =>
    Promise.resolve(
      Array.from({ length: 10 }).map((_, index) => {
        const year = new Date().getFullYear() + index
        return {
          id: year,
          name: `${year}`,
        }
      }),
    )

  const onPercentChange = (id: number, value?: string) => {
    const parsed = value ? parseFloat(value) : undefined
    const val = !isNil(parsed) && !isNaN(parsed) ? parsed : undefined

    set(values, `children[${id}].weight`, val)
    updateTotal()
  }

  const handleKPIChange = (id: number, value?: OptionInterface) => {
    set(values, `children[${id}]`, { ...value, weight: values.children[id]?.weight })
  }

  const renderMessage = () => {
    if (total === 100 || total === 0) {
      return ''
    }
    return `The sum of all weights should be 100%, currently ${total}%`
  }

  const handleDeleteKPI = (id: number) => {
    const result = [...values.children]
    result.splice(id, 1)

    values.children = result
  }

  const handleAddKPI = () => {
    if (total >= 100) {
      let newValues = [...values.children, {} as CompanyGoalsChildren]
      newValues = newValues.map((value, id) => {
        if (id !== newValues.length - 1) {
          return { ...value, weight: Math.floor(100 / newValues.length) }
        }

        return {
          ...value,
          weight: 100 - Math.floor(100 / newValues.length) * (newValues.length - 1),
        }
      }) as CompanyGoalsChildren[]

      values.children = newValues
    } else {
      values.children = [
        ...(values.children || []),
        { weight: 100 - total },
      ] as CompanyGoalsChildren[]
    }
  }

  if (!values.id && isRouter) {
    return (
      <PageBody>
        <Grid mt="s-20" flow="row" gap={24}>
          <NewSelectionCard
            onClick={() => {
              setIsRouter(false)
            }}
            icon="LogoRevolut"
            title="Create a company goal"
            subtitle="High-level strategic driver of the company"
          />
          <NewSelectionCard
            onClick={() => {
              navigateReplace(pathToUrl(ROUTES.FORMS.KPI.GENERAL, {}), {
                initialValues: getKPIFormInitialValues(user, {
                  is_company: true,
                }),
              })
            }}
            icon="Rocket"
            title="Create a company KPI"
            subtitle="Measurable result that supports one of the company goals"
          />
        </Grid>
      </PageBody>
    )
  }

  return (
    <>
      <PageBody>
        <AutoStepper>
          <NewStepperTitle title="General Info" />
          <InputGroup>
            <LapeNewInput label="Name" name="name" required />
            <InputGroup variant="horizontal">
              <LapeRadioSelectInput
                name="owner"
                label="Owner"
                selector={selectorKeys.employee}
              />
              <RadioSelectInput
                label="Target Year"
                onChange={handleYearChange}
                value={
                  values.target_year
                    ? { id: values.target_year, name: `${values.target_year}` }
                    : undefined
                }
                clearable={false}
                selector={getNextTenYears}
                width="30%"
              />
            </InputGroup>

            <LapeNewTextArea label="Description" name="description" required rows={1} />
          </InputGroup>

          <NewStepperTitle
            title="Company KPIs"
            subtitle="Select KPIs and set weights for them."
          />
          <InputGroup>
            <LapeRadioSelectInput<ReviewCyclesSelectorInterface>
              label="Cycle"
              name="review_cycle"
              selector={selectorKeys.review_cycles}
              selectDefaultOption={options => {
                return options.find(option => option.value.offset === '0')
              }}
              width="50%"
            />
            {kpisLoading ? <Loader /> : null}
            {values.children?.map((child, id) => {
              return (
                <InputGroup variant="horizontal" key={`company_kpi_${child.id}_${id}`}>
                  <RadioSelectInput<KpiInterface | CompanyGoalsChildren>
                    label="KPI"
                    onChange={option => {
                      if (option) {
                        handleKPIChange(id, option)
                      } else {
                        handleDeleteKPI(id)
                      }
                    }}
                    value={child}
                    options={availableKpiOptions}
                    referenceText={values?.children?.[id]?.id ? 'Open' : undefined}
                    referenceUrl={getLocationDescriptor(
                      pathToUrl(ROUTES.FORMS.KPI.PREVIEW, {
                        id: values?.children?.[id]?.id,
                      }),
                    )}
                  />
                  <LapeNewInput
                    name="ddd"
                    label="Weight"
                    hasError={total !== 0 && total !== 100}
                    type="number"
                    onChange={e => {
                      onPercentChange(id, e.currentTarget.value)
                    }}
                    value={child?.weight?.toString()}
                    width="30%"
                  />
                  <Flex height="100%" justifyContent="center" alignItems="center">
                    <IconButton
                      useIcon="Delete"
                      size={16}
                      color={Token.color.greyTone50}
                      onClick={() => {
                        handleDeleteKPI(id)
                      }}
                    />
                  </Flex>
                </InputGroup>
              )
            })}
            {availableKpiOptions.length > 0 && (
              <TextButton onClick={handleAddKPI}>
                <HStack align="center" space="s-8">
                  <Icon name="Plus" size={16} />
                  <Text>Add KPI</Text>
                </HStack>
              </TextButton>
            )}
            <Message error>{renderMessage()}</Message>
          </InputGroup>
        </AutoStepper>
      </PageBody>
      <PageActions>
        <NewSaveButtonWithPopup
          successText={values.id ? undefined : 'Your Company goal was submitted'}
          afterSubmitUrl={ROUTES.FORMS.COMPANY.GOALS.GENERAL}
        />
      </PageActions>
    </>
  )
}

export default connect(General)
