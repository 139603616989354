import React, { useRef, useState } from 'react'
import {
  Avatar,
  Box,
  Cell,
  chain,
  Color,
  DetailsCell,
  Flex,
  Group,
  HStack,
  MoreBar,
  Subheader,
  Text,
  TextButton,
  Token,
  Icon as UIKitIcon,
} from '@revolut/ui-kit'
import styled from 'styled-components'

import {
  ApprovalStatus,
  ChangeRequest,
  ChangeRequestStatus,
  EmployeeChangeRequestInterface,
} from '@src/interfaces/employeeChangeRequest'
import {
  AccessLostWidget,
  backUrl,
  Details,
  EmployeeDetails,
  HeaderAvatarCss,
} from './common'
import { PageBody } from '@src/components/Page/PageBody'
import { PageWrapper } from '@src/components/Page/Page'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import UserWithAvatar from '@src/components/UserWithAvatar/UserWithAvatar'
import CommentsSection from '@src/features/Comments/CommentsSection'
import {
  approveAll,
  approveChangeRequest,
  getEmployeeChangeRequestsCommentsAPI,
  rejectAll,
  rejectChangeRequest,
} from '@src/api/employeeChangeRequest'
import { CellTypes, ColumnCellInterface, RowInterface } from '@src/interfaces/data'
import { selectorKeys } from '@src/constants/api'
import AdjustableTable from '@src/components/TableV2/AdjustableTable'
import Table from '@src/components/TableV2/Table'
import SideBar from '@components/SideBar/SideBar'
import { getInitials } from '@src/utils/employees'
import StyledLink from '@src/components/Atoms/StyledLink'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import { formatDate } from '@src/utils/format'
import Icon from '@src/components/Icon/Icon'
import {
  fieldToChangeColumn,
  newValueColumn,
  oldValueColumn,
  statusColumn,
} from '@src/constants/columns/employeeChangeRequest'
import ActionWidget from '@src/components/ActionWidget/ActionWidget'
import { getLocationDescriptor } from '@src/actions/RouterActions'
import { TableNames } from '@src/constants/table'

interface RequestDetailsProps {
  employee: EmployeeDetails
  setChangeRequestDetails: (request: EmployeeChangeRequestInterface) => void
  request: EmployeeChangeRequestInterface
}

export const RequestDetails = ({
  request,
  setChangeRequestDetails,
  employee,
}: RequestDetailsProps) => {
  const [pendingApproveButtons, setPendingApproveButtons] = useState<
    Record<string, boolean>
  >({})
  const [pendingRejectButtons, setPendingRejectButtons] = useState<
    Record<string, boolean>
  >({})
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [selectedRequestId, setSelectedRequestId] = useState<number>()
  const [approveAllPending, setApproveAllPending] = useState(false)
  const [rejectAllPending, setRejectAllPending] = useState(false)

  const setCommentsSidebarOpen = useRef((_: boolean) => {})

  const requestCount = request.change_requests.length
  const pendingRequestCount = request.change_requests.filter(
    r => r.status.id === 'pending_approval',
  ).length
  const canApproveSome = request.change_requests.some(r => r.can_approve)
  const canApproveAll = request.can_approve_all_requests
  const selectedRequest = request.change_requests.find(r => r.id === selectedRequestId)

  const headerProgress = pendingRequestCount ? (
    <Text color={Token.color.orange}>
      Pending {`${pendingRequestCount}/${requestCount}`} approval
    </Text>
  ) : (
    <Text color={Token.color.green}>Complete</Text>
  )

  const onApproveOrReject = async (id: number, operation: 'approve' | 'reject') => {
    const buttonStateSetter =
      operation === 'approve' ? setPendingApproveButtons : setPendingRejectButtons
    const approveRejectApi =
      operation === 'approve' ? approveChangeRequest : rejectChangeRequest

    buttonStateSetter(prevState => ({ ...prevState, [id]: true }))

    try {
      const result = await approveRejectApi(employee.id, id)
      setChangeRequestDetails({
        ...request,
        change_requests: request.change_requests.map(r =>
          r.id === id ? result.data : r,
        ),
      })
    } finally {
      buttonStateSetter(prevState => ({ ...prevState, [id]: false }))
    }
  }

  const onApproveOrRejectAll = async (operation: 'approve' | 'reject') => {
    const buttonStateSetter =
      operation === 'approve' ? setApproveAllPending : setRejectAllPending
    const approveRejectApi = operation === 'approve' ? approveAll : rejectAll

    buttonStateSetter(true)

    try {
      const result = await approveRejectApi(employee.id, request.id)
      setChangeRequestDetails(result.data)
    } finally {
      buttonStateSetter(false)
    }
  }

  const fieldsRow: RowInterface<ChangeRequest> = {
    cells: [
      {
        ...fieldToChangeColumn,
        width: 150,
      },
      {
        ...oldValueColumn,
        width: 140,
      },
      {
        ...newValueColumn,
        width: 140,
      },
      {
        ...statusColumn,
        colors: data => requestStatusToColor[data.status.id],
        width: 120,
      } as ColumnCellInterface<ChangeRequest>,
      canApproveSome || canApproveAll
        ? ({
            type: CellTypes.insert,
            idPoint: '',
            dataPoint: '',
            sortKey: null,
            filterKey: null,
            selectorsKey: selectorKeys.none,
            title: 'Action',
            width: 140,
            insert: ({ data }) => {
              if (
                data.status.id === 'pending_approval' &&
                (data.can_approve || canApproveAll)
              ) {
                return (
                  <HStack width={120}>
                    <TextButton
                      onClick={e => {
                        e.stopPropagation()
                        onApproveOrReject(data.id, 'approve')
                      }}
                      color={Token.color.lightBlue}
                      height={22}
                      pl="0"
                      pr="s-8"
                      disabled={pendingRejectButtons[data.id]}
                    >
                      Approve
                    </TextButton>
                    <TextButton
                      onClick={e => {
                        e.stopPropagation()
                        onApproveOrReject(data.id, 'reject')
                      }}
                      color={Token.color.red}
                      height={22}
                      px="0"
                      disabled={pendingApproveButtons[data.id]}
                    >
                      Reject
                    </TextButton>
                  </HStack>
                )
              }
              return '-'
            },
          } as ColumnCellInterface<ChangeRequest>)
        : null,
    ].filter(Boolean) as ColumnCellInterface<ChangeRequest>[],
  }

  return (
    <PageWrapper>
      <PageHeader
        title={chain('Employee change request', headerProgress)}
        subtitle={
          <UserWithAvatar {...employee} status={undefined} css={HeaderAvatarCss} />
        }
        backUrl={backUrl(employee.id)}
      />

      <PageBody>
        {canApproveAll && pendingRequestCount > 0 ? (
          <Box mb="s-16">
            <MoreBar>
              <MoreBar.Action
                onClick={() => onApproveOrRejectAll('approve')}
                useIcon="Check"
                pending={approveAllPending}
                disabled={rejectAllPending}
              >
                Approve all
              </MoreBar.Action>
              <MoreBar.Action
                onClick={() => onApproveOrRejectAll('reject')}
                variant="negative"
                useIcon="Cross"
                pending={rejectAllPending}
                disabled={approveAllPending}
              >
                Reject all
              </MoreBar.Action>
            </MoreBar>
          </Box>
        ) : null}

        {canApproveSome && (
          <ActionWidget
            title="Please review the changes request for this employee"
            text="Changes to this employee’s profile have been requested and you have been
            appointed as one of the approvers for these changes. Please review the
            changes shown below and approve or reject them."
            avatarColor={Token.color.lightBlue}
            mb="s-16"
          />
        )}

        <AccessLostWidget request={request} />

        <Subheader variant="nested">
          <Subheader.Title>Approvals needed</Subheader.Title>
        </Subheader>
        <Table.Widget>
          <Table.Widget.Table>
            <AdjustableTable
              name={TableNames.EmployeeChangeRequests}
              row={fieldsRow}
              data={request.change_requests}
              count={0}
              noReset
              onRowClick={row => {
                setSelectedRequestId(row.id)
                setCommentsSidebarOpen.current(false)
                setSidebarOpen(true)
              }}
            />
          </Table.Widget.Table>
        </Table.Widget>

        <Box mb="s-16">
          <Subheader variant="nested">
            <Subheader.Title>Request details</Subheader.Title>
          </Subheader>
          <Details request={request} />
        </Box>

        <CommentsSection
          disableTodolistFeature
          api={getEmployeeChangeRequestsCommentsAPI(request.id)}
          onSideOpen={() => setSidebarOpen(false)}
          setSidebarOpen={setCommentsSidebarOpen}
        />
      </PageBody>

      <SideBar
        title={`${selectedRequest?.field.field_display_name} change`}
        isOpen={sidebarOpen}
        onClose={() => setSidebarOpen(false)}
        data-testid="sidebar"
      >
        <Group>
          <DetailsCell>
            <DetailsCell.Title>Old value</DetailsCell.Title>
            <DetailsCell.Content>{selectedRequest?.old_value || '-'}</DetailsCell.Content>
          </DetailsCell>
          <DetailsCell>
            <DetailsCell.Title>New value</DetailsCell.Title>
            <DetailsCell.Content>{selectedRequest?.new_value || '-'}</DetailsCell.Content>
          </DetailsCell>
          <DetailsCell>
            <DetailsCell.Title>Approval type</DetailsCell.Title>
            <DetailsCell.Content>{`${selectedRequest?.field.field_abbreviation} change`}</DetailsCell.Content>
          </DetailsCell>
        </Group>

        <ApprovalCard changeRequest={selectedRequest} />
      </SideBar>
    </PageWrapper>
  )
}

const ApproverContainer = styled(Box)`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas: 'icon status' '. approver';
  align-items: center;
`

const requestStatusToColor: Record<ChangeRequestStatus, Color> = {
  approved: Token.color.green,
  pending_approval: Token.color.orange,
  rejected: Token.color.red,
  complete: Token.color.green,
}

const getApprovalStatusColor = (status: ApprovalStatus) => {
  switch (status) {
    case 'skipped':
      return Token.color.greyTone20
    case 'pending':
      return Token.color.greyTone50
    default:
      return null
  }
}

interface ApprovalCardProps {
  changeRequest?: ChangeRequest
}

const ApprovalCard = ({ changeRequest }: ApprovalCardProps) => {
  const approvers = changeRequest?.approvals

  if (!approvers) {
    return null
  }

  const approverList = approvers.map(approver => {
    const {
      id,
      status,
      approver: { full_name, avatar, id: approverId },
      updated_on,
      label,
    } = approver
    const initials = full_name && getInitials(full_name)

    const details = (() => {
      if ((status.id === 'approved' || status.id === 'rejected') && updated_on) {
        return `${status.id} on ${formatDate(updated_on)}`
      }
      return ''
    })()

    return (
      <ApproverContainer key={id}>
        <StatusIcon status={status.id} />
        <Text
          color={getApprovalStatusColor(status.id)}
          fontWeight={500}
          my="s-8"
          gridArea="status"
        >
          {chain(label, status.name)}
        </Text>
        <Flex gridArea="approver" alignItems="center" mb="s-8">
          <Avatar
            image={avatar || undefined}
            size={24}
            opacity={status.id === 'skipped' ? 0.5 : 1}
          >
            <Text fontSize="tiny">{!avatar && initials}</Text>
          </Avatar>
          <Flex display="inline-block" ml="s-8">
            <StyledLink
              to={getLocationDescriptor(
                pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, { id: approverId }),
              )}
            >
              <Text textDecoration={status.id === 'skipped' ? 'line-through' : undefined}>
                {full_name}
              </Text>
            </StyledLink>
            <Text> {details}</Text>
          </Flex>
        </Flex>
      </ApproverContainer>
    )
  })

  return (
    <Box mt="s-16">
      <Cell display="block">
        <Flex justifyContent="space-between" mb="s-8">
          <Text fontWeight={500} color={Color.GREY_TONE_50}>
            Approval status
          </Text>
          <Text
            color={
              changeRequest?.status.id
                ? requestStatusToColor[changeRequest.status.id]
                : undefined
            }
            data-testid="sidebar-approval-status"
          >
            {changeRequest?.status.name}
          </Text>
        </Flex>
        {approverList}
      </Cell>
    </Box>
  )
}

interface StatusIconProps {
  status: ApprovalStatus
}

const StatusIcon = ({ status }: StatusIconProps) => {
  return (
    <Flex gridArea="icon" minWidth="s-24" justifyContent="center" mr="s-8">
      {(() => {
        switch (status) {
          case 'rejected':
            return <UIKitIcon name="Cross" color={Token.color.red} size={16} />
          case 'approved':
            return (
              <UIKitIcon
                name="Check"
                color={
                  status === 'approved' ? Token.color.lightGreen : Token.color.greyTone20
                }
                size={16}
              />
            )
          case 'pending':
            return <UIKitIcon name="Time" color={Token.color.greyTone50} size={16} />
          case 'skipped':
            return <Icon type="Skip" />
          default:
            return null
        }
      })()}
    </Flex>
  )
}
