import React, { useState } from 'react'

import {
  getKpiTargetAction,
  kpiDynamicColumn,
  kpiTargetCycleNameColumn,
  kpiTargetInitialValueColumn,
  kpiTargetStrategyColumn,
  kpiTargetValueColumn,
  kpiTargetWeightColumn,
} from '@src/constants/columns/kpiTargets'
import { RowInterface } from '@src/interfaces/data'
import { KpiTargets } from '@src/interfaces/kpis'
import AdjustableTable from '@components/Table/AdjustableTable'
import { useLapeContext } from '@src/features/Form/LapeForm'
import useAvailableCycles from '@src/features/FormTabs/Kpi/KPITargets/common/useAvailableCycles'
import {
  Button,
  Flex,
  HStack,
  VStack,
  Widget,
  Text,
  Skeleton,
  Switch,
  Token,
} from '@revolut/ui-kit'
import TemplateTargetForm from './TemplateTargetForm'
import { KpiTargetActionsOptions } from '@components/ColumnInserts/KPITargetActions/KPITargetActions'
import { KPITypes, TableNames } from '@src/constants/table'
import { SummaryTableContainer, summaryTableProps } from '@src/features/Summary/common'
import { InfoIconWithTooltip } from '@src/components/Icon/InfoIconWithTooltip'
import { KPITemplateInterface } from '@src/interfaces/KpiTemplates'

interface Props {
  readonly?: boolean
  dynamicTargets?: boolean
}

const getRow = (
  options: KpiTargetActionsOptions,
  readonly?: boolean,
  dynamicTargets?: boolean,
): RowInterface<KpiTargets> => ({
  cells: [
    {
      ...kpiTargetCycleNameColumn,
      width: 160,
    },
    {
      ...kpiTargetStrategyColumn,
      width: 110,
    },
    {
      ...(dynamicTargets
        ? kpiDynamicColumn('initial_value')
        : kpiTargetInitialValueColumn),
      width: 90,
    },
    {
      ...(dynamicTargets ? kpiDynamicColumn('target') : kpiTargetValueColumn),
      width: 90,
    },
    { ...kpiTargetWeightColumn, width: 110 },
    ...(!readonly
      ? [
          {
            ...getKpiTargetAction(options),
            width: 160,
          },
        ]
      : []),
  ],
})

const TemplateTargetsTable = ({ readonly, dynamicTargets }: Props) => {
  const { values } = useLapeContext<KPITemplateInterface>()

  const [isFormOpen, setIsFormOpen] = useState<boolean>(false)
  const [currentOpenIndex, setCurrentOpenIndex] = useState<number | null>(null)

  const { defaultCycle, availableCycles, canEditCycle, canAddCycle, isLoading } =
    useAvailableCycles({
      targetIndex: currentOpenIndex,
      allTargets: values.targets,
      isEmployeeTemplate: values.kpi_type?.id === KPITypes.employee_kpi,
    })

  const onAdd = () => {
    setIsFormOpen(true)
    setCurrentOpenIndex(null)
  }

  const onOpen = (index: number) => {
    setIsFormOpen(true)
    setCurrentOpenIndex(index)
  }

  const onSubmitTarget = (target: KpiTargets) => {
    const firstTarget = values.targets[0]

    if (currentOpenIndex !== null) {
      values.targets[currentOpenIndex] = target
    } else if (
      firstTarget &&
      target.review_cycle &&
      firstTarget.review_cycle &&
      target.review_cycle.offset > firstTarget.review_cycle.offset
    ) {
      values.targets.unshift(target)
    } else {
      values.targets.push(target)
    }
    setIsFormOpen(false)
    setCurrentOpenIndex(null)
  }

  const row = getRow(
    {
      allTargets: values.targets,
      onView: onOpen,
    },
    readonly,
    dynamicTargets,
  )

  return (
    <Widget p="s-16">
      <VStack space="s-16">
        {isLoading ? (
          <Skeleton width="100%" height="36px" />
        ) : (
          canAddCycle &&
          !readonly && (
            <Flex justifyContent="space-between">
              <Button
                useIcon="Plus"
                onClick={onAdd}
                data-testid="set_target_button"
                variant="bar"
              >
                Set metrics
              </Button>

              <HStack space="s-12" align="center" use="label">
                <HStack space="s-8" align="center">
                  <Text variant="h6" lineHeight="23px" color={Token.color.greyTone50}>
                    Make metrics dynamic
                  </Text>
                  <InfoIconWithTooltip
                    color={Token.color.greyTone50}
                    tooltipProps={{ width: '360px' }}
                    size={16}
                    content="Select dynamic metrics if you need to define variable initial and target values based on parameters (such as employee team, role, or seniority)."
                  />
                </HStack>
                <Switch
                  checked={values.dynamic_targets}
                  onClick={() => {
                    values.dynamic_targets = !values.dynamic_targets
                  }}
                />
              </HStack>
            </Flex>
          )
        )}
        <SummaryTableContainer>
          <AdjustableTable
            name={TableNames.TemplateTargets}
            idPath="uniqueKey"
            noDataMessage="No targets added"
            row={row}
            hideCountAndButtonSection
            count={values.targets.length}
            data={values.targets}
            onRowClick={data => {
              if (data.review_cycle) {
                const rowIndex = values.targets.findIndex(
                  elm => elm.review_cycle?.id === data.review_cycle!.id,
                )
                onOpen(rowIndex)
              } else if (data.employee_cycle) {
                const rowIndex = values.targets.findIndex(
                  elm => elm.employee_cycle?.id === data.employee_cycle!.id,
                )
                onOpen(rowIndex)
              }
            }}
            {...(readonly ? summaryTableProps : {})}
            hideCount
          />
        </SummaryTableContainer>
      </VStack>
      {!!defaultCycle && !readonly && (
        <TemplateTargetForm
          isOpen={isFormOpen}
          targetIndex={currentOpenIndex}
          onClose={() => {
            setIsFormOpen(false)
            setCurrentOpenIndex(null)
          }}
          defaultCycle={defaultCycle}
          availableCycles={availableCycles}
          canEditCycle={canEditCycle}
          onSubmit={onSubmitTarget}
          dynamicTargets={dynamicTargets}
        />
      )}
    </Widget>
  )
}

export default TemplateTargetsTable
