import React, { useEffect, useState } from 'react'
import {
  CompetencyPerformanceWeightType,
  RoleInterface,
  SpecialisationInterface,
} from '@src/interfaces/roles'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { connect } from 'lape'
import {
  Box,
  DetailsCell,
  Flex,
  Group,
  Subheader,
  Item,
  Avatar,
  ActionButton,
} from '@revolut/ui-kit'
import Loader from '@components/CommonSC/Loader'
import { ROUTES } from '@src/constants/routes'
import { RowInterface } from '@src/interfaces/data'
import { pathToUrl } from '@src/utils/router'
import { rolesRequests } from '@src/api/roles'
import { Statuses } from '@src/interfaces'
import { HiringProcessInterface } from '@src/interfaces/hiringProccess'
import {
  hiringProcessEligibleInterviewsLinkColumn,
  hiringProcessPlaybookColumn,
  hiringProcessScorecardLinkColumn,
  hiringProcessSkillsColumn,
  hiringProcessTitleWithLockColumn,
} from '@src/constants/columns/hiringProcess'
import { useParams } from 'react-router-dom'
import PreviewStageSidebar from '@src/pages/Forms/RoleForm/Preview/PreviewStageSidebar/index'
import RoleSaveDraftButton from '@src/pages/Forms/RoleForm/Buttons/RoleSaveDraftButton'
import SpecialisationSubmitButton from '@src/pages/Forms/SpecialisationForm/Buttons/SpecialisationSubmitButton'
import CompetencyMatrixTable from '@src/features/CompetencyMatrixTable/CompetencyMatrixTable'
import { navigateTo } from '@src/actions/RouterActions'
import ApprovalFlow from '@src/features/ApprovalFlow/ApprovalFlow'
import { parseLegacyApprovalSteps } from '@src/utils/approvalFlow'
import { PageBody } from '@components/Page/PageBody'
import { FormPreview } from '@components/FormPreview/FormPreview'
import PostingsViewer from '@src/pages/Forms/SpecialisationForm/Postings/PostingsViewer'
import { useGetOrganisationSettings, useGlobalSettings } from '@src/api/settings'
import { useGetSpecialisationPreferredHiringLocations } from '@src/api/specialisations'
import { PermissionTypes } from '@src/store/auth/types'
import HideIfCommercial from '@src/components/HideIfCommercial/HideIfCommercial'
import { HiringProcessWidget } from '@src/pages/Forms/SpecialisationForm/HiringProcess/HiringProcessWidget'
import { PageActions } from '@src/components/Page/PageActions'

export const HiringProcessRow: RowInterface<HiringProcessInterface> = {
  cells: [
    {
      ...hiringProcessTitleWithLockColumn,
      width: 188,
    },
    {
      ...hiringProcessSkillsColumn,
      width: 144,
    },
    {
      ...hiringProcessPlaybookColumn,
      width: 100,
    },
    {
      ...hiringProcessScorecardLinkColumn,
      width: 134,
    },
    {
      ...hiringProcessEligibleInterviewsLinkColumn,
      width: 154,
    },
  ],
}

const Preview = () => {
  const context = useLapeContext<SpecialisationInterface>()
  const { values } = context
  const params = useParams()
  const [role, setRole] = useState<RoleInterface>()

  const {
    settings: { job_postings_enabled, candidates_enabled, promotions_enabled },
  } = useGlobalSettings()

  const { data: settings } = useGetOrganisationSettings()
  const { data: preferredLocations } = useGetSpecialisationPreferredHiringLocations(
    values.id,
  )
  const approvalsEnabled = !!settings?.enable_specialisations_approvals

  const permissions = context.values.field_options.permissions || []
  const canEdit = permissions.includes(PermissionTypes.ChangeSpecialisation)

  useEffect(() => {
    fetchRole()
  }, [values.role])

  const fetchRole = async () => {
    if (values?.role?.id) {
      try {
        context.loading = true
        const result = await rolesRequests.getItem(values.role.id)
        if (result.data) {
          setRole(result.data)
          if (!values.seniority_max) {
            values.seniority_max = result.data.seniority_max
          }

          if (!values.seniority_min) {
            values.seniority_min = result.data.seniority_min
          }
        }
      } finally {
        context.loading = false
      }
    }
  }

  if (context.loading) {
    return (
      <Flex>
        <Loader />
      </Flex>
    )
  }

  const renderEditLInk = (url: string, label = 'Edit') => {
    return canEdit ? (
      <ActionButton
        onClick={() =>
          navigateTo(
            pathToUrl(url, {
              id: values.id,
            }),
          )
        }
      >
        {label}
      </ActionButton>
    ) : null
  }

  return (
    <>
      <PreviewStageSidebar isSpecialisation />
      <PageBody maxWidth={782}>
        {approvalsEnabled && values.status !== Statuses.draft && (
          <ApprovalFlow
            isLoading={!values.approval_flow_status}
            steps={parseLegacyApprovalSteps(values.approval_flow_status?.approval_steps)}
          />
        )}
        <FormPreview<SpecialisationInterface>
          data={values}
          title="About the specialisation"
          onEdit={
            canEdit
              ? () => navigateTo(pathToUrl(ROUTES.FORMS.SPECIALISATIONS.GENERAL, params))
              : undefined
          }
        >
          <Group>
            <FormPreview.Item title="Specialisation name" field="name" />
            <FormPreview.Item
              title="Specialisation owner"
              type="employee"
              field="owner"
            />
            <FormPreview.Item
              title="Parent Role"
              field="role.name"
              to={() =>
                pathToUrl(ROUTES.FORMS.ROLE.PREVIEW, {
                  id: values.role?.id,
                })
              }
            />
            <HideIfCommercial>
              {values.hiring_playbook_url && (
                <FormPreview.Item
                  title="Playbook"
                  field="hiring_playbook_url"
                  type="link"
                />
              )}
            </HideIfCommercial>
            <FormPreview.Item<SpecialisationInterface>
              title="Locations"
              field="locations"
              insert={data =>
                data?.locations?.length
                  ? data.locations?.map(loc => loc.name).join(', ')
                  : 'All'
              }
            />
            <FormPreview.Item<SpecialisationInterface>
              title="Preferred locations"
              insert={() => preferredLocations?.map(loc => loc.name).join(', ') || '-'}
            />
            <FormPreview.Details title="Specialisation mission" field="mission" />
            <FormPreview.Details
              title="Specialisation goals"
              field="goals"
              insert={() => (
                <ul style={{ paddingInlineStart: 16 }}>
                  {values.goals?.map((goal, id) => {
                    return <li key={id}>{goal.text}</li>
                  })}
                </ul>
              )}
            />
            {promotions_enabled && (
              <FormPreview.Item<SpecialisationInterface>
                title="Linked specialisations"
                field="related_specialisations"
                insert={data =>
                  data?.related_specialisations?.length
                    ? data.related_specialisations.map(spec => spec.name).join(', ')
                    : '-'
                }
              />
            )}
          </Group>
        </FormPreview>
        <Box mt="s-16">
          <Group>
            <Item>
              <Item.Avatar>
                <Avatar useIcon="RepairTool" />
              </Item.Avatar>
              <Item.Content>
                <Item.Title>Competency matrix</Item.Title>
                <Item.Description>
                  The skills to be assessed and their expected competency level
                </Item.Description>
              </Item.Content>
              <Item.Side>
                {renderEditLInk(ROUTES.FORMS.SPECIALISATIONS.COMPETENCY_MATRIX)}
              </Item.Side>
            </Item>

            <DetailsCell>
              <DetailsCell.Title>Allowed seniorities</DetailsCell.Title>
              <DetailsCell.Content whiteSpace="pre-line">
                {values.seniority_min?.name || ''} - {values.seniority_max?.name || ''}
              </DetailsCell.Content>
            </DetailsCell>

            <Box>
              <CompetencyMatrixTable
                competencyMatrices={[
                  {
                    sectionTitle: 'Deliverables',
                    children: [
                      {
                        skill: {
                          id: null,
                          name: 'Deliverables',
                        },
                        competencies: values.deliverables_competencies || [],
                        weight: values.performance_weights?.find(
                          w =>
                            w.weight_type ===
                            CompetencyPerformanceWeightType.Deliverables,
                        )?.weight,
                      },
                    ],
                    disabled: true,
                    hideActionsColumn: true,
                    disableWeights: true,
                  },
                  {
                    sectionTitle: 'Parent role skill',
                    children: role?.functional_competency_matrix?.map(item => ({
                      ...item,
                      weight: values.performance_weights?.find(
                        w =>
                          w.weight_type === CompetencyPerformanceWeightType.Skill &&
                          w.skill_id === item.skill?.id,
                      )?.weight,
                    })),
                    hideActionsColumn: true,
                    disableWeights: true,
                    disabled: true,
                  },
                  {
                    sectionTitle: 'Specialisation skill',
                    children: values.functional_competency_matrix?.map(item => ({
                      ...item,
                      weight: values.performance_weights?.find(
                        w =>
                          w.weight_type === CompetencyPerformanceWeightType.Skill &&
                          w.skill_id === item.skill?.id,
                      )?.weight,
                    })),
                    disabled: true,
                    hideActionsColumn: true,
                    disableWeights: true,
                  },
                ]}
                minSeniority={values.seniority_min}
                maxSeniority={values.seniority_max}
                firstRowTitle="Skills"
                isAdjustable
                isV2Table
                withWeightColumn={!!values.performance_weights}
              />
            </Box>
          </Group>
        </Box>
        {candidates_enabled && (
          <Box mt="s-16">
            <HiringProcessWidget />
          </Box>
        )}
        {job_postings_enabled && (
          <>
            <Subheader variant="nested">
              <Subheader.Title>Job posting</Subheader.Title>
              {renderEditLInk(ROUTES.FORMS.SPECIALISATIONS.POSTINGS, 'View details')}
            </Subheader>
            <PostingsViewer noButtons noEditing noTable />
          </>
        )}
      </PageBody>
      <PageActions>
        {(!values?.id || values.status === Statuses.draft) && (
          <>
            <RoleSaveDraftButton
              title="specialisation"
              pathInLocalStorage={pathToUrl(ROUTES.FORMS.SPECIALISATIONS.GENERAL)}
              pathAfterSave={ROUTES.FORMS.SPECIALISATIONS.PREVIEW}
              isNew
              notification={{
                path: ROUTES.FORMS.SPECIALISATIONS.GENERAL,
                updateMsg: 'Specialisation draft successfully updated.',
                createMsg: 'Specialisation draft successfully created.',
              }}
            />
            <SpecialisationSubmitButton
              isNew
              notification={{
                path: ROUTES.FORMS.SPECIALISATIONS.GENERAL,
                updateMsg: 'Specialisation successfully updated.',
                createMsg: 'Specialisation successfully created.',
              }}
              dialog={{
                title: 'Why do we need this specialisation?',
                placeholder: 'a couple of words about the reason',
                fieldName: 'description',
              }}
            />
          </>
        )}
      </PageActions>
    </>
  )
}

export default connect(Preview)
