import { isEmpty } from 'lodash'
import { useQueryClient } from 'react-query'
import { GetRequestInterface, RequestInterfaceNew, Statuses } from '@src/interfaces'
import {
  GrowthPlanCommentInterface,
  GrowthPlanDecision,
  GrowthPlanInterface,
  GrowthPlanPerformanceSummaryInterface,
  GrowthPlanRelationInterface,
  GrowthPlanRelationStatsInterface,
} from '@src/interfaces/growthPlans'
import { API } from '@src/constants/api'
import { FetchDataQueryInterface } from '@src/interfaces/data'
import { api, apiWithoutHandling } from '@src/api/index'
import { filterSortPageIntoQuery } from '@src/utils/table'
import { useDelete, useFetch, usePost, useUpdate } from '@src/utils/reactQuery'
import { getCommentsAPI } from '@src/api/comments'
import { ApprovalFlowResponse } from '@src/interfaces/approvalFlow'
import { ReviewCycleCategory } from '@src/interfaces/reviewCycles'

export const getGrowthPlansRelations = ({
  sortBy,
  filters,
  page,
}: FetchDataQueryInterface) =>
  api.get<GetRequestInterface<GrowthPlanRelationInterface>>(API.GROWTH_PLANS_RELATIONS, {
    params: filterSortPageIntoQuery(sortBy, filters, page),
  })

export const getGrowthPlansRelationsStats = ({
  sortBy,
  filters,
  page,
}: FetchDataQueryInterface) =>
  api.get<GrowthPlanRelationStatsInterface>(`${API.GROWTH_PLANS_RELATIONS}/stats`, {
    params: filterSortPageIntoQuery(sortBy, filters, page),
  })

// can be used for both filtering and sorting
export enum GrowthPlansRelationsQueryKeys {
  LatestReviewScoreLabel = 'latest_review_score_label',
  TargetSpecialisationId = 'target_specialisation__id',
  TargetSeniorityId = 'target_seniority__id',
  Status = 'status',
}

export enum GrowthPlansRelationsQueryFilterKeys {
  Id = 'id',
  LineManagerId = 'line_manager__id',
  QualityControlId = 'quality_control__id',
  OwnerId = 'owner__id',
}

export enum GrowthPlansRelationsQuerySortKeys {
  FullName = 'full_name',
  LineManagerName = 'line_manager__full_name',
  QualityControlName = 'quality_control__full_name',
  OwnerName = 'owner__full_name',
}

export const growthPlanFormRequests: RequestInterfaceNew<GrowthPlanInterface> = {
  delete: async ({ id }) => apiWithoutHandling.delete(`${API.GROWTH_PLANS}/${id}`),
  get: async ({ id }) => api.get(`${API.GROWTH_PLANS}/${id}`),
  update: async (data, { id }) =>
    apiWithoutHandling.patch(`${API.GROWTH_PLANS}/${id}`, data),
  submit: async data => apiWithoutHandling.post(API.GROWTH_PLANS, data),
}

export const useGetGrowthPlanRelation = (employeeId: number, enabled = false) =>
  useFetch<GrowthPlanRelationInterface>({
    url: `${API.GROWTH_PLANS_RELATIONS}/${employeeId}`,
    withoutHandling: true,
    queryOptions: {
      enabled,
    },
  })

export enum GrowthPlansQueryKeys {
  EmployeeId = 'employee__id',
  Status = 'status',
  Decision = 'decision',
  TargetSpecialisationId = 'target_specialisation__id',
  TargetSeniorityId = 'target_seniority__id',
  MissingNomination = 'promotion_nomination__isnull',
}

export interface GrowthPlansParams {
  [GrowthPlansQueryKeys.EmployeeId]?: number
  [GrowthPlansQueryKeys.Status]?: Statuses[]
  [GrowthPlansQueryKeys.Decision]?: GrowthPlanDecision
  [GrowthPlansQueryKeys.TargetSpecialisationId]?: number
  [GrowthPlansQueryKeys.TargetSeniorityId]?: number
  [GrowthPlansQueryKeys.MissingNomination]?: boolean
}

export const useGetGrowthPlans = (params: GrowthPlansParams, enabled = false) =>
  useFetch<GetRequestInterface<GrowthPlanInterface>>({
    url: API.GROWTH_PLANS,
    withoutHandling: true,
    params: {
      params: {
        ...params,
        ...(params.status ? { status: params.status.join(',') } : {}),
      },
    },
    queryOptions: { enabled },
  })

export const useGetGrowthPlansForNomination = (
  params: Pick<
    GrowthPlansParams,
    GrowthPlansQueryKeys.TargetSeniorityId | GrowthPlansQueryKeys.TargetSpecialisationId
  >,
  enabled?: boolean,
) =>
  useGetGrowthPlans(
    {
      ...params,
      [GrowthPlansQueryKeys.Decision]: GrowthPlanDecision.Completed,
      [GrowthPlansQueryKeys.MissingNomination]: true,
      [GrowthPlansQueryKeys.Status]: [Statuses.approved],
    },
    !!enabled &&
      params[GrowthPlansQueryKeys.TargetSpecialisationId] !== undefined &&
      params[GrowthPlansQueryKeys.TargetSeniorityId] !== undefined,
  )

export const useGetGrowthPlan = (id?: number, enabled = false) =>
  useFetch<GrowthPlanInterface>({
    url: `${API.GROWTH_PLANS}/${id}`,
    withoutHandling: true,
    queryOptions: {
      enabled: enabled && Number.isInteger(id),
    },
  })

export const useUpdateGrowthPlan = () =>
  useUpdate<Partial<GrowthPlanInterface>, GrowthPlanInterface>({
    url: API.GROWTH_PLANS,
    usePut: false,
    withoutHandling: true,
  })

export const useMarkGrowthPlanAction = (growthPlanId: number) =>
  useUpdate<{ id: number; is_completed: boolean }, { is_completed: boolean }>({
    url: `${API.GROWTH_PLANS}/${growthPlanId}/actions`,
    usePut: false,
    withoutHandling: true,
  })

export const useAddGrowthPlanComment = (growthPlanId: number) =>
  usePost<undefined, GrowthPlanCommentInterface, { body: string }>({
    url: `${API.GROWTH_PLANS}/${growthPlanId}/comments`,
    withoutHandling: true,
  })

export const getGrowthPlanCommentsAPI = (id: number) =>
  getCommentsAPI({ baseUrl: `${API.GROWTH_PLANS}/${id}/comments` })

export const useCompleteGrowthPlan = (growthPlanId: number) => {
  const queryClient = useQueryClient()

  return usePost<undefined, GrowthPlanInterface, void>({
    url: `${API.GROWTH_PLANS}/${growthPlanId}/complete`,
    withoutHandling: true,
    handleSuccess: ({ data }) => {
      queryClient.setQueryData<GrowthPlanInterface | undefined>(
        [`${API.GROWTH_PLANS}/${growthPlanId}`, 'v1', null],
        oldData => {
          if (!oldData) {
            return undefined
          }

          return { ...oldData, status: data.status, decision: data.decision }
        },
      )
    },
  })
}

export const useMissGrowthPlan = (growthPlanId: number) => {
  const queryClient = useQueryClient()

  return usePost<undefined, GrowthPlanInterface, void>({
    url: `${API.GROWTH_PLANS}/${growthPlanId}/miss`,
    withoutHandling: true,
    handleSuccess: ({ data }) => {
      queryClient.setQueryData<GrowthPlanInterface | undefined>(
        [`${API.GROWTH_PLANS}/${growthPlanId}`, 'v1', null],
        oldData => {
          if (!oldData) {
            return undefined
          }

          return { ...oldData, status: data.status, decision: data.decision }
        },
      )
    },
  })
}

export const useApproveGrowthPlan = (growthPlanId: number) => {
  const queryClient = useQueryClient()

  return usePost<undefined, GrowthPlanInterface, void>({
    url: `${API.GROWTH_PLANS}/${growthPlanId}/approve`,
    withoutHandling: true,
    handleSuccess: ({ data }) => {
      queryClient.setQueryData<GrowthPlanInterface | undefined>(
        [`${API.GROWTH_PLANS}/${growthPlanId}`, 'v1', null],
        oldData => {
          if (!oldData) {
            return undefined
          }

          return { ...oldData, status: data.status, decision: data.decision }
        },
      )
    },
  })
}

export const useRejectGrowthPlan = (growthPlanId: number) => {
  const queryClient = useQueryClient()

  return usePost<undefined, GrowthPlanInterface, { rejection_comment: string }>({
    url: `${API.GROWTH_PLANS}/${growthPlanId}/reject`,
    withoutHandling: true,
    handleSuccess: ({ data }) => {
      queryClient.setQueryData<GrowthPlanInterface | undefined>(
        [`${API.GROWTH_PLANS}/${growthPlanId}`, 'v1', null],
        oldData => {
          if (!oldData) {
            return undefined
          }

          return { ...oldData, status: data.status }
        },
      )
    },
  })
}

export const useArchiveGrowthPlan = (growthPlanId: number) => {
  const queryClient = useQueryClient()

  return usePost<undefined, GrowthPlanInterface, void>({
    url: `${API.GROWTH_PLANS}/${growthPlanId}/archive`,
    withoutHandling: true,
    handleSuccess: ({ data }) => {
      queryClient.setQueryData<GrowthPlanInterface | undefined>(
        [`${API.GROWTH_PLANS}/${growthPlanId}`, 'v1', null],
        oldData => {
          if (!oldData) {
            return undefined
          }

          return { ...oldData, status: data.status }
        },
      )
    },
  })
}

export const useRemindToNominateByGrowthPlan = (growthPlanId: number) => {
  const queryClient = useQueryClient()

  return usePost<undefined, GrowthPlanInterface, void>({
    url: `${API.GROWTH_PLANS}/${growthPlanId}/remindToNominate`,
    withoutHandling: true,
    handleSuccess: ({ data }) => {
      queryClient.setQueryData<GrowthPlanInterface | undefined>(
        [`${API.GROWTH_PLANS}/${growthPlanId}`, 'v1', null],
        oldData => {
          if (!oldData) {
            return undefined
          }

          return { ...oldData, remind_to_nominate: data.remind_to_nominate }
        },
      )
    },
  })
}

export const useDeleteGrowthPlan = () => useDelete(API.GROWTH_PLANS)

export const useGetGrowthPlanApprovals = (id?: number) => {
  return useFetch<ApprovalFlowResponse>({
    url: `${API.GROWTH_PLANS}/${id}/approvals`,
    withoutHandling: true,
    queryOptions: { enabled: Number.isInteger(id) },
  })
}

interface GrowthPlanSummaryParams {
  employee_id: number
  target_specialisation_id: number
  target_seniority_id: number
  cycle_id?: number | string
  cycle_category?: ReviewCycleCategory
}

export const useGetPerformanceSummaryForGrowthPlan = (
  params: GrowthPlanSummaryParams,
  enabled = false,
) =>
  useFetch<GrowthPlanPerformanceSummaryInterface | null>({
    url: `${API.GROWTH_PLANS}/performanceSummary`,
    params: { params },
    queryOptions: {
      select: data => (isEmpty(data) ? null : data),
      enabled,
      refetchOnWindowFocus: false,
    },
  })
