import React from 'react'
import get from 'lodash/get'
import isNumber from 'lodash/isNumber'
import { Box, Circle, Flex, HStack, Icon, Tag, Text, Token } from '@revolut/ui-kit'

import { DistributionChartV2 } from '@src/apps/People/Engagement/Results/components/DistributionChart'
import Tooltip from '@src/components/Tooltip/Tooltip'
import { CellTypes, ColumnInterface, FilterType } from '@src/interfaces/data'
import {
  EngagementAnswerInterface,
  EngagementResultV2Interface,
} from '@src/interfaces/engagement'
import { selectorKeys } from '../api'

const HideDataIcon = (
  <Flex width="100%" justifyContent="flex-start">
    <Tooltip
      placement="top"
      body={
        <Box padding="s-8" width={210}>
          <Text color={Token.color.background}>
            This data is hidden to protect the anonymity of the respondents
          </Text>
        </Box>
      }
    >
      <Icon name="EyeHide" size={14} color={Token.color.greyTone50} />
    </Tooltip>
  </Flex>
)

export const engagementResultsV2QuestionNameColumn: ColumnInterface<EngagementResultV2Interface> =
  {
    type: CellTypes.text,
    idPoint: 'id',
    dataPoint: 'name',
    sortKey: 'name',
    filterKey: 'id',
    selectorsKey: selectorKeys.engagement_question_texts,
    title: 'Question',
  }

export const engagementResultsV2QuestionCategoryNameColumn: ColumnInterface<EngagementResultV2Interface> =
  {
    type: CellTypes.text,
    idPoint: 'driver',
    dataPoint: 'driver',
    sortKey: 'driver',
    filterKey: 'driver',
    selectorsKey: selectorKeys.engagement_drivers,
    title: 'Category',
  }

export const engagementResultsV2QuestionTypeColumn: ColumnInterface<EngagementResultV2Interface> =
  {
    type: CellTypes.insert,
    idPoint: 'type',
    dataPoint: 'type',
    sortKey: 'question__type',
    filterKey: 'question__type',
    selectorsKey: selectorKeys.engagement_question_types_v2,
    title: 'Question type',
    insert: ({ data }) => {
      if (typeof data.type === 'object') {
        // TODO: RHR-6307 Remove when BE fixes data format
        return get(data.type, 'name')
      }
      return data.type
    },
  }

export const engagementResultsV2AverageScoreColumn: ColumnInterface<EngagementResultV2Interface> =
  {
    type: CellTypes.insert,
    idPoint: 'average_score',
    dataPoint: 'average_score',
    sortKey: 'average_score',
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Avg score',
    insert: ({ data }) => {
      const notAvailable = <Text color={Token.color.greyTone50}>n/a</Text>

      if (data.type === 'open_ended' || data.average_score === null) {
        return notAvailable
      }
      if (data.can_show_results === false) {
        return HideDataIcon
      }
      if (typeof data.average_score !== 'number') {
        return notAvailable
      }
      // actually, API field name is wrong: trend_average_score is a score value from the previous run
      const trendDiff =
        typeof data.trend_average_score === 'number'
          ? data.average_score - data.trend_average_score
          : 0
      const tredDiffFormatted = trendDiff.toFixed(2)

      return (
        <HStack align="center" space="s-8">
          <Text>{Number(data.average_score.toFixed(2))}</Text>
          <HStack align="center">
            {trendDiff > 0 && (
              <Icon size={14} name="ArrowUp" color={Token.color.success} />
            )}
            {trendDiff < 0 && (
              <Icon size={14} name="ArrowDown" color={Token.color.danger} />
            )}
            {!!trendDiff && (
              <Text color={Token.color.greyTone50}>
                {trendDiff > 0 ? `+${tredDiffFormatted}` : tredDiffFormatted}
              </Text>
            )}
          </HStack>
        </HStack>
      )
    },
    headerTooltip: (
      <Box color={Token.color.background} p="s-8" minWidth={250}>
        The average score is normalized average of all answers for each particular
        question or driver, it ranges from 1 to 10
      </Box>
    ),
  }

export const engagementResultsV2CategoryNameColumn: ColumnInterface<EngagementResultV2Interface> =
  {
    type: CellTypes.insert,
    idPoint: 'id',
    dataPoint: 'name',
    sortKey: 'name',
    filterKey: 'id',
    selectorsKey: selectorKeys.engagement_drivers,
    title: 'Category',
    insert: ({ data }) => (
      <Box py="s-12">
        <Text whiteSpace="pre-wrap">{data.name || '-'}</Text>
      </Box>
    ),
    notHoverable: true,
  }

export const engagementResultsV2QuestionTextColumn: ColumnInterface<EngagementAnswerInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'question.id',
    dataPoint: 'question.question_text',
    sortKey: 'question__title',
    filterKey: 'question__id',
    selectorsKey: selectorKeys.engagement_question_texts,
    title: 'Question',
    insert: ({ data }) => (
      <Box py="s-12">
        <Text whiteSpace="pre-wrap">{data.question?.question_text || '-'}</Text>
      </Box>
    ),
    notHoverable: true,
  }

export const isEmptyAnswerTextColName = 'answer_text__isnull'
export const engagementResultsV2AnswerTextColumn: ColumnInterface<EngagementAnswerInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'id',
    dataPoint: 'answer_text',
    sortKey: null,
    filterKey: isEmptyAnswerTextColName,
    filterType: FilterType.boolean,
    selectorsKey: selectorKeys.yes_no_inverted_value_options,
    title: 'Answer text',
    notHoverable: true,
    insert: ({ data }) => (
      <HStack align="start" space="s-8" py="s-12">
        {!!data.answer_text && (
          <Tooltip
            placement="top"
            text={data.acknowledged ? 'Acknowledged' : 'Not acknowledged'}
          >
            <Box pt="s-4">
              <Icon
                size={14}
                name={data.acknowledged ? 'CheckSuccess' : '16/Time'}
                color={data.acknowledged ? Token.color.green_80 : Token.color.orange_80}
              />
            </Box>
          </Tooltip>
        )}
        <Text
          use="div"
          whiteSpace="pre-wrap"
          color={data.answer_text ? Token.color.foreground : Token.color.greyTone50}
        >
          {data.answer_text || 'n/a'}
        </Text>
      </HStack>
    ),
  }

export const engagementResultsV2CategoryColumn: ColumnInterface<EngagementAnswerInterface> =
  {
    type: CellTypes.text,
    idPoint: 'question.driver.id',
    dataPoint: 'question.driver.name',
    sortKey: 'driver__name',
    filterKey: 'driver__id',
    selectorsKey: selectorKeys.engagement_drivers,
    title: 'Category',
  }

export const engagementResultsV2AnswerScoreColumn: ColumnInterface<EngagementAnswerInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'answer_score',
    dataPoint: 'answer_score',
    sortKey: 'answer_score',
    filterKey: 'answer_score',
    selectorsKey: selectorKeys.engagement_answer_scores,
    title: 'Answer score',
    insert: ({ data }) => {
      if (isNumber(data.answer_score)) {
        return (
          <Circle size={18} variant="filled">
            <Text fontSize="x-small" fontWeight={500} color={Token.color.background}>
              {data.answer_score}
            </Text>
          </Circle>
        )
      }
      if (data.categories?.names?.length) {
        return (
          <HStack space="s-4">
            {data.categories.names.map(name => (
              <Tag key={name} variant="outlined">
                {name}
              </Tag>
            ))}
          </HStack>
        )
      }
      return '-'
    },
  }

export const engagementResultsV2AnsweredOnColumn: ColumnInterface<EngagementAnswerInterface> =
  {
    type: CellTypes.date,
    idPoint: 'creation_date_time',
    dataPoint: 'creation_date_time',
    sortKey: 'creation_date_time',
    filterKey: null,
    selectorsKey: selectorKeys.none,
    filterType: FilterType.date,
    title: 'Answered on',
  }

export const engagementResultsV2DistributionColumn: ColumnInterface<EngagementResultV2Interface> =
  {
    type: CellTypes.insert,
    idPoint: 'id',
    dataPoint: 'id',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    insert: ({ data }) => {
      return <DistributionChartV2 data={data} />
    },
    title: 'Distribution',
  }

export const engagementResultsV2CommentsNumColumn: ColumnInterface<EngagementResultV2Interface> =
  {
    type: CellTypes.insert,
    idPoint: 'number_of_comments',
    dataPoint: 'number_of_comments',
    sortKey: 'number_of_comments',
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Comments',
    insert: ({ data }) => {
      if (data.number_of_comments == null) {
        return <Text color={Token.color.greyTone50}>n/a</Text>
      }
      return (
        <HStack align="center" space="s-8">
          <Icon name="Chat" size={14} color={Token.color.greyTone50} />
          <Text>{data.number_of_comments}</Text>
        </HStack>
      )
    },
  }
