import React, { useCallback, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { PageWrapper } from '@src/components/Page/Page'
import { PageBody } from '@src/components/Page/PageBody'
import { TwoColumnsLayout } from '../../../common/TwoColumnsLayout'
import { MeetingsList } from './MeetingsList'
import { useParams } from 'react-router-dom'
import {
  useCreateOneToOneNote,
  useGetEmployeeMeeting,
  useGetMeetingNotesFeedback,
  useGetMeetingsSummary,
  useUpdateOneToOneNote,
} from '@src/api/meetings'
import { FilterByInterface } from '@src/interfaces/data'
import {
  PublicContentItem,
  GoogleCalendarEventAttendee,
  MeetingEvent,
  MeetingEventStatus,
  MeetingNotesOrigin,
  PrivateContentItem,
  privateRoles,
  AiGenerationStatus,
} from '@src/interfaces/meetings'
import { SummaryHeader } from './components/SummaryHeader'
import { useReviewCycleData } from './hooks/useReviewCycleData'
import { CycleFilterButton } from '@src/components/Inputs/Filters/FilterSelect/CycleFilter/CycleFilterButton'
import {
  ActionButton,
  Avatar,
  BarSkeleton,
  Cell,
  Ellipsis,
  Grid,
  HStack,
  Skeleton,
  Spacer,
  Text,
  Token,
  useToggle,
  VStack,
  Widget,
} from '@revolut/ui-kit'
import { NotesHeader } from './components/NotesHeader'
import { EmptyTableRaw as EmptyState } from '@src/components/Table/EmptyTableRaw'
import { useOpenNewTab } from '@src/actions/RouterActions'
import { SummarySection } from './components/SummarySection'
import { ActionPoints } from './components/ActionPoints'
import {
  getContentItemsByRole,
  getPrivateContentItemsByRole,
} from './utils/getContentItemsByRole'
import { Attachments } from './components/Attachments'
import { TalkingPointsSection } from './components/TalkingPointsSection'
import { PersonalNotesSection } from './components/PersonalNotesSection'
import { FinalGrade } from '@src/interfaces/performance'
import { PendingAIGeneration } from './components/PendingAIGeneration'
import { isAfter } from 'date-fns'
import { generateRandomId } from '@src/utils/numbers'
import { useQuery } from '@src/utils/queryParamsHooks'
import { oneToOneModel } from '../models/oneToOneModel'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'
import { PageNotFoundWidget } from '@src/features/Errors/components/Page/PageNotFoundWidget'
import { MeetingsSummary } from '@src/pages/EmployeeProfile/Layout/Meetings/OneToOne/Summary/MeetingsSummary'
import { useGetSelectors } from '@src/api/selectors'
import { GradeOption } from '@src/interfaces/selectors'
import { selectorKeys } from '@src/constants/api'

export const OneToOneSummary = () => {
  const [selectedMeeting, setSelectedMeeting] = useState<
    MeetingEvent<GoogleCalendarEventAttendee> | undefined
  >()
  const [isMeetingsSummarySelected, meetingsSummaryToggler] = useToggle()
  const [saveHash] = useState(generateRandomId().toString())
  const currentUser = useSelector(selectUser)

  const [meetingList, setMeetingList] = useState<
    MeetingEvent<GoogleCalendarEventAttendee>[]
  >([])
  const [updatingSection, setUpdatingSection] = useState<
    | PublicContentItem['role']
    | PrivateContentItem['role']
    | 'summary'
    | 'personal_note'
    | undefined
  >()

  const params = useParams<{ employeeId: string; meetingId: string; managerId: string }>()
  const { query, changeQueryParam, deleteQueryParam } =
    useQuery<{ cycle_id?: string; noteId?: string }>()
  const openNewTab = useOpenNewTab()

  const employeeId = Number(params.employeeId)
  const meetingId = Number(params.meetingId)
  const managerId = Number(params.managerId)

  const {
    isLoading: isReviewCyclesLoading,
    dateRanges,
    selectedCycle,
    reviewCycleOptions,
  } = useReviewCycleData({ activeCycleId: query.cycle_id, employeeId, managerId })

  const {
    data: meetingNotesDetails,
    isLoading: isLoadingMeetingNotesDetails,
    refetch: refetchMeetingNotesFeedback,
  } = useGetMeetingNotesFeedback(selectedMeeting?.id)

  const { data: gradesOptions = [] } = useGetSelectors<GradeOption>(selectorKeys.grades)

  const { data, isLoading: isLoadingEmployeeManagerMeeting } = useGetEmployeeMeeting(
    employeeId,
    meetingId,
  )

  const canViewMeetingsSummary =
    !isLoadingEmployeeManagerMeeting &&
    data &&
    oneToOneModel.isMeetingManagedBy(currentUser, data)
  const { data: meetingsSummaries, isLoading: isMeetingsSummariesLoading } =
    useGetMeetingsSummary(employeeId, query.cycle_id, canViewMeetingsSummary)
  const meetingsSummary = meetingsSummaries?.results[0]

  const { mutateAsync: createNotes, isLoading: isLoadingCreateNotes } =
    useCreateOneToOneNote(selectedMeeting?.id)

  const { mutateAsync: updateNotes, isLoading: isLoadingUpdatingNotes } =
    useUpdateOneToOneNote()

  const isNotesDetails =
    selectedMeeting && meetingNotesDetails?.id && !isLoadingMeetingNotesDetails

  useEffect(() => {
    if (!isLoadingUpdatingNotes) {
      setUpdatingSection(undefined)
    }
  }, [isLoadingUpdatingNotes])

  useEffect(() => {
    if (isMeetingsSummarySelected && selectedMeeting !== undefined) {
      meetingsSummaryToggler.off()
    }
  }, [isMeetingsSummarySelected, selectedMeeting])

  useEffect(() => {
    if (!isMeetingsSummariesLoading && !!meetingsSummary) {
      meetingsSummaryToggler.on()
    }
  }, [isMeetingsSummariesLoading, meetingsSummary])

  const isLoadingUpdatingSummaryNotes =
    isLoadingUpdatingNotes && updatingSection === 'summary'
  const isLoadingUpdatingPersonalNotes =
    isLoadingUpdatingNotes && updatingSection === 'personal_note'
  const isLoadingUpdatingTalkingPoints =
    isLoadingUpdatingNotes && updatingSection === 'talking_points'
  const isLoadingPerformanceRating =
    isLoadingUpdatingNotes && updatingSection === 'performance_rating'
  const isLoadingActionItems =
    isLoadingUpdatingNotes && updatingSection === 'action_items'

  const handleCreateNotes = async () => {
    const payload = {
      manager: { id: managerId },
      report: { id: employeeId },
      origin: MeetingNotesOrigin.Platform,
    }
    await createNotes(payload)
    refetchMeetingNotesFeedback()
  }

  const onChangeSchedule = () => {
    if (data?.next_meeting_calendar_link) {
      openNewTab(data.next_meeting_calendar_link)
    }
  }

  const onCycleChange = (cycle: FilterByInterface) => {
    if (cycle.filters.at(0)?.id) {
      changeQueryParam('cycle_id', String(cycle.filters.at(0)?.id))
    }
    deleteQueryParam('noteId')
    setSelectedMeeting(undefined)
  }

  const onMeetingChange = useCallback(
    (event: MeetingEvent<GoogleCalendarEventAttendee> | undefined) => {
      if (event === undefined) {
        deleteQueryParam('noteId')
      } else if (!query.noteId || String(event.id) !== query.noteId) {
        changeQueryParam('noteId', String(event.id))
      }

      setSelectedMeeting(event)
    },
    [query.noteId],
  )

  const onSummaryChange = async (summary: string) => {
    if (meetingNotesDetails && selectedMeeting) {
      setUpdatingSection('summary')
      await updateNotes([
        { noteId: meetingNotesDetails.id, meetingId: selectedMeeting.id },
        { summary, origin: MeetingNotesOrigin.Platform, save_hash: saveHash },
      ])
    }
  }

  const personalNotes = meetingNotesDetails
    ? meetingNotesDetails[
        Object.hasOwn(meetingNotesDetails, 'manager_personal_notes')
          ? 'manager_personal_notes'
          : 'report_personal_notes'
      ]
    : ''

  const onPersonalNotesChange = async (notes: string) => {
    if (meetingNotesDetails && selectedMeeting) {
      setUpdatingSection('personal_note')
      const updateFieldName = Object.hasOwn(meetingNotesDetails, 'manager_personal_notes')
        ? 'manager_personal_notes'
        : 'report_personal_notes'

      await updateNotes([
        { noteId: meetingNotesDetails.id, meetingId: selectedMeeting.id },
        {
          [updateFieldName]: notes,
          origin: MeetingNotesOrigin.Platform,
          save_hash: saveHash,
        },
      ])
    }
  }

  const onTalkingPointsChange = async (talkingPoints: string) => {
    onContentChange({ newValue: { content: talkingPoints }, role: 'talking_points' })
  }

  const isEnabledAI = Boolean(
    meetingNotesDetails?.enable_ai &&
      (meetingNotesDetails?.summary_status === AiGenerationStatus.COMPLETED ||
        meetingNotesDetails?.has_transcript),
  )

  const onPerformanceRatingChange = (
    value: {
      name: string
      value: FinalGrade
    } | null,
  ) => {
    const performanceRating = getPrivateContentItemsByRole(
      meetingNotesDetails,
      'performance_rating',
    )?.content

    if (performanceRating) {
      onContentChange({
        newValue: { ...performanceRating, value },
        role: 'performance_rating',
      })
    }
  }

  const onContentChange = async ({
    newValue,
    role,
  }:
    | {
        newValue: PublicContentItem['content']
        role: PublicContentItem['role']
      }
    | {
        newValue: PrivateContentItem['content']
        role: PrivateContentItem['role']
      }) => {
    if (meetingNotesDetails && selectedMeeting) {
      setUpdatingSection(role)
      const isPrivate = privateRoles.includes(role as PrivateContentItem['role']) // Type assertion to satisfy TypeScript)
      const newContent = (
        isPrivate ? meetingNotesDetails?.private_content : meetingNotesDetails?.content
      )?.map(item => {
        if (item.role === role) {
          item.content = newValue
        }
        return item
      })
      await updateNotes([
        { noteId: meetingNotesDetails.id, meetingId: selectedMeeting.id },
        {
          [isPrivate ? 'private_content' : 'content']: newContent,
          origin: MeetingNotesOrigin.Platform,
          save_hash: saveHash,
        },
      ])
    }
  }

  const isNotesEmptyState =
    selectedMeeting && !meetingNotesDetails?.id && !isLoadingMeetingNotesDetails

  const actionItems = getContentItemsByRole(meetingNotesDetails, 'action_items')
  const talkingPoints =
    getContentItemsByRole(meetingNotesDetails, 'talking_points')?.content.content || ''

  const showCompletedMeetingSection =
    (selectedMeeting?.start && isAfter(new Date(), new Date(selectedMeeting.start))) ||
    selectedMeeting?.status === MeetingEventStatus.Completed ||
    meetingNotesDetails?.has_transcript ||
    meetingNotesDetails?.summary_status !== null ||
    meetingNotesDetails.action_items_status !== null

  const isMeetingListAvailable = Boolean(meetingList.length)

  const isSelectedCompletedMeetingLast =
    meetingList.filter(meeting => meeting.status === MeetingEventStatus.Completed)[0]
      ?.id === selectedMeeting?.id

  const isPreviousCycle = selectedCycle ? selectedCycle?.offset > 0 : false

  const hasEditDisabled =
    selectedMeeting?.status === MeetingEventStatus.Completed
      ? !isSelectedCompletedMeetingLast || isPreviousCycle
      : false

  if (
    !isLoadingEmployeeManagerMeeting &&
    data &&
    !oneToOneModel.isMeetingParticipant(currentUser, data)
  ) {
    return <PageNotFoundWidget />
  }

  return (
    <PageWrapper>
      <SummaryHeader
        data={data}
        isLoading={isLoadingEmployeeManagerMeeting}
        onChangeSchedule={onChangeSchedule}
      />

      <CycleFilterButton
        isLoading={isReviewCyclesLoading}
        columnName="cycle_id"
        filter={
          selectedCycle
            ? [
                {
                  columnName: 'cycle_id',
                  filters: [{ id: selectedCycle.id, name: selectedCycle?.name }],
                },
              ]
            : []
        }
        onFilterChange={onCycleChange}
        selector={() => Promise.resolve(reviewCycleOptions)}
      />

      <PageBody mt="s-4" maxWidthMd={Token.breakpoint.max}>
        <TwoColumnsLayout
          leftFlex={0}
          leftMinWidth={isMeetingListAvailable || !!meetingsSummary ? 270 : '100%'}
          left={
            <VStack space="s-2" data-testid="meetings_navigation">
              {meetingsSummary && (
                <Cell
                  use="button"
                  onClick={() => {
                    meetingsSummaryToggler.on()
                    setSelectedMeeting(undefined)
                    deleteQueryParam('noteId')
                  }}
                  aria-pressed={isMeetingsSummarySelected}
                >
                  <HStack space="s-16" align="center">
                    <Avatar useIcon="Document">
                      {isMeetingsSummarySelected && <Avatar.Badge useIcon="Check" />}
                    </Avatar>
                    <Text variant="emphasis1" width="100%" use="p">
                      <FormattedMessage
                        id="common.summary.title"
                        defaultMessage="Summary"
                      />
                    </Text>
                    <Spacer />
                    <Text
                      variant="body1"
                      color={Token.color.greyTone50}
                      width="100%"
                      use="p"
                    >
                      <Ellipsis>{meetingsSummary.cycle.name}</Ellipsis>
                    </Text>
                  </HStack>
                </Cell>
              )}
              {dateRanges.startDate && dateRanges.endDate && (
                <MeetingsList
                  setMeetingList={setMeetingList}
                  startDate={dateRanges.startDate}
                  endDate={dateRanges.endDate}
                  isLoading={
                    isReviewCyclesLoading ||
                    isLoadingEmployeeManagerMeeting ||
                    isMeetingsSummariesLoading
                  }
                  employeeId={employeeId}
                  managerId={managerId}
                  selectedMeeting={selectedMeeting}
                  setSelectedMeeting={onMeetingChange}
                  hideEmpty={!!meetingsSummary}
                  preselect={
                    !canViewMeetingsSummary ||
                    (!isMeetingsSummariesLoading && !meetingsSummary)
                  }
                />
              )}
            </VStack>
          }
          right={
            <>
              {isMeetingsSummarySelected && meetingsSummary && (
                <MeetingsSummary
                  summary={meetingsSummary}
                  gradesOptions={gradesOptions}
                />
              )}
              {!isMeetingsSummarySelected && (
                <>
                  <Widget data-testid="meeting_details">
                    {selectedMeeting && isLoadingMeetingNotesDetails && (
                      <VStack p="s-16" gap="s-16">
                        <BarSkeleton />
                        <Skeleton height={300} radius="widget" />
                        <Skeleton height={300} radius="widget" />
                      </VStack>
                    )}
                    {isNotesEmptyState && (
                      <EmptyState
                        title={
                          hasEditDisabled
                            ? "This meeting doesn't have notes"
                            : 'You don’t have meeting notes yet'
                        }
                        imageId="3D298"
                        description={
                          hasEditDisabled
                            ? 'No notes were recorded during this meeting'
                            : 'Record talking points and actions, and take personal notes'
                        }
                        action={
                          hasEditDisabled ? null : (
                            <ActionButton
                              useIcon="Plus"
                              onClick={handleCreateNotes}
                              pending={isLoadingCreateNotes}
                              disabled={hasEditDisabled}
                            >
                              Create meeting notes
                            </ActionButton>
                          )
                        }
                      />
                    )}
                    {isNotesDetails && (
                      <VStack p="s-16" gap="s-16">
                        <NotesHeader
                          disabled={hasEditDisabled}
                          isLoadingPerformanceRating={isLoadingPerformanceRating}
                          refetchMeetingNotesFeedback={refetchMeetingNotesFeedback}
                          meetingId={selectedMeeting?.id}
                          meetingNotes={meetingNotesDetails}
                          isLoading={isLoadingMeetingNotesDetails}
                          isCompletedEvent={
                            selectedMeeting?.status === MeetingEventStatus.Completed
                          }
                          onPerformanceRatingChange={onPerformanceRatingChange}
                        />
                        <Text variant="heading3">
                          {isEnabledAI ? 'AI Notes' : 'Notes'}
                        </Text>
                        <TalkingPointsSection
                          disabled={hasEditDisabled}
                          id={meetingNotesDetails?.id}
                          isLoading={isLoadingUpdatingTalkingPoints}
                          talkingPoints={talkingPoints}
                          onTalkingPointsChange={onTalkingPointsChange}
                        />
                        {showCompletedMeetingSection && (
                          <Grid columns={isEnabledAI ? 2 : 1} gap="s-16">
                            {(meetingNotesDetails.summary_status &&
                              [
                                AiGenerationStatus.IN_PROGRESS,
                                AiGenerationStatus.NOT_STARTED,
                              ].includes(meetingNotesDetails.summary_status)) ||
                            (meetingNotesDetails.summary_status === null &&
                              meetingNotesDetails.has_transcript) ? (
                              <PendingAIGeneration
                                variant="summary"
                                genrationStatus={
                                  meetingNotesDetails.summary_status ||
                                  AiGenerationStatus.NOT_STARTED
                                }
                              />
                            ) : isEnabledAI ? (
                              <SummarySection
                                disabled={hasEditDisabled}
                                id={meetingNotesDetails?.id}
                                isLoading={isLoadingUpdatingSummaryNotes}
                                onSummaryChange={onSummaryChange}
                                isEnabledAI={isEnabledAI}
                                summary={meetingNotesDetails?.summary}
                              />
                            ) : null}
                            {(meetingNotesDetails.action_items_status &&
                              [
                                AiGenerationStatus.IN_PROGRESS,
                                AiGenerationStatus.NOT_STARTED,
                              ].includes(meetingNotesDetails.action_items_status)) ||
                            (meetingNotesDetails.action_items_status === null &&
                              meetingNotesDetails.has_transcript) ? (
                              <PendingAIGeneration
                                variant="action_point"
                                genrationStatus={
                                  meetingNotesDetails.action_items_status ||
                                  AiGenerationStatus.NOT_STARTED
                                }
                              />
                            ) : (
                              <ActionPoints
                                isUpdating={isLoadingActionItems}
                                actionPoints={actionItems?.content.content || []}
                                disabled={hasEditDisabled}
                                onChange={newValue =>
                                  onContentChange({
                                    newValue: { content: newValue },
                                    role: 'action_items',
                                  })
                                }
                                isGeneratedByAi={
                                  meetingNotesDetails?.enable_ai &&
                                  meetingNotesDetails?.action_items_status ===
                                    AiGenerationStatus.COMPLETED
                                }
                              />
                            )}
                          </Grid>
                        )}
                        {meetingNotesDetails?.enable_personal_notes && (
                          <PersonalNotesSection
                            disabled={hasEditDisabled}
                            notes={personalNotes}
                            id={meetingNotesDetails?.id}
                            isLoading={isLoadingUpdatingPersonalNotes}
                            onPersonalNotesChange={onPersonalNotesChange}
                          />
                        )}
                      </VStack>
                    )}
                  </Widget>
                  <Attachments attachments={meetingNotesDetails?.attachments || []} />
                </>
              )}
            </>
          }
        />
      </PageBody>
    </PageWrapper>
  )
}
