import { useSelector } from 'react-redux'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { FeatureFlags } from '@src/store/auth/types'

export const useDynamicSlotsFeature = () => {
  const featureFlags = useSelector(selectFeatureFlags)
  return (
    featureFlags.includes(FeatureFlags.CombinedDynamicSlots) &&
    !featureFlags.includes(FeatureFlags.OldClassicScheduling)
  )
}
