import React, { useEffect } from 'react'
import capitalize from 'lodash/capitalize'
import { Input, InputGroup, Separator, Side, Subheader } from '@revolut/ui-kit'
import { selectorKeys } from '@src/constants/api'

import {
  ContractorInterface,
  EmployeeContractType,
  EmployeeInterface,
  EmployeeType,
  EMPLOYEE_PROFILE_SUB_SECTIONS,
} from '@src/interfaces/employees'
import useIsCommercial from '@src/hooks/useIsCommercial'
import { useSubsenioritySelector } from '@src/hooks/useSubsenioritySelector'
import AutoStepper from '@components/Stepper/AutoStepper'
import NewStepperTitle from '@components/Stepper/NewStepperTitle'
import NewStepperSectionCustomFields from '@components/Stepper/NewStepperSectionCustomFields'
import { useLapeContext } from '@src/features/Form/LapeForm'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { DynamicGroupIDs, SectionOptions } from '@src/interfaces/customFields'
import { PageBody } from '@components/Page/PageBody'
import { PageActions } from '@components/Page/PageActions'
import { ContractTypeOption } from '@src/features/Contracts/ContractTypeOption'
import LapeSenioritySelector from '@src/features/SenioritySelector/LapeSenioritySelector'
import { useEmployeeRecordToPrimaryContractEnabled } from '@src/pages/EmployeeProfile/Preview/ProfileSummary/common'
import { PermissionTypes } from '@src/store/auth/types'

import { UpdateButton } from '../common'
import { useGetEmployeeSettings, useGetOrganisationSettings } from '@src/api/settings'
import { useEmployeeDetailsOnAfterSubmit } from '../hooks'

export interface Props {
  data: EmployeeInterface
  refreshData?: () => void
  type: EmployeeType
  dynamicGroups: DynamicGroupIDs
  isSidebarContent?: boolean
}

const Name = ({ dynamicGroups, type, data, refreshData, isSidebarContent }: Props) => {
  const employeeRecordToPrimaryContractEnabled =
    useEmployeeRecordToPrimaryContractEnabled()
  const hasEditablePositionDetails = data.field_options.permissions?.includes(
    PermissionTypes.HasEditablePositionDetails,
  )
  const form = useLapeContext<EmployeeInterface | ContractorInterface>()
  const { initialValues, values } = form

  const isCommercial = useIsCommercial()
  const { data: settings } = useGetEmployeeSettings()
  const { data: organisationSettings } = useGetOrganisationSettings()
  const subsenioritySelector = useSubsenioritySelector<
    EmployeeInterface | ContractorInterface
  >(values, initialValues)

  useEffect(() => {
    if (!values.id && values.first_name && values.last_name) {
      values.display_name = `${values.first_name} ${values.last_name}`
    }
  }, [values.first_name, values.last_name])

  const { onAfterSubmit } = useEmployeeDetailsOnAfterSubmit(refreshData)
  const ActionsWrapper = isSidebarContent ? Side.Actions : PageActions

  return (
    <>
      <PageBody>
        <AutoStepper>
          <NewStepperSectionCustomFields
            sectionId={SectionOptions.EmployeeProfile}
            subSectionId={EMPLOYEE_PROFILE_SUB_SECTIONS.BASIC_INFO}
            dynamicGroups={dynamicGroups}
          >
            {values.id && !!settings?.enable_show_employee_id && (
              <Subheader variant="nested">
                <Subheader.Title>{`Employee ID: ${values.id}`}</Subheader.Title>
              </Subheader>
            )}
            <NewStepperTitle title="Legal names" mt="s-8" />
            <InputGroup>
              <LapeNewInput
                name="first_name"
                label="Legal first name"
                message="Your first name as it appears on your identification documents"
                required
              />
              <LapeNewInput
                name="middle_name"
                label="Middle name"
                message="Your middle name as it appears on your identification documents"
              />
              <LapeNewInput
                name="last_name"
                label="Legal last name"
                message="Your last name as it appears on your identification documents"
                required
              />
            </InputGroup>

            <NewStepperTitle title="Preferred name" />
            <LapeNewInput
              name="display_name"
              label="Preferred name"
              message="The name you prefer to be called e. g. your legal first name is Jonathan, but your friends call you Jon"
            />

            <NewStepperTitle title="Work email" />
            <LapeNewInput name="email" label="Work email" required />

            {employeeRecordToPrimaryContractEnabled && (
              <>
                <NewStepperTitle title="Work" />
                <InputGroup>
                  <LapeRadioSelectInput<EmployeeContractType>
                    name="contract_type"
                    label="Contract type"
                    selector={selectorKeys.contract_types}
                  >
                    {option => <ContractTypeOption option={option} />}
                  </LapeRadioSelectInput>
                  {type && (
                    <Input
                      description="This is determined by the contract type. Internal employees have access to our systems whilst external employees cannot access internal systems, unless specified otherwise."
                      disabled
                      label="Employee type"
                      value={capitalize(type)}
                    />
                  )}
                  <Separator />
                  <LapeRadioSelectInput
                    name="specialisation"
                    label="Role (Specialisation)"
                    selector={selectorKeys.specialisations}
                    clearable
                  />
                  {type === 'internal' && (
                    <>
                      <LapeSenioritySelector
                        clearable
                        name="seniority"
                        label="Seniority"
                        specialisationId={values.specialisation?.id || null}
                        required
                      />
                      {!!organisationSettings?.enable_multiple_levels_per_seniority && (
                        <LapeRadioSelectInput
                          name="specialisation_seniority_sublevel"
                          label="Seniority Level"
                          selector={subsenioritySelector}
                        />
                      )}
                      {!!settings?.enable_job_title &&
                        (isCommercial ? (
                          <LapeNewInput
                            name="job_title"
                            label="Job title"
                            description="Enter a job title"
                          />
                        ) : (
                          <LapeRadioSelectInput<{ id: string; name?: string }>
                            name="job_title"
                            label="Job title"
                            selector={selectorKeys.all_job_titles}
                            value={
                              'job_title' in values
                                ? {
                                    id: values.job_title,
                                  }
                                : undefined
                            }
                            onChange={selector => {
                              if (selector?.name && 'job_title' in values) {
                                values.job_title = selector.name
                              }
                            }}
                            clearable={false}
                          />
                        ))}
                    </>
                  )}
                  <Separator />
                  {hasEditablePositionDetails && (
                    <>
                      <LapeRadioSelectInput
                        name="location"
                        label="Location"
                        selector={selectorKeys.location}
                      />
                      <LapeRadioSelectInput
                        name="entity"
                        label="Entity"
                        selector={selectorKeys.entity}
                      />
                    </>
                  )}
                </InputGroup>
              </>
            )}
          </NewStepperSectionCustomFields>
        </AutoStepper>
      </PageBody>
      <ActionsWrapper>
        <UpdateButton
          form={form}
          employeeTypeChanged={type !== data?.employee_type}
          onAfterSubmit={onAfterSubmit}
        />
      </ActionsWrapper>
    </>
  )
}

export default Name
