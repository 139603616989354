import { useLapeContext } from '@src/features/Form/LapeForm'
import { EngagementResultsScope } from '@src/interfaces/engagement'
import { FunctionInterface } from '@src/interfaces/functions'
import { SurveyResultsPublished } from '@src/pages/Performance/Engagement/components/SurveyResultsPublished'
import { SurveryResultsTabPublished as SurveyResultsPublishedV2 } from '@src/pages/Performance/Engagement/components/v2/ResultsTab/Published'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { FeatureFlags } from '@src/store/auth/types'
import { toIdAndName } from '@src/utils/toIdAndName'
import React from 'react'
import { useSelector } from 'react-redux'

export const Engagement = () => {
  const { values } = useLapeContext<FunctionInterface>()

  const featureFlags = useSelector(selectFeatureFlags)
  const isEngagementV2 = featureFlags.includes(FeatureFlags.EngagementV2)

  if (isEngagementV2) {
    return (
      <SurveyResultsPublishedV2
        scopeFilters={[
          {
            columnName: 'function',
            filters: [toIdAndName(String(values.id))],
            nonResettable: true,
          },
        ]}
      />
    )
  }
  return (
    <SurveyResultsPublished
      entity={{
        type: EngagementResultsScope.Functions,
        id: values.id,
        permissions: values.field_options.permissions,
      }}
    />
  )
}
