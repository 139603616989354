import React, { useContext, useEffect, useState } from 'react'
import set from 'lodash/set'
import merge from 'lodash/merge'
import { KPIFormLocationState, KpiInterface, UpdateTypes } from '@src/interfaces/kpis'
import { css } from 'styled-components'
import { BaseChartInner } from '@components/Charts/BaseChart/BaseChartInner'
import {
  getKPICommentsAPI,
  getKpiPerformanceGraph,
  kpisRequestsNew,
  updateKPI,
} from '@src/api/kpis'
import { CellTypes } from '@src/interfaces/data'
import RoadmapProgressChart from '@components/Charts/RoadmapProgressChart/RoadmapProgressChart'
import QualityScore from '@components/ColumnInserts/QualityScore/QualityScore'
import { EntityPermissions, PermissionTypes } from '@src/store/auth/types'
import { selectorKeys } from '@src/constants/api'
import { connect, useLape } from 'lape'
import {
  Box,
  Flex,
  Group,
  InputGroup,
  Link as UIKitLink,
  MoreBar,
  Subheader,
  TabBar,
  Text,
  Token,
  BoxProps,
  Tooltip as UIKitTooltip,
  useTooltip,
} from '@revolut/ui-kit'
import { useLapeContext } from '@src/features/Form/LapeForm'
import NewStaticTable from '@components/Table/NewStaticTable'
import useTabBarSwitcher from '@src/features/TabBarSwitcher/useTabBarSwitcher'
import useChartTableSwitcher, {
  ChartTableTabs,
} from '@src/features/TabBarSwitcher/useChartTableSwitcher'
import SettingsButtons, {
  ArchiveButton,
  CopyButton,
  DeleteButton,
} from '@src/features/SettingsButtons'
import { Statuses } from '@src/interfaces'
import { navigateTo } from '@src/actions/RouterActions'
import { getBackUrl } from '@src/pages/Forms/KpiForm/common/Header'
import { useLocation } from 'react-router-dom'
import CommentsSection from '@src/features/Comments/CommentsSection'
import { FormPreview } from '@components/FormPreview/FormPreview'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { usePreviewPendingChanges } from '@components/FormPreview/changelog'
import { getPendingKpiChangesRequest } from '@src/api/changelog'
import { changelogApiKPI } from '@src/pages/Forms/KpiForm/General'
import {
  fixChangelogTargets,
  fixPreviousValuesTargets,
  useGetEntityName,
} from '@src/utils/kpi'
import RoadmapTargetsTable from '@src/features/FormTabs/Kpi/KPITargets/RoadmapTargets/RoadmapTargetsTable'
import { SummarySubheader, SummaryTableContainer } from '@src/features/Summary/common'
import ValueTargets from '@src/features/FormTabs/Kpi/KPITargets/ValueTargets/ValueTargetsTable'
import { RoadmapCalibrationBanner } from '@src/pages/Forms/KpiForm/common/common'
import QualityChecks from '@components/ColumnInserts/QualityScore/QualityChecks'
import ActionWidget from '@components/ActionWidget/ActionWidget'
import {
  TargetsApprovalsContext,
  TargetsApprovalsValue,
} from '@src/pages/Forms/KpiForm/common/TargetsApprovals'
import SwitchButton from '@components/SwitchButton/SwitchButton'
import { AnalyticsDashbordsTable } from './AnalyticsDashbordsTable'
import { formatUTCDate } from '@src/utils/format'

interface Props {
  embedded?: boolean
  data?: KpiInterface
}

const VerticalScrollCss = css`
  overflow-y: auto;
`

const WordWrapCss = css`
  overflow-wrap: break-word;
  word-wrap: break-word;
  white-space: pre-wrap;
`

enum EpicCharts {
  Current = 'Current',
  Historical = 'Historical',
}

const epicChartsTabs = [EpicCharts.Current, EpicCharts.Historical]

const StepWrapper = (props: BoxProps) => (
  <Box
    css={WordWrapCss}
    bg={Token.color.widgetBackground}
    radius="widget"
    p={{ all: '10px', md: '20px' }}
    {...props}
  />
)

const tabsIcons = {
  [EpicCharts.Current]: 'Percent' as const,
  [EpicCharts.Historical]: 'BarChart' as const,
}

const Preview = ({ embedded, data }: Props) => {
  const refreshButtonTooltip = useTooltip()

  const { getEntityName } = useGetEntityName()
  const form = useLapeContext<KpiInterface>()
  const values = data || form.values
  const initialValues = data || form.initialValues
  const location = useLocation<KPIFormLocationState>()
  const { status, approvalFlow, reviewCycle } = useContext<TargetsApprovalsValue>(
    TargetsApprovalsContext,
  )

  const [showPendingChanges, setShowPendingChanges] = useState(true)

  const pendingChangelog = usePreviewPendingChanges<KpiInterface>({
    api: changelogApiKPI,
    data: values,
    showPendingChanges,
    disabled: status !== Statuses.pending,
    pendingChangesRequest: getPendingKpiChangesRequest,
  })

  const canApprove = values.field_options?.actions?.includes(EntityPermissions.Approve)

  useEffect(() => {
    if (status !== Statuses.pending) {
      form.changelog = {}
      if (pendingChangelog.data) {
        form.reset(merge(form.values, pendingChangelog.data), initialValues)
      }
      return
    }
    if (showPendingChanges) {
      form.changelogApi = changelogApiKPI
      form.changelog = pendingChangelog.data
        ? fixChangelogTargets(pendingChangelog.data, values)
        : {}

      form.reset(merge(form.values, form.changelog), initialValues)
    } else if (pendingChangelog.previousValues) {
      form.changelog = {}

      form.reset(
        fixPreviousValuesTargets(pendingChangelog.previousValues, values),
        initialValues,
      )
    }
  }, [showPendingChanges, status, pendingChangelog.data])

  const canViewComments = values.field_options?.permissions?.includes(
    PermissionTypes.CanAccessKPIComments,
  )

  const state = useLape({
    updating: false,
    isSubmittingApproval: false,
  })

  const epicChartsSwitcher = useTabBarSwitcher({
    tabs: epicChartsTabs,
    useIcons: tabsIcons,
  })
  const chartTableSwitcher = useChartTableSwitcher()

  const showGraph = values.field_options?.permissions?.includes(
    PermissionTypes.ViewResultsPendingKpi,
  )

  const utcLastUpdateDate = formatUTCDate(values?.extra?.last_progress_update)

  const chartComponent = (
    <StepWrapper height={{ all: 306, md: 360 }} p={0} pl="s-8">
      <BaseChartInner
        title="Metric over time"
        isNew
        fetchData={getKpiPerformanceGraph}
        id={values.id}
        showButtons
      />
    </StepWrapper>
  )

  const approvalProcess =
    values.id && !embedded && approvalFlow ? (
      <InputGroup>
        {(status === Statuses.pending || status === Statuses.requires_changes) &&
          canApprove &&
          reviewCycle && (
            <ActionWidget
              title={`You are approving targets for ${reviewCycle?.name}`}
              text={`Please review the targets in pending status to approve them. 
            Future targets if any will be requested for approval during its corresponding 
            setting period.`}
            >
              <MoreBar>
                <SwitchButton
                  onClick={() => setShowPendingChanges(!showPendingChanges)}
                  checked={showPendingChanges}
                >
                  Show pending changes
                </SwitchButton>
              </MoreBar>
            </ActionWidget>
          )}
        {approvalFlow}
      </InputGroup>
    ) : null

  const basicInfo = (
    <Box pt="s-16">
      <FormPreview<KpiInterface>
        data={values}
        title="Basic info"
        changelog={pendingChangelog}
      >
        <Group>
          {!values.is_employee && (
            <FormPreview.Item<KpiInterface>
              field=""
              title="Organisation unit"
              insert={d => <Text>{getEntityName(d)}</Text>}
            />
          )}
          <FormPreview.Item<KpiInterface>
            field="owner"
            title={values.is_employee ? 'Organisation unit' : 'Owner'}
            type="employee"
          />
          {values.is_inherited && (
            <FormPreview.Details<KpiInterface>
              field="parent.name"
              title="Parent"
              to={d => pathToUrl(ROUTES.FORMS.KPI.PREVIEW, { id: d.parent?.id })}
            />
          )}
          <FormPreview.Item<KpiInterface> field="unit" title="Unit" />
          <FormPreview.Details<KpiInterface> field="description" title="Description" />
          {values.quality_check?.checks && values.quality_check?.checks?.length !== 0 && (
            <FormPreview.CollapsibleDescription
              title="Quality"
              leftInfo={<QualityScore value={values.quality_score} />}
              content={<QualityChecks data={values.quality_check.checks} />}
            />
          )}
          {values.sql_query_db?.id === 'looker' && values.look_url ? (
            <FormPreview.Item<KpiInterface>
              field="look_url"
              title="Look URL"
              insert={({ look_url }) => (
                <UIKitLink href={look_url!} target="_blank" rel="noreferrer noopener">
                  {look_url}
                </UIKitLink>
              )}
            />
          ) : null}
        </Group>
      </FormPreview>
    </Box>
  )

  const refreshKpi = async () => {
    state.updating = true
    values.can_run_query = false
    await updateKPI(values.id)
    set(values, 'extra.last_progress_update', new Date())
    set(values, 'quality_check.modified_on', new Date())
    state.updating = false
  }

  const kpiIsUpdatingText =
    'Metric update is running in the background, please refresh the page in a few minutes.'

  const refreshTooltipText =
    state.updating || !values?.can_run_query ? kpiIsUpdatingText : undefined

  const buttons = (
    <SettingsButtons mb="s-24">
      <MoreBar.Action
        onClick={() => {
          if (!(state.updating || !values?.can_run_query)) {
            refreshKpi()
          }
        }}
        aria-disabled={state.updating || !values?.can_run_query}
        useIcon="ArrowExchange"
        pending={state.updating}
        {...refreshButtonTooltip.getAnchorProps()}
      >
        Refresh
        {refreshTooltipText && (
          <UIKitTooltip {...refreshButtonTooltip.getTargetProps()} width={200}>
            {refreshTooltipText}
          </UIKitTooltip>
        )}
      </MoreBar.Action>
      {!values.is_inherited ? (
        <>
          <CopyButton
            cleanFields={[
              'approver',
              'approval_step',
              'quality_check',
              'progress_history',
              'children',
              'children_type',
              'created_by',
              'creation_date_time',
              'can_run_query',
              'has_cascaded_children',
              'mandatory_checkes_passed',
              'kpi_category',
              'updated_date_time',
              'rejection_reason',
            ]}
            afterSubmitUrl={`${pathToUrl(ROUTES.FORMS.KPI.GENERAL)}?type=${
              values.update_type
            }`}
          >
            Create copy
          </CopyButton>
          <DeleteButton
            isVisible={!!values?.id}
            onAfterDelete={() => {
              navigateTo(getBackUrl(values, location.state))
            }}
            deleteApi={kpisRequestsNew.delete!}
            title="KPI"
          />
        </>
      ) : null}
      <ArchiveButton
        showDialog={values.has_cascaded_children}
        dialogText={`If you archive this KPI all the KPIs that are cascaded from it will be also archived. Are you sure you want to proceed?`}
      />
    </SettingsButtons>
  )

  if (values?.update_type === UpdateTypes.roadmap) {
    return (
      <Box maxWidth={embedded ? '100%' : 700} mt="s-8" pb="36px">
        {!embedded && buttons}
        {approvalProcess}
        {basicInfo}
        <Box mt="s-16">
          <SummarySubheader>Metrics</SummarySubheader>
          <RoadmapCalibrationBanner mb="s-16" />
          <SummaryTableContainer>
            <RoadmapTargetsTable readonly />
          </SummaryTableContainer>
        </Box>
        <Box mt="s-16">
          <Subheader variant="nested">
            <Subheader.Title>Progress Chart</Subheader.Title>
            {utcLastUpdateDate && (
              <Subheader.Side>
                <Text>Last refresh on {utcLastUpdateDate}</Text>
              </Subheader.Side>
            )}
          </Subheader>
          <Flex mb="s-16">
            <TabBar variant="segmented">{epicChartsSwitcher.tabBar}</TabBar>
          </Flex>
          <StepWrapper>
            {epicChartsSwitcher.currentTab === EpicCharts.Current ? (
              <RoadmapProgressChart targets={values.target_epics} />
            ) : (
              chartComponent
            )}
          </StepWrapper>
        </Box>
        {/* Need the empty node because sidebar cuts the spaces (padding/margin) */}
        {embedded && <Box height={40} />}
        {canViewComments && !embedded && (
          <InputGroup>
            <CommentsSection api={getKPICommentsAPI(values.id)} />
          </InputGroup>
        )}
      </Box>
    )
  }

  const rows = {
    cells: values?.extra?.result?.[0]
      ? Object.keys(values.extra.result[0]).map(key => ({
          dataPoint: key,
          idPoint: key,
          type: CellTypes.text,
          title: key,
          sortKey: null,
          filterKey: null,
          width: 300,
          selectorsKey: selectorKeys.none,
        }))
      : [],
  }

  return (
    <Box pb="s-40" maxWidth={embedded ? '100%' : 700} mt="s-8">
      {!embedded && buttons}
      {approvalProcess}
      {values.update_type === UpdateTypes.sql && values.extra?.error && (
        <Box mt="s-16">
          <ActionWidget
            title="There was a problem running your query"
            text={values.extra.error}
          />
        </Box>
      )}
      {basicInfo}
      <Box mt="s-16">
        <SummarySubheader>Metrics</SummarySubheader>
        <SummaryTableContainer>
          <ValueTargets readonly />
        </SummaryTableContainer>
      </Box>
      <Box mt="s-16">
        <SummarySubheader>Analytics</SummarySubheader>
        <SummaryTableContainer>
          <AnalyticsDashbordsTable />
        </SummaryTableContainer>
      </Box>
      <Box mt="s-16">
        <Subheader variant="nested">
          <Subheader.Title>Progress Chart</Subheader.Title>
          {utcLastUpdateDate && (
            <Subheader.Side>
              <Text>Last refresh on {utcLastUpdateDate}</Text>
            </Subheader.Side>
          )}
        </Subheader>
        <Flex mb="s-16">{chartTableSwitcher.tabBar}</Flex>
        {showGraph ? (
          <>
            {chartTableSwitcher.currentTab === ChartTableTabs.Chart ? (
              chartComponent
            ) : (
              <>
                {values?.extra?.result ? (
                  <Box css={VerticalScrollCss} maxHeight={380}>
                    <NewStaticTable rows={rows} data={values?.extra?.result} />
                  </Box>
                ) : (
                  <StepWrapper height={360}>No data available</StepWrapper>
                )}
              </>
            )}
          </>
        ) : (
          <ActionWidget
            title="Missing permissions to view the data"
            text={
              <>
                Please ensure that:
                <br />
                1. The KPI is in Approved status (pending KPIs are data restricted).
                <br />
                2. You have access to
                {values.update_type === UpdateTypes.sql && values.sql_query_db?.name
                  ? ` the ${values.sql_query_db.name} database.`
                  : ' view the database that the KPI is using.'}
              </>
            }
          />
        )}
      </Box>
      {/* Need the empty node because sidebar cuts the spaces (padding/margin) */}
      {embedded && <Box height={40} />}
      {canViewComments && !embedded && (
        <Box mt="s-16">
          <InputGroup>
            <CommentsSection api={getKPICommentsAPI(values.id)} />
          </InputGroup>
        </Box>
      )}
    </Box>
  )
}

export default connect(Preview)
