import React from 'react'
import { Tooltip, VStack, Flex, Text, Token, TooltipState } from '@revolut/ui-kit'
import { RecommendedGradesInterface } from '../../EmployeePerformanceLayout/utils'
import { getGradeColor } from '@src/interfaces/performance'
import { useGetReviewGradesMap } from '@src/utils/grades'

interface Props {
  recommendedGrades: RecommendedGradesInterface
  tooltip: TooltipState<Element>
}

const getGradesAndPercentages = (grades?: RecommendedGradesInterface['grades']) => {
  const goalsGrade = grades?.kpiGrade || grades?.deliverablesGrade
  const skillsGrade = grades?.skillsGrade
  const valuesGrade = grades?.cultureValuesGrade || grades?.cultureSkillsGrade

  const hasSkillsAndValues = skillsGrade && valuesGrade
  const hasSkillsOrValues = skillsGrade || valuesGrade

  let goalsPercentage = ''
  if (goalsGrade && hasSkillsAndValues) {
    goalsPercentage = '50%'
  } else if (goalsGrade && hasSkillsOrValues) {
    goalsPercentage = '66.6%'
  }

  let skillsValuesPercentage = '33.3%'
  if (hasSkillsAndValues) {
    skillsValuesPercentage = '25%'
  }

  return { goalsGrade, skillsGrade, valuesGrade, goalsPercentage, skillsValuesPercentage }
}

export const HowGradeIsCalculatedTooltip = ({ tooltip, recommendedGrades }: Props) => {
  const {
    goalsGrade,
    skillsGrade,
    valuesGrade,
    goalsPercentage,
    skillsValuesPercentage,
  } = getGradesAndPercentages(recommendedGrades.grades)
  const { gradesMap } = useGetReviewGradesMap()
  if (!goalsGrade && !skillsGrade && !valuesGrade) {
    return null
  }
  return (
    <Tooltip {...tooltip.getTargetProps()} placement="right-end" width={300}>
      <VStack p="s-12">
        <Text variant="primary" mb="s-8">
          How the grade is calculated
        </Text>
        {goalsGrade && (
          <Flex justifyContent="space-between">
            <Text color={Token.color.greyTone50}>{goalsPercentage} Goals</Text>
            <Text color={getGradeColor(goalsGrade)}>{gradesMap[goalsGrade]}</Text>
          </Flex>
        )}
        {skillsGrade && (
          <Flex justifyContent="space-between">
            <Text color={Token.color.greyTone50}>{skillsValuesPercentage} Skills</Text>
            <Text color={getGradeColor(skillsGrade)}>{gradesMap[skillsGrade]}</Text>
          </Flex>
        )}
        {valuesGrade && (
          <Flex justifyContent="space-between">
            <Text color={Token.color.greyTone50}>{skillsValuesPercentage} Values</Text>
            <Text color={getGradeColor(valuesGrade)}>{gradesMap[valuesGrade]}</Text>
          </Flex>
        )}
      </VStack>
    </Tooltip>
  )
}
