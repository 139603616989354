import React from 'react'
import { CellInsertParams } from '@src/interfaces/data'

import { TalentUIInterface } from '../types'
import { TalentType } from '@src/interfaces/talent/talent'
import { FinalGrade } from '@src/interfaces/performance'
import GradeLockIcon from '@src/features/Calibration/GradeLockIcon'
import { getAboveBelowSuggestedGrades, getGradeLabel, isFunctionType } from '../utils'
import {
  Flex,
  Icon,
  TextButton,
  Token,
  useToggle,
  Text,
  HStack,
  TextSkeleton,
  Ellipsis,
  Grid,
} from '@revolut/ui-kit'
import { getInverseColorsAndGrade } from '@src/utils/grades'
import { SetGradePopup } from './SetGradePopup'
import { IdAndName } from '@src/interfaces'
import { StyledActionButton } from './StyledActionButton'
import { AdjustedTooltipWithIcon } from './AdjustedTooltipWithIcon'

interface Props {
  data: CellInsertParams<TalentUIInterface>['data']
  talentType: TalentType
  changeHoFGrade: (id: number, rating: FinalGrade, justification?: string) => void
  gradeOptions: IdAndName<FinalGrade>[]
  requireJustification: boolean
  showRanking: boolean
}

export const HoFGradeCell = ({
  data,
  talentType,
  gradeOptions,
  changeHoFGrade,
  requireJustification,
  showRanking,
}: Props) => {
  const [isPopupOpen, setPopupOpen] = useToggle()

  if (data.self_employee_locked) {
    return <GradeLockIcon />
  }

  const readOnly = data.field_options.read_only.includes(
    'function_owner_grade_suggestion',
  )
  const canChange = !isFunctionType(talentType) ? false : !readOnly

  const { color, grade } =
    getInverseColorsAndGrade(
      data.function_owner_grade_suggestion
        ? {
            id: data.function_owner_grade_suggestion.id,
            label:
              getGradeLabel(gradeOptions, data.function_owner_grade_suggestion.id) ||
              data.function_owner_grade_suggestion.label,
          }
        : null,
    ) || {}

  const gradeSuggestion = data.ranking_grade
    ? {
        id: data.ranking_grade,
        name: getGradeLabel(gradeOptions, data.ranking_grade) || '',
        label: getGradeLabel(gradeOptions, data.ranking_grade),
      }
    : null

  if (data.isPendingGrade) {
    return <TextSkeleton height={14} />
  }

  const adjustedOptions = gradeOptions.map(option => {
    if (!showRanking) {
      return option
    }

    const hodGrade = data.department_owner_grade_suggestion?.id
    const hodSelectedPoor = hodGrade === FinalGrade.Poor

    let disabled = !!hodGrade && option.id === FinalGrade.Poor
    if (hodSelectedPoor) {
      disabled = option.id !== FinalGrade.Poor
    }

    const tooltipText = disabled
      ? 'This grade cannot be selected, as the HoD input has priority for underperformance.'
      : undefined

    return { ...option, disabled, tooltipText }
  })

  const adjustedAttitude = getAboveBelowSuggestedGrades(
    data.department_owner_grade_suggestion?.id,
    gradeSuggestion?.id,
  )

  return (
    <>
      {grade ? (
        <Flex justifyContent="space-between" alignItems="center">
          <HStack align="center" gap="s-4">
            <Ellipsis>
              <Text color={color}>{grade}</Text>
            </Ellipsis>
            <AdjustedTooltipWithIcon
              attitude={adjustedAttitude}
              calibratorName={data.function_grade_calibrator?.full_name}
              justification={data.function_grade_justification}
            />
          </HStack>
          {canChange && (
            <TextButton
              aria-label="Edit HoF grade"
              color={Token.color.foreground}
              onClick={e => {
                e.stopPropagation()
                setPopupOpen.on()
              }}
            >
              <Icon name="Pencil" size={16} />
            </TextButton>
          )}
        </Flex>
      ) : (
        <Grid columns="1fr auto" gap="s-8" placeItems="center start">
          <Ellipsis>
            <Text style={{ fontStyle: 'italic' }} color={Token.color.greyTone20}>
              {gradeSuggestion?.label || '-'}
            </Text>
          </Ellipsis>
          <HStack gap="s-8">
            <StyledActionButton
              styleVariant="warning"
              size="xs"
              aria-label="Edit HoF grade"
              iconOnly
              useIcon="Pencil"
              onClick={e => {
                e.stopPropagation()
                setPopupOpen.on()
              }}
            />
            {gradeSuggestion && (
              <StyledActionButton
                styleVariant="success"
                size="xs"
                aria-label="Accept suggested HoF grade"
                iconOnly
                useIcon="Check"
                onClick={e => {
                  e.stopPropagation()
                  changeHoFGrade(data.id, gradeSuggestion.id)
                }}
              />
            )}
          </HStack>
        </Grid>
      )}
      <SetGradePopup
        isOpen={isPopupOpen}
        onClose={setPopupOpen.off}
        data={data}
        gradeOptions={adjustedOptions}
        requireJustification={requireJustification}
        initialSelectedGrade={
          data.function_owner_grade_suggestion?.id
            ? {
                id: data.function_owner_grade_suggestion.id,
                name: data.function_owner_grade_suggestion.label,
              }
            : gradeSuggestion
        }
        gradeSuggestion={gradeSuggestion?.id}
        onConfirm={async (selectedGrade, justification) => {
          await changeHoFGrade(data.id, selectedGrade, justification)
        }}
      />
    </>
  )
}
