import React, { useEffect, useRef, useState } from 'react'
import {
  Dots,
  IconButton,
  Input,
  ItemSkeleton,
  Side,
  Token,
  VStack,
} from '@revolut/ui-kit'

import {
  useCreateEngagementAnswerReply,
  useGetEngagementAnswerDetails,
  useGetEngagementAnswerReplies,
} from '@src/api/engagement'
import { ReplyWidget } from '../../common/ReplyWidget'
import { AnswerWidget } from '../../common/AnswerWidget'

interface Props {
  answerId: number
  onAcknowledge: VoidFunction
}
export const AcknowledgementSidebar = ({ answerId, onAcknowledge }: Props) => {
  const [replyInputValue, setReplyInputValue] = useState<string>('')

  const {
    data: answerData,
    refetch: refetchAnswer,
    isLoading: isLoadingAnswer,
  } = useGetEngagementAnswerDetails(answerId)

  const {
    data: repliesData,
    isLoading: isLoadingReplies,
    isRefetching: isRefetchingReplies,
    refetch: refetchReplies,
  } = useGetEngagementAnswerReplies(answerId)
  const replies = repliesData?.results || []

  const { mutateAsync: sendReply, isLoading: isLoadingSendReply } =
    useCreateEngagementAnswerReply(answerId)

  const commentsBottomRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    commentsBottomRef.current?.scrollIntoView({ behavior: 'smooth' })
  }, [isLoadingSendReply, commentsBottomRef])

  const handleSendReply = async () => {
    if (replyInputValue) {
      setReplyInputValue('')
      await sendReply({ text: replyInputValue })
      await refetchReplies()
      commentsBottomRef.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }

  return (
    <VStack space="s-16">
      <AnswerWidget
        canAcknowledge
        answer={answerData}
        isLoading={isLoadingAnswer}
        onAcknowledge={() => {
          refetchAnswer()
          onAcknowledge()
        }}
      />
      {!replies.length && isLoadingReplies ? (
        <>
          <ItemSkeleton />
          <ItemSkeleton />
          <ItemSkeleton />
        </>
      ) : (
        <>
          {replies.map(reply => (
            <ReplyWidget
              key={reply.id}
              employee={reply.employee}
              answerText={reply.text}
            />
          ))}
          {(isLoadingSendReply || isRefetchingReplies) && (
            <Dots color={Token.color.greyTone50} duration={900} />
          )}
          <div ref={commentsBottomRef} />
        </>
      )}
      <Side.Actions>
        <Input
          label="Reply"
          value={replyInputValue}
          onChange={e => setReplyInputValue(e.currentTarget.value)}
          onKeyDown={async e => {
            if (e.key === 'Enter') {
              await handleSendReply()
            }
          }}
          renderAction={() => {
            const disabled = isLoadingReplies || isLoadingSendReply

            return (
              <IconButton
                aria-label="Send reply"
                color={disabled ? Token.color.greyTone20 : Token.color.blue}
                disabled={isLoadingSendReply}
                useIcon="SendMessage"
                onClick={handleSendReply}
              />
            )
          }}
        />
      </Side.Actions>
    </VStack>
  )
}
