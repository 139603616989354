import React, { useState } from 'react'
import { Button, StatusPopup, useToggle } from '@revolut/ui-kit'
import { EmployeeWorkAndOrgDetailsInterface } from '@src/interfaces/employees'
import { DatePickerInputProps } from '@components/Inputs/DatePickerInput/DatePickerInput'
import EffectiveDatePopup from '@src/features/Popups/EffectiveDatePopup'
import { useFormValidator } from '@src/features/Form/FormValidator'
import { useLapeContext } from '@src/features/Form/LapeForm'

interface UpdateButtonProps {
  datePickerProps?: DatePickerInputProps
  onAfterSubmit?: VoidFunction
}

export const UpdateButton = ({
  onAfterSubmit,
  ...datePickerProps
}: UpdateButtonProps) => {
  const { dirty, disabled, submit, values } =
    useLapeContext<EmployeeWorkAndOrgDetailsInterface>()
  const formValidator = useFormValidator()

  const [checkingConflicts, setCheckingConflicts] = useState(false)
  const [effectiveDatePopupOpen, effectiveDateToggler] = useToggle()
  const [successPopupOpen, successPopupToggler] = useToggle()

  const shouldHideButton = (!!values.id && !dirty) || disabled

  const onSaveChanges = async () => {
    try {
      await submit()
      successPopupToggler.on()
    } finally {
      effectiveDateToggler.off()
    }
  }

  return (
    <>
      {effectiveDatePopupOpen && (
        <EffectiveDatePopup
          onCheckingConflictsChange={setCheckingConflicts}
          onClick={formValidator!.validate(onSaveChanges)}
          onClose={() => effectiveDateToggler.off()}
          {...datePickerProps}
        />
      )}
      <StatusPopup
        onClose={() => {
          successPopupToggler.off()
          onAfterSubmit?.()
        }}
        open={successPopupOpen}
        variant="success"
      >
        <StatusPopup.Title>Compensation details successfully updated</StatusPopup.Title>
      </StatusPopup>
      {shouldHideButton ? null : (
        <Button
          elevated
          onClick={() => effectiveDateToggler.on()}
          pending={checkingConflicts}
        >
          Save changes
        </Button>
      )}
    </>
  )
}
