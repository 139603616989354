import React from 'react'
import { ComposedChart, Legend, Tooltip as RechartsTooltip, XAxis, YAxis } from 'recharts'
import { Relative, Token } from '@revolut/ui-kit'
import { ChartSkeleton } from '@src/pages/Forms/QueryForm/components/Charts/ChartSkeleton'
import { ChartEmptyResults } from '@src/pages/Forms/QueryForm/components/Charts/ChartEmptyResults'
import { ResponsiveContainer } from '@src/pages/Forms/QueryForm/components/Charts/ResponsiveContainer'
import { ChartTooltip } from '@src/pages/Forms/QueryForm/components/Charts/components/ChartTooltip'
import { formatPercentage } from '@src/utils/format'
import {
  GradeDistributionHistoryDataKey,
  GradeDistributionHistoryItemData,
} from '@src/features/ReviewCycle/Analytics/Charts/HistoryBarCharts/GradeDistributionBarChart/ChartData'
import { DefaultLegend } from '@src/pages/Forms/QueryForm/components/Charts/components/DefaultLegend'
import { Tooltip } from '@src/features/ReviewCycle/Analytics/Charts/HistoryBarCharts/GradeDistributionBarChart/Tooltip'
import { renderStackedRectBar } from '@src/features/ReviewCycle/Analytics/Charts/components/StackedRectBar'
import { YAxisUnits } from '@src/features/ReviewCycle/Analytics/Charts/HistoryBarCharts/hooks/useChartData'

const CHART_HEIGHT = 500
const CHART_MARGIN = { top: 16, right: 40, bottom: 5, left: 50 }
const DEFAULT_TICK_COUNT = 5
const X_AXIS_TICK_MARGIN = 8
const Y_AXIS_TICK_MARGIN = 65

interface Props {
  dataKeys: GradeDistributionHistoryDataKey[]
  data: GradeDistributionHistoryItemData[]
  yAxisUnit: YAxisUnits
  isLoading: boolean
  height?: number
}

export const GradesDistributionHistoryBarChart = ({
  height = CHART_HEIGHT,
  data,
  dataKeys,
  yAxisUnit,
  isLoading,
}: Props) => {
  if (isLoading) {
    return <ChartSkeleton height={height} />
  }

  if (!data.length) {
    return <ChartEmptyResults height={height} />
  }

  return (
    <Relative width="100%" height="100%">
      <ResponsiveContainer width="100%" height="100%">
        <ComposedChart data={data} layout="horizontal" margin={CHART_MARGIN}>
          <XAxis
            dataKey="label"
            tickMargin={X_AXIS_TICK_MARGIN}
            axisLine={false}
            tickLine={false}
          />
          <YAxis
            type="number"
            axisLine={false}
            tickLine={false}
            allowDecimals={false}
            tickCount={DEFAULT_TICK_COUNT}
            tick={{ color: Token.color.greyTone50, fontSize: 12 }}
            orientation="right"
            tickFormatter={value =>
              yAxisUnit === YAxisUnits.Percentage ? formatPercentage(value / 100) : value
            }
            tickMargin={Y_AXIS_TICK_MARGIN}
          />
          {dataKeys.map(renderStackedRectBar)}
          <RechartsTooltip
            cursor={false}
            content={<ChartTooltip tooltip={Tooltip} dataKeys={dataKeys} />}
          />
          <Legend
            content={props => (
              <DefaultLegend props={props} dataKeys={dataKeys} justifyContent="center" />
            )}
            verticalAlign="bottom"
            wrapperStyle={{
              paddingTop: '32px',
            }}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </Relative>
  )
}
