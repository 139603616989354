import {
  ActionButtonSkeleton,
  HStack,
  Icon,
  TextButton,
  Text,
  Spinner,
  Button,
} from '@revolut/ui-kit'
import { captureException } from '@sentry/react'
import { navigateTo } from '@src/actions/RouterActions'
import { createDraftGoal } from '@src/api/goals'
import { useGetSelectors } from '@src/api/selectors'
import { PrimaryAction } from '@src/components/PrimaryAction/PrimaryAction'
import { EntityTypes, selectorKeys } from '@src/constants/api'
import { ROUTES } from '@src/constants/routes'
import { useOrgEntity } from '@src/features/OrgEntityProvider/OrgEntityProvider'
import { GoalContentType, GoalsInterface } from '@src/interfaces/goals'
import { isOnboardingPath } from '@src/pages/OnboardingChecklistV2/common/helpers'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import { pathToUrl } from '@src/utils/router'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import React, { ReactNode, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useOwnerId } from './useOwnerId'

const useDefaultWriteAccess = (
  preselectedType?: EntityTypes.companyV2 | EntityTypes.department | EntityTypes.team,
) => {
  const { entity } = useOrgEntity()

  const permissions = useSelector(selectPermissions)
  const canAddGoals = !!entity?.data.field_options?.permissions?.includes(
    PermissionTypes.CanAddGoals,
  )

  const entityPermissionMap = {
    [EntityTypes.companyV2]: PermissionTypes.CanAddCompanyGoal,
    [EntityTypes.department]: PermissionTypes.CanAddDepartmentGoal,
    [EntityTypes.team]: PermissionTypes.CanAddTeamGoal,
  }

  if (!entity) {
    return false
  }

  switch (entity.type) {
    case EntityTypes.company:
    case EntityTypes.companyV2:
      if (!preselectedType) {
        return [
          PermissionTypes.CanAddCompanyGoal,
          PermissionTypes.CanAddDepartmentGoal,
          PermissionTypes.CanAddTeamGoal,
        ].some(permission => permissions.includes(permission))
      }

      return permissions.includes(entityPermissionMap[preselectedType])
    default:
      return canAddGoals
  }
}

const isCompany = (type: EntityTypes) => {
  return [EntityTypes.company, EntityTypes.companyV2].includes(type)
}

const getChilLevelContentType = (type: EntityTypes | undefined) => {
  if (type && isCompany(type)) {
    return EntityTypes.department
  }

  if (type === EntityTypes.department) {
    return EntityTypes.teams
  }

  if (type === EntityTypes.teams) {
    return EntityTypes.employee
  }
  return EntityTypes.employees
}

export const AddGoalAction = ({
  variant = 'default',
  label,
  preselectedType,
  parent,
}: {
  variant?: 'text' | 'button' | 'default'
  label?: ReactNode
  preselectedType?: EntityTypes.companyV2 | EntityTypes.department | EntityTypes.team
  parent?: GoalsInterface
}) => {
  const location = useLocation()
  const { navigateWithEntity, entity } = useOrgEntity()
  const { data: contentTypes, isLoading } = useGetSelectors<GoalContentType>(
    selectorKeys.goal_content_types,
  )
  const canAdd = useDefaultWriteAccess(preselectedType)
  const [pending, setPending] = useState(false)
  const showStatusPopup = useShowStatusPopup()
  const ownerId = useOwnerId()

  if (isLoading) {
    return <ActionButtonSkeleton />
  }

  const handleNew = async () => {
    const createSpecificGoal = !(!entity || (entity.type && isCompany(entity.type)))

    setPending(true)

    const isOnboarding = isOnboardingPath()

    const getGoalsParams = () => {
      const isEmployeeGoal =
        entity?.type &&
        [EntityTypes.employee, EntityTypes.employees].includes(entity.type)
      const autoCascadeParams = isEmployeeGoal ? {} : { is_auto_cascaded_to_owner: true }

      if (!createSpecificGoal) {
        return autoCascadeParams
      }

      if (parent) {
        return {
          is_company:
            entity?.type === EntityTypes.company ||
            entity?.type === EntityTypes.companyV2,
          parent: parent || undefined,
          ...autoCascadeParams,
        }
      }

      return {
        is_company:
          entity?.type === EntityTypes.company || entity?.type === EntityTypes.companyV2,
        content_type:
          entity?.type === EntityTypes.company || entity?.type === EntityTypes.companyV2
            ? undefined
            : contentTypes?.find(({ model }) => model === entity?.type),
        object_id: entity?.data.id,
        ...autoCascadeParams,
      }
    }

    try {
      const response = await createDraftGoal({
        owner: { id: ownerId },
        ...getGoalsParams(),
      })

      if (createSpecificGoal) {
        const targetModel = getChilLevelContentType(entity?.type)

        navigateWithEntity(
          pathToUrl(
            isOnboarding
              ? ROUTES.ONBOARDING_CHECKLIST_V2.GOALS.GOAL.EDIT
              : ROUTES.FORMS.GOAL.EDIT,
            { id: response.data.id },
            parent ? { type: targetModel } : undefined,
          ),
          {
            reviewCycleId: new URLSearchParams(location.search).get('cycle__id'),
            isNew: true,
          },
        )
      } else {
        navigateTo(
          pathToUrl(
            isOnboarding
              ? ROUTES.ONBOARDING_CHECKLIST_V2.GOALS.GOAL.CREATE.FORM
              : ROUTES.FORMS.GOAL.CREATE.FORM,
            { id: response.data.id },
            { type: preselectedType || EntityTypes.companyV2 },
          ),
          {
            reviewCycleId: new URLSearchParams(location.search).get('cycle__id'),
            isNew: true,
          },
        )
      }
    } catch (err) {
      captureException(err)
      showStatusPopup({
        status: 'error',
        title: 'Failed to create goal',
        description: getStringMessageFromError(
          err,
          'Something went wrong. Please try again.',
        ),
      })

      setPending(false)
    }
  }

  const renderButton = () => {
    if (variant === 'text') {
      return (
        <TextButton disabled={pending} onClick={handleNew}>
          <HStack align="center" space="s-8">
            {pending ? <Spinner size={16} /> : <Icon name="Plus" size={16} />}
            <Text>{label || 'Add new goal'}</Text>
          </HStack>
        </TextButton>
      )
    }

    if (variant === 'button') {
      return (
        <Button pending={pending} onClick={handleNew}>
          {label || 'Add new goal'}
        </Button>
      )
    }
    return (
      <PrimaryAction pending={pending} useIcon="Plus" onClick={handleNew}>
        {label || 'Add new goal'}
      </PrimaryAction>
    )
  }

  return canAdd ? renderButton() : null
}
