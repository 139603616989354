import React from 'react'
import { useSelector } from 'react-redux'

import { DataHandlerInterface } from '@src/components/FormPreview/FormPreview'
import { ROUTES } from '@src/constants/routes'
import { EmployeeInterface } from '@src/interfaces/employees'

import { selectFeatureFlags } from '@src/store/auth/selectors'
import { FeatureFlags } from '@src/store/auth/types'
import { General } from '../../Forms'
import { Screening } from '../../Forms/Screening'
import { useCanViewReferrals } from '../../Preview/components/Buttons/common'
import { DataAccess } from '../../Preview/DataAccess'
import { Labels } from '../../Preview/Labels'
import {
  canViewCompensationV2,
  canViewChangelog,
  canViewDocuments,
  canViewPolicyAssignments,
  canViewTimeline,
  hasLinkedAccountsPermissions,
  hasPayrollPermissions,
  hasScreeningPermissions,
} from '../../Preview/ProfileSummary/common'
import { LinkedAccountsList } from '../../Preview/ProfileSummary/components/LinkedAccountsList'
import { Referrals } from '../../Preview/Referrals'
import Hierarchy from '../../Preview/Talent/Hierarchy'
import { EmployeeTimeOffPolicies } from '../../Preview/TimeOff/EmployeeTimeOffPolicies'
import { CompensationPages } from '../Compensation'
import { CompensationV2Pages } from '../CompensationV2/Pages'
import { CompliancePages } from '../Compliance'
import { DocumentsLayoutTab } from '../Documents/LayoutTab'
import { TeamCalendar } from './TeamCalendar'
import { VisibilityWrapper } from './VisibilityWrapper'
import { CompensationParams } from '../common/types'
import { useGetEmployeeStats } from '@src/api/employees'
import { Route } from 'react-router-dom'
import { EmployeePageHeader } from '../common/EmployeePageHeader'
import LinkedAccounts from '../../Forms/LinkedAccounts/LinkedAccount'
import BankDetails from '@src/pages/EmployeeProfile/Forms/BankDetails'
import PageLoading from '@src/components/PageLoading/PageLoading'
import { Changelog } from '../../Preview/Changelog'
import { Timeline } from '../../Preview/Timeline'

const legacyGeneralRoutes = [
  ROUTES.FORMS.EMPLOYEE.GENERAL.NAME,
  ROUTES.FORMS.EMPLOYEE.GENERAL.POSITION,
  ROUTES.FORMS.EMPLOYEE.GENERAL.COMPENSATION,
  ROUTES.FORMS.EMPLOYEE.GENERAL.ORGANISATION,
  ROUTES.FORMS.EMPLOYEE.GENERAL.NOTES,
  ROUTES.FORMS.EMPLOYEE.GENERAL.BIO,
  ROUTES.FORMS.EMPLOYEE.GENERAL.CONTACT_INFO,
  ROUTES.FORMS.EMPLOYEE.GENERAL.EMERGENCY_CONTACTS,
  ROUTES.FORMS.EMPLOYEE.GENERAL.LAPTOP_DELIVERY,
  ROUTES.FORMS.EMPLOYEE.GENERAL.IDENTIFICATION,
  ROUTES.FORMS.EMPLOYEE.GENERAL.CORRESPONDENCE_ADDRESS,
  ROUTES.FORMS.EMPLOYEE.GENERAL.ABOUT,
  ROUTES.FORMS.EMPLOYEE.GENERAL.CONTRACT,
  ROUTES.FORMS.EMPLOYEE.GENERAL.RIGHT_TO_WORK,
  ROUTES.FORMS.EMPLOYEE.GENERAL.KEY_PERSON_RESPONSIBILITIES,
  ROUTES.FORMS.EMPLOYEE.GENERAL.KEY_PERSON_RESPONSIBILITY_CHANGELOG,
  ROUTES.FORMS.EMPLOYEE.GENERAL.DIVERSITY,
  ROUTES.FORMS.EMPLOYEE.GENERAL.TERMINATION,
  ROUTES.FORMS.EMPLOYEE.GENERAL.CHANGE_PASSWORD,
  ROUTES.FORMS.EMPLOYEE.GENERAL.TERMINATION_V2,
  ROUTES.FORMS.EMPLOYEE.GENERAL.PIP,
  ROUTES.FORMS.EMPLOYEE.GENERAL.ACTION_ITEMS,
]

type UseGetExternalPagesParams = {
  data: EmployeeInterface | undefined
  employeeId: string | undefined
  dataHandler: DataHandlerInterface<EmployeeInterface>
  dynamicGroups: { id: number }[]
  compensationParams: CompensationParams
}
export const useGetExternalPages = ({
  data,
  employeeId,
  dataHandler,
  dynamicGroups,
  compensationParams,
}: UseGetExternalPagesParams) => {
  const featureFlags = useSelector(selectFeatureFlags)
  const canViewReferrals = useCanViewReferrals({ data })
  const { data: stats, refetch: refreshStats } = useGetEmployeeStats(employeeId)
  const isCompensationV2 = featureFlags.includes(FeatureFlags.TotalCompensationV2)

  if (!data) {
    return <PageLoading />
  }

  const pages = [
    {
      key: 'general',
      path: legacyGeneralRoutes,
      component: (
        <General
          data={data}
          dynamicGroups={dynamicGroups}
          refreshData={dataHandler.refetch}
        />
      ),
      canView: true,
    },
    {
      key: 'profile',
      title: 'Employee hierarchy',
      path: ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.TALENT.HIERARCHY,
      component: <Hierarchy data={data} />,
      canView: true,
    },
    {
      key: 'timeOff',
      title: 'Manage Policies',
      path: ROUTES.FORMS.EMPLOYEE.TIME_OFF.POLICIES,
      component: <EmployeeTimeOffPolicies data={data} />,
      canView: canViewPolicyAssignments(data),
    },
    {
      key: 'documents',
      title: 'Documents',
      path: ROUTES.FORMS.EMPLOYEE.DOCUMENTS_BY_CATEGORY,
      component: <DocumentsLayoutTab data={data} mode="page" />,
      canView: canViewDocuments(data),
    },
    {
      key: 'compensation',
      path: ROUTES.FORMS.EMPLOYEE.REWARDS.ANY,
      component: (
        <CompensationPages data={data} compensationParams={compensationParams} />
      ),
      canView: true,
    },
    {
      key: 'compensation_v2',
      path: ROUTES.FORMS.EMPLOYEE.REWARDS_V2.ANY,
      component: (
        <CompensationV2Pages data={data} compensationParams={compensationParams} />
      ),
      canView: isCompensationV2 && canViewCompensationV2(data),
    },
    {
      key: 'compliance',
      path: [
        ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.FINDINGS,
        ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.RISK.ANY,
        ROUTES.FORMS.EMPLOYEE.OWNERSHIP.ANY,
        ROUTES.FORMS.EMPLOYEE.KEY_PERSON,
      ],
      component: (
        <CompliancePages data={data} stats={stats} refreshStats={refreshStats} />
      ),
      canView: true,
    },
    {
      key: 'dataAccess',
      title: 'Data Access',
      path: [ROUTES.FORMS.EMPLOYEE.ACCESS_REQUESTS],
      component: <DataAccess data={data} />,
      canView: true,
    },
    {
      key: 'referrals',
      title: 'Referrals',
      path: ROUTES.FORMS.EMPLOYEE.REFERRALS,
      component: <Referrals data={data} />,
      canView: canViewReferrals,
    },
    {
      key: 'labels',
      title: 'Labels',
      path: ROUTES.FORMS.EMPLOYEE.LABELS,
      component: <Labels data={data} />,
    },
    {
      key: 'teamCalendarView',
      title: 'Employee team calendar',
      path: ROUTES.FORMS.EMPLOYEE.TIME_OFF.TEAM_CALENDAR,
      component: <TeamCalendar data={data} />,
    },
    {
      key: 'linkedAccounts',
      title: 'Linked accounts',
      path: ROUTES.FORMS.EMPLOYEE.LINKED_ACCOUNTS,
      component: (
        <VisibilityWrapper
          canView={hasLinkedAccountsPermissions(data)}
          checkBySettings={{ global: settings => !!settings?.linked_accounts_enabled }}
        >
          <LinkedAccountsList data={data} />
        </VisibilityWrapper>
      ),
    },
    {
      key: 'linkedAccountsForm',
      title: 'Linked Accounts',
      path: ROUTES.FORMS.EMPLOYEE.GENERAL.LINKED_ACCOUNTS,
      component: (
        <VisibilityWrapper
          canView={hasLinkedAccountsPermissions(data)}
          checkBySettings={{ global: settings => !!settings?.linked_accounts_enabled }}
        >
          <LinkedAccounts data={data} />
        </VisibilityWrapper>
      ),
    },
    {
      key: 'screening',
      title: 'Screening',
      path: ROUTES.FORMS.EMPLOYEE.GENERAL.SCREENING,
      component: (
        <VisibilityWrapper
          canView={hasScreeningPermissions(data)}
          checkBySettings={{ screening: settings => !!settings?.enabled }}
        >
          <Screening data={data} />
        </VisibilityWrapper>
      ),
    },
    {
      key: 'bankDetailsForm',
      title: 'Bank details',
      path: ROUTES.FORMS.EMPLOYEE.GENERAL.BANK_DETAILS,
      component: (
        <VisibilityWrapper
          canView={hasPayrollPermissions(data)}
          checkBySettings={{ employee: settings => !!settings?.enable_bank_details }}
        >
          <BankDetails dynamicGroups={dynamicGroups} />
        </VisibilityWrapper>
      ),
    },
    {
      key: 'changelog',
      title: 'Changelog',
      path: ROUTES.FORMS.EMPLOYEE.CHANGELOG,
      component: (
        <VisibilityWrapper canView={canViewChangelog(data)}>
          <Changelog data={data} />
        </VisibilityWrapper>
      ),
    },
    {
      key: 'timeline',
      title: 'Timeline',
      path: ROUTES.FORMS.EMPLOYEE.TIMELINE,
      component: (
        <VisibilityWrapper canView={canViewTimeline(data)}>
          <Timeline data={data} />
        </VisibilityWrapper>
      ),
    },
  ]

  return pages.map(page => (
    <Route exact key={page.key} path={page.path}>
      {page.title && <EmployeePageHeader data={data} title={page.title} />}
      {page.component}
    </Route>
  ))
}
