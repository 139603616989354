import React from 'react'
import { useParams } from 'react-router-dom'
import { Avatar, Item, ItemSkeleton, Text, Token, VStack } from '@revolut/ui-kit'

import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageWrapper } from '@src/components/Page/Page'
import { PageBody } from '@src/components/Page/PageBody'
import { ROUTES } from '@src/constants/routes'
import {
  useGetEngagementAnswerDetails,
  useGetEngagementAnswerReplies,
} from '@src/api/engagement'
import { ReplyWidget } from '../common/ReplyWidget'
import { AnswerWidget } from '../common/AnswerWidget'

export const EngagementAnswerReplyThread = () => {
  const { answerId } = useParams<{ questionId: string; answerId: string }>()

  const { data: answerData, isLoading: isLoadingAnswer } = useGetEngagementAnswerDetails(
    Number(answerId),
  )

  const { data: repliesData, isLoading: isLoadingReplies } =
    useGetEngagementAnswerReplies(Number(answerId))
  const replies = repliesData?.results || []

  return (
    <PageWrapper>
      <PageHeader title="Survey question replies" backUrl={ROUTES.MAIN} />
      <PageBody>
        <VStack space="s-16">
          {isLoadingAnswer ? (
            <>
              <ItemSkeleton />
              <ItemSkeleton />
            </>
          ) : (
            <>
              <Item>
                <Item.Avatar>
                  <Avatar useIcon="QuestionOutline" />
                </Item.Avatar>
                <Item.Content>
                  <Item.Title>
                    {answerData?.question.question_text ? (
                      <Text variant="h4">{answerData.question.question_text}</Text>
                    ) : (
                      <Text color={Token.color.greyTone50}>Question not found</Text>
                    )}
                  </Item.Title>
                </Item.Content>
              </Item>
              <AnswerWidget answer={answerData} />
            </>
          )}
          {isLoadingReplies ? (
            <>
              <ItemSkeleton />
              <ItemSkeleton />
              <ItemSkeleton />
            </>
          ) : (
            replies?.map(reply => (
              <ReplyWidget
                key={reply.id}
                employee={reply.employee}
                answerText={reply.text}
              />
            ))
          )}
        </VStack>
      </PageBody>
    </PageWrapper>
  )
}
